/**
 * @component MissionDialogView
 * @description Presentation component for mission creation/editing dialog.
 * Renders form fields for mission configuration and user assignment.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Autocomplete,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {Object} MissionDialogViewProps
 * @property {boolean} open - Dialog visibility state
 * @property {Function} onClose - Dialog close handler
 * @property {Function} onSubmit - Form submission handler
 * @property {Object} formData - Form field values
 * @property {Function} onFormChange - Form field change handler
 * @property {Array<string>} users - Available users list
 * @property {boolean} isEdit - Whether editing existing mission
 */
const MissionDialogView = ({ open, onClose, onSubmit, formData, onFormChange, users, isEdit }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth data-testid="mission-dialog">
      <DialogTitle>
        {t(isEdit ? 'management.mission.title.edit' : 'management.mission.title.new')}
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              data-testid="mission-name-input"
              label={t('management.mission.fields.name')}
              value={formData.name}
              onChange={e => onFormChange('name', e.target.value)}
              required
            />
            <TextField
              data-testid="mission-description-input"
              label={t('management.mission.fields.description')}
              value={formData.description}
              onChange={e => onFormChange('description', e.target.value)}
              multiline
              rows={3}
            />
            <TextField
              data-testid="mission-total-days-input"
              label={t('management.mission.fields.total_days')}
              type="number"
              value={formData.totalDays}
              onChange={e => onFormChange('totalDays', Number(e.target.value))}
              required
              inputProps={{ min: 0 }}
            />
            <TextField
              data-testid="mission-daily-rate-input"
              label={t('management.mission.fields.daily_rate')}
              type="number"
              value={formData.dailyRate}
              onChange={e => onFormChange('dailyRate', Number(e.target.value))}
              required
              inputProps={{ min: 0 }}
            />

            <FormControlLabel
              data-testid="mission-availability-switch"
              control={
                <Switch
                  checked={formData.isAvailableToEveryone}
                  onChange={e => onFormChange('isAvailableToEveryone', e.target.checked)}
                  color="primary"
                />
              }
              label={t(
                formData.isAvailableToEveryone
                  ? 'management.mission.access.available_to_everyone'
                  : 'management.mission.access.private'
              )}
            />
            {/* User authorization field */}

            {!formData.isAvailableToEveryone && (
              <Autocomplete
                data-testid="authorized-users-autocomplete"
                multiple
                options={users}
                value={formData.authorizedUsers}
                onChange={(_, newValue) => onFormChange('authorizedUsers', newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('management.mission.fields.authorized_users')}
                    placeholder={t('management.mission.fields.authorized_users_placeholder')}
                    data-testid="authorized-users-input"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} data-testid={`user-option-${option}`}>
                    {option}
                  </li>
                )}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} data-testid="mission-dialog-cancel">
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            data-testid="mission-dialog-submit"
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

// PropTypes validation
MissionDialogView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    totalDays: PropTypes.number.isRequired,
    dailyRate: PropTypes.number.isRequired,
    authorizedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
    isAvailableToEveryone: PropTypes.bool.isRequired,
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default MissionDialogView;
