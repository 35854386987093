import React from 'react';
import PropTypes from 'prop-types';
import { startOfMonth, endOfMonth } from 'date-fns';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';

// Import all components
import CoverPage from './components/CoverPage';
import Footer from './components/Footer';
import ConsultantSummaryTable from './components/tables/ConsultantSummaryTable';
import ConsultantTimesheet from './components/tables/ConsultantTimesheet';
import SignaturePage from './components/SignaturePage';

const styles = StyleSheet.create({
  page: {
    padding: '20mm',
    backgroundColor: 'white',
    position: 'relative',
    minHeight: '297mm',
  },
});

const TimeSheetPDFTemplate = ({ projectData, selectedDate }) => {
  const periodStart = startOfMonth(selectedDate);
  const periodEnd = endOfMonth(selectedDate);
  const totalPages = projectData.timesheets.length + 3; // Cover + Summary + Signatures + Consultants

  return (
    <Document>
      {/* Cover Page (Page 1) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <CoverPage projectData={projectData} period={{ start: periodStart, end: periodEnd }} />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`1/${totalPages}`}
        />
      </Page>

      {/* Summary Page (Page 2) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <ConsultantSummaryTable projectData={projectData} />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`2/${totalPages}`}
        />
      </Page>

      {/* Individual Consultant Pages */}
      {projectData.timesheets.map((timesheet, index) => (
        <Page key={timesheet.id} size="A4" style={styles.page} wrap={false}>
          <ConsultantTimesheet
            consultant={timesheet}
            period={{ start: periodStart, end: periodEnd }}
            projectData={projectData}
          />
          <Footer
            projectName={projectData.projectName}
            projectCode={projectData.projectCode || '-'}
            selectedDate={selectedDate}
            pageNumber={`${index + 3}/${totalPages}`}
          />
        </Page>
      ))}

      {/* Signature Page (Last Page) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <SignaturePage />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`${totalPages}/${totalPages}`}
        />
      </Page>
    </Document>
  );
};

TimeSheetPDFTemplate.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
    contractRef: PropTypes.string,
    orderNumber: PropTypes.string,
    totalUO: PropTypes.number,
    totalTickets: PropTypes.number,
    totalDays: PropTypes.number.isRequired,
    timesheets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userEmail: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
        days: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

export default TimeSheetPDFTemplate;
