/**
 * @component ManagementView
 * @description Presentation component for project management interface.
 * Displays project list with filtering, pagination, and action controls.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Alert,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {Object} ManagementViewProps
 * @property {boolean} loading - Loading state indicator
 * @property {string} error - Error message if any
 * @property {string} searchTerm - Current search filter value
 * @property {string} statusFilter - Current status filter value
 * @property {number} page - Current page number
 * @property {number} rowsPerPage - Number of rows per page
 * @property {Array} paginatedProjects - Projects for current page
 * @property {Array} filteredProjects - All filtered projects
 * @property {Function} onSearchChange - Search term change handler
 * @property {Function} onStatusFilterChange - Status filter change handler
 * @property {Function} onPageChange - Page change handler
 * @property {Function} onOpenDialog - Dialog open handler
 * @property {Function} onNavigateToReview - Review navigation handler
 */
const ManagementView = ({
  loading,
  error,
  searchTerm,
  statusFilter,
  page,
  rowsPerPage,
  paginatedProjects,
  filteredProjects,
  onSearchChange,
  onStatusFilterChange,
  onPageChange,
  onOpenDialog,
  onNavigateToReview,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('management.title')}
        </Typography>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => onOpenDialog(null)}>
          {t('management.add_project')}
        </Button>
      </Box>

      {/* Filters */}
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t('management.filters.search')}
              value={searchTerm}
              onChange={e => onSearchChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{t('management.filters.status')}</InputLabel>
              <Select
                value={statusFilter}
                onChange={e => onStatusFilterChange(e.target.value)}
                label="Status Filter"
              >
                <MenuItem value="all">{t('common.all')}</MenuItem>
                <MenuItem value="active">{t('management.project.status.active')}</MenuItem>
                <MenuItem value="on_hold">{t('management.project.status.on_hold')}</MenuItem>
                <MenuItem value="completed">{t('management.project.status.completed')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* Loading and error states */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>{t('common.loading')}</Typography>
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      ) : (
        <>
          {/* Projects table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('management.columns.code')}</TableCell>
                  <TableCell>{t('management.columns.name')}</TableCell>
                  <TableCell>{t('management.columns.status')}</TableCell>
                  <TableCell align="right">{t('management.columns.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedProjects.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {t('management.no_projects')}
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedProjects.map(project => (
                    <TableRow key={project.id}>
                      <TableCell>{project.code}</TableCell>
                      <TableCell>{project.name}</TableCell>
                      <TableCell>
                        {t(`management.project.status.${project.status.toLowerCase()}`)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => onOpenDialog(project)}
                          color="primary"
                          size="small"
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination controls */}
          <TablePagination
            component="div"
            count={filteredProjects.length}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10]}
            labelRowsPerPage={t('common.pagination.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) =>
              t('common.pagination.range', { from, to, count })
            }
            sx={{ mt: 2 }}
          />
        </>
      )}
    </Container>
  );
};

// PropTypes validation
ManagementView.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  statusFilter: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  paginatedProjects: PropTypes.array.isRequired,
  filteredProjects: PropTypes.array.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  onNavigateToReview: PropTypes.func.isRequired,
};

export default ManagementView;
