/**
 * @component MissionItemView
 * @description Presentation component for individual mission items in the project table.
 * Displays mission details and action buttons for editing and deletion.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * @typedef {Object} MissionItemViewProps
 * @property {Object} mission - Mission data to display
 * @property {Function} onDelete - Deletion handler
 * @property {Function} onEdit - Edit handler
 */
const MissionItemView = ({ mission, onDelete, onEdit }) => (
  <TableRow>
    {/* Mission details cells */}
    <TableCell>{mission.name}</TableCell>
    <TableCell>{mission.description}</TableCell>
    <TableCell align="right">{mission.totalDays}</TableCell>
    <TableCell align="right">{mission.dailyRate}</TableCell>
    <TableCell align="right">{(mission.totalDays * mission.dailyRate).toLocaleString()}</TableCell>

    {/* Action buttons */}
    <TableCell align="center">
      <IconButton
        size="small"
        onClick={() => onEdit(mission)}
        sx={{ mr: 1 }}
        data-testid="edit-mission-button"
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={onDelete} data-testid="delete-mission-button">
        <DeleteIcon fontSize="small" />
      </IconButton>
    </TableCell>
  </TableRow>
);

// PropTypes validation
MissionItemView.propTypes = {
  mission: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    totalDays: PropTypes.number.isRequired,
    dailyRate: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default MissionItemView;
