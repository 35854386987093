import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SummaryTab = ({ timeEntries, selectedMissions }) => {
  const { t } = useTranslation();

  // Calculate totals by project and mission
  const totals = React.useMemo(() => {
    const projectTotals = {};
    const missionTotals = {};
    const nonInvoicableTotals = {
      total: 0,
      missions: {},
    };

    // Initialize totals
    selectedMissions.forEach(mission => {
      // Skip missions with invalid project data
      if (!mission?.project) return;

      const projectId = mission.project.id;
      missionTotals[mission.id] = 0;

      if (!projectTotals[projectId]) {
        projectTotals[projectId] = {
          name: mission.project.name,
          total: 0,
          isInvoicable: mission.project.invoicable,
        };
      }
    });

    // Sum up all entries
    for (const missionId in timeEntries) {
      const mission = selectedMissions.find(m => m.id === missionId);
      if (!mission?.project) continue;

      const projectId = mission.project.id;
      let missionTotal = 0;

      // Sum all days for this mission
      for (const date in timeEntries[missionId]) {
        const value = parseFloat(timeEntries[missionId][date] || 0);
        missionTotal += isNaN(value) ? 0 : value;
      }

      missionTotals[missionId] = missionTotal;

      if (!mission.project.invoicable) {
        nonInvoicableTotals.total += missionTotal;
        nonInvoicableTotals.missions[missionId] = {
          name: mission.name,
          project: mission.project.name,
          total: missionTotal,
        };
      } else {
        projectTotals[projectId].total += missionTotal;
      }
    }

    return { projectTotals, missionTotals, nonInvoicableTotals };
  }, [timeEntries, selectedMissions]);

  return (
    <Box sx={{ p: 2 }}>
      {/* Project Summary */}
      <Typography variant="h6" gutterBottom>
        {t('timesheet.summary.byProject')}
      </Typography>
      <Table size="small" data-testid="project-summary-table">
        <TableHead>
          <TableRow>
            <TableCell>{t('timesheet.summary.project')}</TableCell>
            <TableCell align="right">{t('timesheet.summary.days')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(totals.projectTotals)
            .filter(([_, data]) => data.isInvoicable)
            .map(([projectId, data]) => (
              <TableRow key={projectId} data-testid={`project-row-${projectId}`}>
                <TableCell>{data.name}</TableCell>
                <TableCell align="right">{data.total.toFixed(1)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {/* Non-Invoicable Summary */}
      <Typography variant="h6" sx={{ mt: 4 }} gutterBottom>
        {t('timesheet.summary.nonInvoicable')}
      </Typography>
      <Table size="small" data-testid="non-invoicable-summary-table">
        <TableHead>
          <TableRow>
            <TableCell>{t('timesheet.summary.mission')}</TableCell>
            <TableCell>{t('timesheet.summary.project')}</TableCell>
            <TableCell align="right">{t('timesheet.summary.days')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(totals.nonInvoicableTotals.missions).map(([missionId, data]) => (
            <TableRow key={missionId} data-testid={`non-invoicable-row-${missionId}`}>
              <TableCell>{data.name}</TableCell>
              <TableCell>{data.project}</TableCell>
              <TableCell align="right">{data.total.toFixed(1)}</TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ '& td': { fontWeight: 'bold' } }} data-testid="non-invoicable-total-row">
            <TableCell colSpan={2}>{t('timesheet.summary.totalNonInvoicable')}</TableCell>
            <TableCell align="right">{totals.nonInvoicableTotals.total.toFixed(1)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Mission Summary */}
      <Typography variant="h6" sx={{ mt: 4 }} gutterBottom>
        {t('timesheet.summary.byMission')}
      </Typography>
      <Table size="small" data-testid="mission-summary-table">
        <TableHead>
          <TableRow>
            <TableCell>{t('timesheet.summary.mission')}</TableCell>
            <TableCell>{t('timesheet.summary.project')}</TableCell>
            <TableCell align="right">{t('timesheet.summary.days')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedMissions.map(mission => (
            <TableRow key={mission.id} data-testid={`mission-row-${mission.id}`}>
              <TableCell>{mission.name}</TableCell>
              <TableCell>{mission.project?.name || '-'}</TableCell>
              <TableCell align="right">
                {(totals.missionTotals[mission.id] || 0).toFixed(1)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

SummaryTab.propTypes = {
  timeEntries: PropTypes.object.isRequired,
  selectedMissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        invoicable: PropTypes.bool.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default SummaryTab;
