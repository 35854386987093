import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CommentsTab = ({ comments, onChange, readOnly }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 2 }}>
      <TextField
        fullWidth
        multiline
        rows={4}
        value={comments || ''}
        onChange={e => onChange(e.target.value)}
        disabled={readOnly}
        placeholder={t('timesheet.comments.placeholder')}
        label={t('timesheet.comments.label')}
      />
    </Box>
  );
};

CommentsTab.propTypes = {
  comments: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default CommentsTab;
