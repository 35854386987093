import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from './contexts/theme';
import { ToastProvider } from './contexts/toast';
import { AuthProvider } from './contexts/auth';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import './i18n';

const msalInstance = new PublicClientApplication(msalConfig);

// Initialize app with async MSAL
(async () => {
  await msalInstance.initialize();

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <BrowserRouter>
            <ToastProvider>
              <MsalProvider instance={msalInstance}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </MsalProvider>
            </ToastProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
})().catch(error => {
  // Handle error silently or show a user-friendly message
});
