/**
 * @component AuthProvider
 * @description Authentication context provider that manages user authentication state using Microsoft SSO.
 * Handles login, logout, and user data synchronization with Firestore.
 */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { db, initializeFirebaseAuth } from '../../firebase/config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { AuthContext } from './authContext';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

export const AuthProvider = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = useCallback(async account => {
    if (!account) return;

    try {
      // First, ensure Firebase auth is initialized with MSAL token
      const result = await initializeFirebaseAuth(account);
      const firebaseUser = result?.firebaseUser;

      if (!firebaseUser) {
        throw new Error('Failed to initialize Firebase auth');
      }

      const userId = account.username.toLowerCase().replace('@', '_at_');
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      const now = new Date();

      if (userDoc.exists()) {
        // Update existing user with last login
        const userData = {
          ...userDoc.data(),
          lastLoginAt: now,
        };

        await setDoc(doc(db, 'users', userId), userData, { merge: true });

        setUser({
          ...account,
          userId,
          ...userData,
        });
      } else {
        // Create new user with initial last login
        const newUserData = {
          email: account.username,
          name: account.name,
          role: 'user',
          createdAt: now,
          lastLoginAt: now,
        };

        await setDoc(doc(db, 'users', userId), newUserData);
        setUser({
          ...account,
          userId,
          ...newUserData,
        });
      }
    } catch (error) {
      setUser(account);
    }
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      if (inProgress !== 'none') return;

      if (accounts.length > 0) {
        await fetchUserData(accounts[0]);
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    initializeAuth();
  }, [accounts, inProgress, fetchUserData]);

  const login = useCallback(async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      return response;
    } catch (error) {
      throw error;
    }
  }, [instance]);

  const logout = useCallback(async () => {
    try {
      await instance.logoutPopup();
      setUser(null);
    } catch (error) {
      throw error;
    }
  }, [instance]);

  const value = {
    user,
    login,
    logout,
    loading,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
