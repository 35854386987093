import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControl, InputLabel, Select, MenuItem, Chip, Box } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

const ENTITY_TYPES = ['USER', 'TIMESHEET', 'PROJECT'];
const ACTION_TYPES = ['CREATE', 'UPDATE', 'DELETE'];

/**
 * @component AuditTrailFilters
 * @description Presentation component for audit trail filtering controls
 */
const AuditTrailFilters = ({
  selectedEntities,
  selectedActions,
  onEntityChange,
  onActionChange,
}) => {
  return (
    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Entity Types</InputLabel>
        <Select
          multiple
          value={selectedEntities}
          onChange={onEntityChange}
          input={<OutlinedInput label="Entity Types" />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {ENTITY_TYPES.map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Actions</InputLabel>
        <Select
          multiple
          value={selectedActions}
          onChange={onActionChange}
          input={<OutlinedInput label="Actions" />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {ACTION_TYPES.map(action => (
            <MenuItem key={action} value={action}>
              {action}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

AuditTrailFilters.propTypes = {
  selectedEntities: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onEntityChange: PropTypes.func.isRequired,
  onActionChange: PropTypes.func.isRequired,
};

export default AuditTrailFilters;
