import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fr, enUS, es, pt, ko } from 'date-fns/locale';

const CONTRACT_TYPES = ['FULL_TIME', 'PART_TIME_50', 'PART_TIME_80'];

const localeMap = {
  fr,
  en: enUS,
  es,
  pt,
  ko,
};

const defaultEmployeeData = {
  id: null,
  dateOfBirth: null,
  entryDate: null,
  contractType: 'FULL_TIME',
  isActive: true,
};

const EmployeeSetupDialog = ({ open, onClose, onSave, initialData = null }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = localeMap[i18n.language] || enUS;

  const [employeeData, setEmployeeData] = useState(defaultEmployeeData);

  useEffect(() => {
    if (initialData) {
      setEmployeeData({
        ...defaultEmployeeData,
        ...initialData,
      });
    }
  }, [initialData]);

  const handleSave = () => {
    const formattedData = {
      ...employeeData,
      dateOfBirth: employeeData.dateOfBirth ? employeeData.dateOfBirth : null,
      entryDate: employeeData.entryDate ? employeeData.entryDate : null,
    };
    onSave(formattedData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('admin.users.setup.title')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={employeeData.isActive}
                onChange={e => setEmployeeData(prev => ({ ...prev, isActive: e.target.checked }))}
                color="primary"
              />
            }
            label={t('admin.users.fields.isActive')}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLocale}>
            <DatePicker
              label={t('admin.users.fields.dateOfBirth')}
              value={employeeData.dateOfBirth}
              onChange={newValue => setEmployeeData(prev => ({ ...prev, dateOfBirth: newValue }))}
              slotProps={{
                textField: {
                  fullWidth: true,
                  inputProps: { 'data-testid': 'date-of-birth-input' },
                },
              }}
              format="P"
            />

            <DatePicker
              label={t('admin.users.fields.entryDate')}
              value={employeeData.entryDate}
              onChange={newValue => setEmployeeData(prev => ({ ...prev, entryDate: newValue }))}
              slotProps={{
                textField: {
                  fullWidth: true,
                  inputProps: { 'data-testid': 'entry-date-input' },
                },
              }}
              format="P"
            />
          </LocalizationProvider>

          <FormControl fullWidth>
            <InputLabel>{t('admin.users.fields.contractType')}</InputLabel>
            <Select
              data-testid="contract-type-input"
              value={employeeData.contractType}
              onChange={e => setEmployeeData(prev => ({ ...prev, contractType: e.target.value }))}
              label={t('admin.users.fields.contractType')}
            >
              {CONTRACT_TYPES.map(type => (
                <MenuItem key={type} value={type} data-testid={`contract-type-${type}`}>
                  {t(`admin.users.contractTypes.${type}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }} data-testid="cancel-button">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" data-testid="save-button">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmployeeSetupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    dateOfBirth: PropTypes.instanceOf(Date),
    entryDate: PropTypes.instanceOf(Date),
    contractType: PropTypes.oneOf(CONTRACT_TYPES),
    isActive: PropTypes.bool,
  }),
};

export default EmployeeSetupDialog;
