export const msalConfig = {
  auth: {
    clientId: '0f74600b-b12d-4f90-9af0-13ea893b4504',
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    allowNativeBroker: false,
    windowHashTimeout: 9000,
    iframeHashTimeout: 9000,
    loadFrameTimeout: 9000,
  },
};

export const loginRequest = {
  scopes: ['User.Read', 'profile', 'openid', 'email'],
};
