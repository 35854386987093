import { format } from 'date-fns';
import { fr, enUS, es, pt, ko } from 'date-fns/locale';

export const locales = {
  fr,
  en: enUS,
  es,
  pt,
  ko,
};

// Helper function to capitalize first letter
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Helper function to format date with capitalized month
export const formatDateWithCapitalizedMonth = (date, formatStr, locale) => {
  const selectedLocale = locales[locale] || locales.en;
  const formatted = format(date, formatStr, { locale: selectedLocale });
  return capitalizeFirstLetter(formatted);
};
