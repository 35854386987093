import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  Collapse,
  Divider,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const MissionSelectionDialog = ({ open, onClose, projects, onMissionSelect, userEmail }) => {
  const { t } = useTranslation();
  const [expandedProjects, setExpandedProjects] = useState({});
  const [selectedMissions, setSelectedMissions] = useState([]);

  const handleProjectClick = projectId => {
    setExpandedProjects(prev => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  const handleMissionToggle = mission => {
    setSelectedMissions(prev => {
      const missionIndex = prev.findIndex(m => m.id === mission.id);
      if (missionIndex === -1) {
        return [...prev, mission];
      }
      return prev.filter(m => m.id !== mission.id);
    });
  };

  const handleConfirm = () => {
    onMissionSelect(selectedMissions);
    onClose();
  };

  const isMissionSelected = missionId => {
    return selectedMissions.some(m => m.id === missionId);
  };

  // Filter projects and missions based on user permissions
  const filteredProjects = projects
    .map(project => ({
      ...project,
      missions: project.missions.filter(
        mission =>
          mission.isAvailableToEveryone ||
          (mission.authorizedUsers && mission.authorizedUsers.includes(userEmail))
      ),
    }))
    .filter(project => project.missions.length > 0);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle data-testid="timesheet-dialog-title">
        {t('timesheet.mission.select_title')}
      </DialogTitle>
      <DialogContent>
        <List sx={{ width: '100%' }}>
          {filteredProjects.map(project => (
            <React.Fragment key={project.id}>
              <ListItemButton
                onClick={() => handleProjectClick(project.id)}
                sx={{
                  backgroundColor: project.color || '#f5f5f5',
                  '&:hover': {
                    backgroundColor: project.color ? `${project.color}dd` : 'action.hover',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: project.color ? 'white' : 'text.primary',
                        fontWeight: 'bold',
                      }}
                    >
                      {project.name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      sx={{
                        color: project.color ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary',
                      }}
                    >
                      {project.code}
                    </Typography>
                  }
                />
                {expandedProjects[project.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={expandedProjects[project.id]} timeout="auto">
                <List component="div" disablePadding>
                  {project.missions.map(mission => (
                    <ListItemButton
                      key={mission.id}
                      sx={{ pl: 4 }}
                      onClick={() => handleMissionToggle(mission)}
                    >
                      <Checkbox
                        edge="start"
                        checked={isMissionSelected(mission.id)}
                        tabIndex={-1}
                        disableRipple
                        data-testid={`mission-checkbox-${mission.id}`}
                      />
                      <ListItemText primary={mission.name} secondary={mission.description} />
                    </ListItemButton>
                  ))}
                  {project.missions.length === 0 && (
                    <Box sx={{ pl: 4, py: 1, color: 'text.secondary' }}>
                      {t('timesheet.mission.no_missions')}
                    </Box>
                  )}
                </List>
              </Collapse>
              <Divider />
            </React.Fragment>
          ))}
          {filteredProjects.length === 0 && (
            <Box sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
              {t('timesheet.mission.no_available_missions')}
            </Box>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={selectedMissions.length === 0}
        >
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MissionSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      color: PropTypes.string,
      missions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
          isAvailableToEveryone: PropTypes.bool,
          authorizedUsers: PropTypes.arrayOf(PropTypes.string),
        })
      ).isRequired,
    })
  ).isRequired,
  onMissionSelect: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default MissionSelectionDialog;
