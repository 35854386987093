import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import StorageIcon from '@mui/icons-material/Storage';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/toast';

/**
 * @component BackupView
 * @description Presentation component for database backup and statistics.
 * Displays collection statistics and backup tools.
 */
const BackupView = ({ collections, onExportDatabase, onImportDatabase, loading }) => {
  const fileInputRef = React.useRef(null);
  const [importPreview, setImportPreview] = useState(null);
  const { t } = useTranslation();
  const { showToast } = useToast();

  const handleFileSelect = async event => {
    const files = Array.from(event.target.files);
    try {
      const file = files[0]; // Only handle first file for now
      const fileContent = await file.text();
      const importData = JSON.parse(fileContent);

      // Create preview data
      const preview = Object.entries(importData).map(([collectionName, documents]) => ({
        name: collectionName,
        documentCount: documents.length,
        documents,
      }));

      setImportPreview(preview);
    } catch (error) {
      console.error('Error reading file:', error);
      showToast(t('admin.database.errors.file_read_failed'), 'error');
    }
    event.target.value = '';
  };

  const handleImportCollection = (collectionName, documents) => {
    onImportDatabase({ [collectionName]: documents });
  };

  const ImportPreviewDialog = () => (
    <Dialog open={!!importPreview} onClose={() => setImportPreview(null)} maxWidth="md" fullWidth>
      <DialogTitle>{t('admin.database.import_preview.title')}</DialogTitle>
      <DialogContent>
        <List>
          {importPreview?.map(collection => (
            <ListItem key={collection.name}>
              <ListItemText
                primary={collection.name}
                secondary={t('admin.database.import_preview.document_count', {
                  count: collection.documentCount,
                })}
              />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleImportCollection(collection.name, collection.documents)}
                  disabled={loading}
                >
                  {t('admin.database.import_preview.import_collection')}
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setImportPreview(null)} color="primary">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Database Backup & Statistics
      </Typography>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        {collections.map(collection => (
          <Grid item xs={12} sm={6} md={4} key={collection.name}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center" mb={1}>
                  <StorageIcon sx={{ mr: 1 }} />
                  <Typography variant="h6" component="div">
                    {collection.name}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Documents: {collection.count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Paper sx={{ p: 3 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h6">Database Backup</Typography>
            <Typography variant="body2" color="textSecondary">
              Export or import database collections
            </Typography>
          </Box>
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={onExportDatabase}
              disabled={loading}
            >
              Export Database
            </Button>
            <input
              type="file"
              multiple
              accept=".json"
              ref={fileInputRef}
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
            <Button
              variant="contained"
              color="secondary"
              startIcon={<UploadIcon />}
              onClick={() => fileInputRef.current?.click()}
              disabled={loading}
            >
              Import Database
            </Button>
          </Box>
        </Box>
      </Paper>

      <ImportPreviewDialog />
    </Container>
  );
};

BackupView.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      indexes: PropTypes.number.isRequired,
    })
  ).isRequired,
  onExportDatabase: PropTypes.func.isRequired,
  onImportDatabase: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default BackupView;
