import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Grid,
  Alert,
  Tabs,
  Tab,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import {
  CalendarToday as CalendarTodayIcon,
  Weekend as WeekendIcon,
  Assignment as AssignmentIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  CheckCircle as CheckCircleIcon,
  CalendarViewDay as CalendarViewDayIcon,
  CalendarViewWeek as CalendarViewWeekIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import TimeEntryRow from './TimeEntryRow';
import {
  format,
  isWeekend,
  endOfWeek,
  endOfMonth,
  startOfMonth,
  isSameMonth,
  formatDistance,
  isAfter,
  addMonths,
  subMonths,
} from 'date-fns';
import { enUS, fr, pt, es, ko } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { formatWeekNumber, formatWeekday } from '../../utils/dateFormatters';
import MissionSelectionDialog from './MissionSelectionDialog';
import CommentsTab from './tabs/CommentsTab';
import SummaryTab from './tabs/SummaryTab';
import AuditTrailTab from './tabs/AuditTrailTab';
import StatCard from '../common/StatCard';

/**
 * @component TimeSheetFormView
 * @description Presentation component for the timesheet interface.
 * Renders the grid layout, mission rows, and control elements.
 *
 * @param {Object} props
 * @param {boolean} props.isLoading - Loading state indicator
 * @param {boolean} props.readOnly - Whether the timesheet is editable
 * @param {boolean} props.includeWeekends - Weekend display toggle state
 * @param {Function} props.onToggleWeekends - Weekend toggle handler
 * @param {Function} props.onBack - Navigation handler
 * @param {Date[]} props.workingDays - Array of valid working days
 * @param {Array} props.selectedMissions - Currently selected missions
 * @param {Object} props.timeEntries - Current time entry values
 * @param {Function} props.onTimeChange - Time entry update handler
 * @param {Function} props.onDeleteMission - Mission removal handler
 * @param {Array} props.remainingMissions - Available missions to add
 * @param {boolean} props.openDialog - Mission selection dialog state
 * @param {Function} props.onOpenDialog - Dialog open handler
 * @param {Function} props.onCloseDialog - Dialog close handler
 * @param {Function} props.onAddMission - New mission handler
 * @param {Object} props.kpis - Timesheet statistics
 * @param {Function} props.onSave - Save handler
 * @param {Date} props.selectedMonth - Current month
 * @param {Function} props.onMonthChange - Month selection handler
 * @param {Array} props.timeOptions - Available time values
 * @param {string} props.userEmail - Current user's email
 * @param {string} props.viewMode - Current view mode (days/weeks)
 * @param {Function} props.onViewModeChange - View mode toggle handler
 * @param {string[]} props.nonWorkingDays - Non-working day dates
 * @param {boolean} props.hasChanges - Tracking changes state
 * @param {boolean} props.isSaved - Tracking saved state
 * @param {Date} props.selectedMonth - Current month
 * @param {string} props.userEmail - Current user's email
 * @param {Object} props.initialData - Initial data for the timesheet
 * @param {boolean} props.isValidated - Validation status
 * @param {boolean} props.isUserOnly - User role check
 * @param {Date} props.monthClosure - Month closure date
 * @param {string} props.comments - Comments for the timesheet
 * @param {Function} props.onCommentsChange - Comments change handler
 * @param {string} props.timesheetId - Current timesheet ID
 * @param {Function} props.onValidate - Validate handler
 * @param {Function} props.onApprove - Approve handler
 * @param {Function} props.onDisapprove - Disapprove handler
 * @param {string} props.status - Current status
 * @param {boolean} props.isManagerOrAbove - Manager or above role check
 */

const TimeSheetFormView = ({
  isLoading,
  readOnly,
  includeWeekends,
  onToggleWeekends,
  onBack,
  workingDays,
  selectedMissions,
  timeEntries,
  onTimeChange,
  onDeleteMission,
  remainingMissions,
  openDialog,
  onOpenDialog,
  onCloseDialog,
  onAddMission,
  kpis,
  onSave,
  selectedMonth,
  onMonthChange,
  timeOptions,
  userEmail,
  viewMode = 'days',
  onViewModeChange,
  nonWorkingDays,
  hasChanges,
  isSaved,
  initialData = null,
  isValidated,
  isUserOnly,
  availableUsers,
  onUserChange,
  monthClosure = null,
  comments = '',
  onCommentsChange,
  timesheetId,
  onValidate,
  onApprove,
  onDisapprove,
  status,
  isManagerOrAbove,
  onConvertToDraft,
}) => {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(0);

  // timesheet is still opened, monthClosure is not closed .
  const isTimesheetOpened = !monthClosure || isAfter(new Date(), monthClosure);

  const canSave = isTimesheetOpened || !isUserOnly;

  const getLocale = () => {
    switch (i18n.language) {
      case 'fr':
        return fr;
      case 'pt':
        return pt;
      case 'es':
        return es;
      case 'ko':
        return ko;
      default:
        return enUS;
    }
  };
  // Calculate month closure status and message
  const monthClosureInfo = useMemo(() => {
    // Get the appropriate locale based on i18n language

    const getLocale = () => {
      switch (i18n.language) {
        case 'fr':
          return fr;
        case 'pt':
          return pt;
        case 'es':
          return es;
        case 'ko':
          return ko;
        default:
          return enUS;
      }
    };
    if (!monthClosure) {
      // show an alert that the month is not yet closed.
      return (
        <Alert severity="info" sx={{ mb: 2 }}>
          {t('timesheet.form.month_not_closed')}
        </Alert>
      );
    }

    const now = new Date();
    const isClosed = isAfter(now, monthClosure);
    const locale = getLocale();

    if (isClosed) {
      return (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {t('timesheet.form.month_closed', {
            date: format(monthClosure, 'PPP', { locale }),
          })}
        </Alert>
      );
    }

    return (
      <Alert severity="info" sx={{ mb: 2 }}>
        {t('timesheet.form.month_closure_reminder', {
          timeLeft: formatDistance(monthClosure, now, { addSuffix: true, locale }),
          date: format(monthClosure, 'PPP', { locale }),
        })}
      </Alert>
    );
  }, [monthClosure, t, i18n.language]);

  // Create a handler that preserves the current userEmail when changing months
  const handleMonthChange = newMonth => {
    // Call the parent's onMonthChange with both the new month and current userEmail
    onMonthChange(newMonth, userEmail);
  };

  return (
    <Container maxWidth="xl">
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>{t('common.loading')}</Typography>
        </Box>
      ) : (
        <Paper sx={{ p: 3, mt: 3 }}>
          {monthClosureInfo}
          <Box sx={{ mb: 6 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flexWrap: 'wrap',
                mb: 3, // Add margin bottom to separate from KPIs
              }}
            >
              <IconButton onClick={onBack} aria-label="back" sx={{ color: 'text.secondary' }}>
                <ArrowBackIcon />
              </IconButton>

              <Typography variant="h6" data-testid="timesheet-title">
                {t('timesheet.title')}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  onClick={() => handleMonthChange(subMonths(selectedMonth, 1))}
                  aria-label={t('timesheet.navigation.previous')}
                  data-testid="prev-month"
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <DatePicker
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  views={['month', 'year']}
                  format={i18n.language === 'ko' ? 'yyyy년 MM월' : 'MM/yyyy'}
                  slots={{
                    textField: params => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          '& .MuiInputBase-root': {
                            fontSize: 'h6.fontSize',
                            fontWeight: 'h6.fontWeight',
                          },
                          width: '200px',
                        }}
                      />
                    ),
                  }}
                />
                <IconButton
                  onClick={() => handleMonthChange(addMonths(selectedMonth, 1))}
                  aria-label={t('timesheet.navigation.next')}
                  data-testid="next-month"
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>

              {isUserOnly ? (
                <Typography component="span" sx={{ ml: 1 }}>
                  {userEmail}
                </Typography>
              ) : (
                <Select
                  value={userEmail || ''}
                  onChange={event => onUserChange(event)}
                  size="small"
                  sx={{ ml: 1, minWidth: 200 }}
                  disabled={isUserOnly}
                  data-testid="user-email-select"
                >
                  {availableUsers.map(user => (
                    <MenuItem
                      key={user.email}
                      value={user.email}
                      data-testid={`user-email-option-${user.email}`}
                    >
                      {user.email}
                    </MenuItem>
                  ))}
                </Select>
              )}

              {isValidated && (
                <Typography
                  component="span"
                  sx={{
                    color: 'success.main',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <CheckCircleIcon sx={{ mr: 0.5 }} fontSize="small" />
                  {t('timesheet.messages.validated')}
                </Typography>
              )}
            </Box>

            {isValidated && isUserOnly && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {t('timesheet.messages.validated_user_only')}
              </Typography>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <StatCard
                  icon={<CalendarTodayIcon />}
                  title={t('timesheet.form.working_days')}
                  value={workingDays.length}
                  subtitle={t('timesheet.form.total_business_days')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StatCard
                  icon={<WeekendIcon />}
                  title={t('timesheet.form.non_working_days')}
                  value={nonWorkingDays.length}
                  subtitle={t('timesheet.form.weekends_holidays')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StatCard
                  icon={<AssignmentIcon />}
                  title={t('timesheet.form.days_filled')}
                  value={kpis.totalDaysInputted.toFixed(1)}
                  subtitle={t('timesheet.form.total_days_recorded')}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
              mb: 4,
            }}
          >
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={onViewModeChange}
              size="small"
              aria-label="view mode"
            >
              <ToggleButton value="days" aria-label="days view" aria-pressed={viewMode === 'days'}>
                <CalendarViewDayIcon sx={{ mr: 1 }} />
                {t('timesheet.form.view_modes.days')}
              </ToggleButton>
              <ToggleButton
                value="weeks"
                aria-label="weeks view"
                aria-pressed={viewMode === 'weeks'}
              >
                <CalendarViewWeekIcon sx={{ mr: 1 }} />
                {t('timesheet.form.view_modes.weeks')}
              </ToggleButton>
            </ToggleButtonGroup>

            <FormControlLabel
              control={
                <Switch
                  checked={includeWeekends}
                  onChange={onToggleWeekends}
                  name="includeWeekends"
                />
              }
              label={t('timesheet.form.show_non_working_days')}
              sx={{ ml: 2 }}
            />
          </Box>

          <TableContainer sx={{ mt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '200px' }}>{t('timesheet.form.mission')}</TableCell>
                  {viewMode === 'weeks'
                    ? workingDays.map(weekStart => {
                        const monthStart = startOfMonth(selectedMonth);
                        const monthEnd = endOfMonth(selectedMonth);
                        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });

                        // Adjust start and end dates to stay within the month
                        const displayStart = isSameMonth(weekStart, selectedMonth)
                          ? weekStart
                          : monthStart;
                        const displayEnd = isSameMonth(weekEnd, selectedMonth) ? weekEnd : monthEnd;

                        return (
                          <TableCell
                            key={weekStart.toISOString()}
                            align="center"
                            sx={{
                              padding: '4px',
                              minWidth: '120px',
                            }}
                          >
                            <Typography variant="body2">
                              {t('timesheet.form.week_header.week')}{' '}
                              {formatWeekNumber(weekStart, i18n.language)}
                            </Typography>
                            <Typography
                              variant="caption"
                              display="block"
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              {t('timesheet.form.week_header.range', {
                                startDay: format(displayStart, 'EEE', { locale: getLocale() }),
                                startDate: format(displayStart, 'd'),
                                endDay: format(displayEnd, 'EEE', { locale: getLocale() }),
                                endDate: format(displayEnd, 'd'),
                              })}
                            </Typography>
                          </TableCell>
                        );
                      })
                    : workingDays.map(day => (
                        <TableCell
                          key={day.toISOString()}
                          align="center"
                          sx={{
                            padding: '4px',
                            backgroundColor: isWeekend(day) ? 'grey.100' : 'inherit',
                          }}
                          data-testid={`day-cell-${format(day, 'yyyy-MM-dd')}`}
                        >
                          <Typography variant="body2">{format(day, 'd')}</Typography>
                          <Typography variant="caption" display="block">
                            {formatWeekday(day, i18n.language)}
                          </Typography>
                        </TableCell>
                      ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedMissions.map(mission => (
                  <TimeEntryRow
                    key={mission.id}
                    mission={mission}
                    workingDays={workingDays}
                    timeEntries={timeEntries}
                    readOnly={readOnly}
                    onTimeChange={onTimeChange}
                    onDelete={onDeleteMission}
                    timeOptions={timeOptions}
                    viewMode={viewMode}
                    includeWeekends={includeWeekends}
                    nonWorkingDays={nonWorkingDays}
                    selectedMonth={selectedMonth}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!readOnly && (
            <Button
              startIcon={<AddIcon />}
              onClick={onOpenDialog}
              disabled={remainingMissions.length === 0}
              sx={{ mt: 2 }}
            >
              {t('timesheet.form.add_mission')}
            </Button>
          )}

          <MissionSelectionDialog
            open={openDialog}
            onClose={onCloseDialog}
            projects={remainingMissions.reduce((acc, mission) => {
              const projectId = mission.project.id;
              if (!acc.find(p => p.id === projectId)) {
                acc.push({
                  id: projectId,
                  name: mission.project.name,
                  code: mission.project.code,
                  color: mission.project.color,
                  missions: remainingMissions.filter(m => m.project.id === projectId),
                });
              }
              return acc;
            }, [])}
            onMissionSelect={missions => {
              missions.forEach(mission => onAddMission(mission));
              onCloseDialog();
            }}
            userEmail={userEmail}
          />

          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              aria-label="timesheet tabs"
            >
              <Tab label={t('timesheet.tabs.comments')} />
              <Tab label={t('timesheet.tabs.summary')} />
              {<Tab label={t('timesheet.tabs.audit')} />}
            </Tabs>
          </Box>

          <Box sx={{ mt: 2 }}>
            {selectedTab === 0 && (
              <CommentsTab comments={comments} onChange={onCommentsChange} readOnly={readOnly} />
            )}
            {selectedTab === 1 && (
              <SummaryTab timeEntries={timeEntries} selectedMissions={selectedMissions} />
            )}
            {selectedTab === 2 && <AuditTrailTab timesheetId={timesheetId} />}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={onBack} sx={{ color: 'text.secondary' }}>
              {t('common.back')}
            </Button>

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
              {/* Save button with draft text if status is PENDING */}

              {/* Validate button - enabled if status is PENDING */}
              {isUserOnly && (
                <>
                  {
                    <Button
                      variant="contained"
                      color="success"
                      onClick={onValidate}
                      disabled={isLoading || status !== 'PENDING'}
                      startIcon={<CheckCircleIcon />}
                      data-testid="validate-button"
                    >
                      {t('timesheet.form.buttons.validate')}
                    </Button>
                  }
                  {status === 'VALIDATED' && (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={onConvertToDraft}
                      disabled={isLoading}
                      startIcon={<SaveIcon />}
                      data-testid="convert-to-draft-button"
                    >
                      {t('timesheet.form.buttons.convert_to_draft')}
                    </Button>
                  )}

                  {status === 'PENDING' && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSave}
                      disabled={!canSave || readOnly || status !== 'PENDING'}
                      startIcon={<SaveIcon />}
                      data-testid="save-draft-button"
                      sx={{
                        fontWeight: 'bold',
                        minWidth: '120px',
                      }}
                    >
                      {t('timesheet.form.buttons.save_draft')}
                    </Button>
                  )}
                </>
              )}

              {/* Approve button - visible to managers+ if status is PENDING or VALIDATED */}
              {!isUserOnly && (status === 'VALIDATED' || status === 'PENDING') && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={onApprove}
                  disabled={isLoading || status === 'APPROVED'}
                  startIcon={<CheckCircleIcon />}
                  data-testid="approve-button"
                >
                  {t('timesheet.form.buttons.approve')}
                </Button>
              )}
              {!isUserOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                  disabled={status === 'APPROVED'}
                  startIcon={<SaveIcon />}
                  data-testid="manager-save-draft-button"
                  sx={{
                    fontWeight: 'bold',
                    minWidth: '120px',
                  }}
                >
                  {t('timesheet.form.buttons.save_draft')}
                </Button>
              )}

              {/* Disapprove button - visible to managers+ if status is APPROVED */}
              {!isUserOnly && status === 'APPROVED' && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={onDisapprove}
                  disabled={isLoading}
                  startIcon={<CheckCircleIcon />}
                  data-testid="disapprove-button"
                >
                  {t('timesheet.form.buttons.disapprove')}
                </Button>
              )}
            </Box>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

TimeSheetFormView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  includeWeekends: PropTypes.bool.isRequired,
  onToggleWeekends: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  workingDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  selectedMissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        color: PropTypes.string,
        invoicable: PropTypes.bool.isRequired,
      }).isRequired,
    })
  ).isRequired,
  timeEntries: PropTypes.object.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onDeleteMission: PropTypes.func.isRequired,
  remainingMissions: PropTypes.array.isRequired,
  openDialog: PropTypes.bool.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onAddMission: PropTypes.func.isRequired,
  kpis: PropTypes.shape({
    totalWorkingDays: PropTypes.number.isRequired,
    optionalDays: PropTypes.number.isRequired,
    totalDaysInputted: PropTypes.number.isRequired,
    completionPercentage: PropTypes.number.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  selectedMonth: PropTypes.instanceOf(Date).isRequired,
  onMonthChange: PropTypes.func.isRequired,
  timeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  userEmail: PropTypes.string,
  viewMode: PropTypes.oneOf(['days', 'weeks']),
  onViewModeChange: PropTypes.func.isRequired,
  nonWorkingDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isSaved: PropTypes.bool.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    userEmail: PropTypes.string,
  }),
  isValidated: PropTypes.bool.isRequired,
  isUserOnly: PropTypes.bool.isRequired,
  availableUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
    })
  ).isRequired,
  onUserChange: PropTypes.func.isRequired,
  monthClosure: PropTypes.instanceOf(Date),
  comments: PropTypes.string,
  onCommentsChange: PropTypes.func.isRequired,
  timesheetId: PropTypes.string,
  onValidate: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDisapprove: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['PENDING', 'VALIDATED', 'APPROVED']).isRequired,
  isManagerOrAbove: PropTypes.bool.isRequired,
  onConvertToDraft: PropTypes.func.isRequired,
};

export default TimeSheetFormView;
