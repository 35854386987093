import React from 'react';
import { Layout } from './components/layout';
import AppRoutes from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './i18n';
import { ThemeProvider } from './contexts/theme/ThemeProvider';

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Layout>
          <AppRoutes />
        </Layout>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
