import React from 'react';
import { useTheme } from '@mui/material/styles';
import { DarkMode, LightMode } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const ThemeSwitcher = ({ onToggleTheme }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <IconButton
      onClick={onToggleTheme}
      aria-label="Toggle dark mode"
      title="Toggle dark mode"
      sx={{
        color: 'text.primary',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      {isDark ? <LightMode /> : <DarkMode />}
    </IconButton>
  );
};

ThemeSwitcher.propTypes = {
  onToggleTheme: PropTypes.func,
};

export default ThemeSwitcher;
