/**
 * @component NonWorkingDaysTab
 * @description Tab component for managing non-working days.
 * Allows adding and removing specific dates from the calendar.
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import { parseISO, isValid, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {Object} NonWorkingDaysTabProps
 * @property {string[]} days - Array of non-working dates in YYYY-MM-DD format
 * @property {Function} onAdd - Handler for adding new non-working day
 * @property {Function} onDelete - Handler for removing non-working day
 * @property {Date} selectedYear - The selected year for validation
 */
const NonWorkingDaysTab = ({ days = [], onAdd, onDelete, selectedYear }) => {
  const { t } = useTranslation();

  const handleDateChange = date => {
    if (!date || !isValid(date)) {
      return;
    }

    const formattedDate = format(date, 'yyyy-MM-dd');

    // Check if date already exists
    if (days.includes(formattedDate)) {
      onAdd(null, 'Date already exists');
      return;
    }

    // Check if date is in selected year
    if (date.getFullYear().toString() !== selectedYear.getFullYear().toString()) {
      onAdd(null, 'Date is not in the selected year');
      return;
    }

    onAdd(formattedDate);
  };

  const sortedDays = useMemo(() => {
    const sorted = [...days].sort((a, b) => parseISO(b).getTime() - parseISO(a).getTime());
    return sorted;
  }, [days]);

  return (
    <Box sx={{ p: 3 }} data-testid="non-working-days-container">
      <Typography variant="h6" gutterBottom>
        {t('admin.calendar.non_working_days.title')}
      </Typography>

      {/* Add new date */}
      <Box sx={{ mb: 3 }}>
        <DatePicker
          label={t('admin.calendar.non_working_days.date')}
          onChange={handleDateChange}
          value={null} // Reset value after each selection
          slotProps={{
            textField: {
              size: 'small',
              sx: { width: 200 },
              inputProps: {
                'data-testid': 'non-working-day-picker-input',
              },
            },
            openPickerButton: {
              'data-testid': 'non-working-day-picker-button',
            },
            popper: {
              'data-testid': 'non-working-day-picker-popper',
            },
          }}
        />
      </Box>

      {/* List of dates */}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('admin.calendar.non_working_days.date')}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="non-working-days-tbody">
            {days.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} align="center" data-testid="no-dates-message">
                  {t('admin.calendar.non_working_days.no_dates')}
                </TableCell>
              </TableRow>
            ) : (
              sortedDays.map(date => (
                <TableRow key={date} data-testid="non-working-day-row">
                  <TableCell data-testid="non-working-days">
                    {format(parseISO(date), 'PP')}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => onDelete(date)}
                      size="small"
                      color="error"
                      data-testid="delete-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

NonWorkingDaysTab.propTypes = {
  days: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedYear: PropTypes.instanceOf(Date).isRequired,
};

export default NonWorkingDaysTab;
