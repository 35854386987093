/**
 * @component UserManagementView
 * @description Presentation component for user management interface.
 * Renders tab navigation and corresponding content components.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  ButtonGroup,
  Tooltip,
} from '@mui/material';
import {
  PersonAdd as PersonAddIcon,
  Email as EmailIcon,
  Settings as SettingsIcon,
  ManageAccounts as ManageAccountsIcon,
  PeopleOutline as PeopleOutlineIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  People as PeopleIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import EmployeeSetupDialog from '../users/EmployeeSetupDialog';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import StatCard from '../common/StatCard';

/**
 * @typedef {Object} UserManagementViewProps
 * @property {Array} users - Complete list of users
 * @property {boolean} openInviteDialog - Invite dialog visibility state
 * @property {string} inviteEmail - New user email input
 * @property {string} searchTerm - Current search filter
 * @property {string} roleFilter - Current role filter
 * @property {string} statusFilter - Current status filter
 * @property {number} page - Current page number
 * @property {number} rowsPerPage - Items per page
 * @property {Array} paginatedUsers - Users for current page
 * @property {Array} filteredUsers - All filtered users
 * @property {Function} onInviteEmailChange - Email input handler
 * @property {Function} onSearchChange - Search filter handler
 * @property {Function} onRoleFilterChange - Role filter handler
 * @property {Function} onStatusFilterChange - Status filter handler
 * @property {Function} onToggleManagerRole - Role toggle handler
 * @property {Function} onResendWelcome - Welcome email resend handler
 * @property {Function} onOpenInviteDialog - Dialog open handler
 * @property {Function} onCloseInviteDialog - Dialog close handler
 * @property {Function} onInviteSubmit - Invite submission handler
 * @property {Function} onPageChange - Pagination handler
 * @property {Function} onSetupSuperAdmin - Super admin setup handler
 * @property {boolean} openSetupDialog - Setup dialog visibility state
 * @property {object} selectedUser - Selected user for setup
 * @property {Function} onOpenSetupDialog - Dialog open handler for setup
 * @property {Function} onCloseSetupDialog - Dialog close handler for setup
 * @property {Function} onSaveSetup - Setup save handler
 */
const UserManagementView = ({
  users,
  openInviteDialog,
  openSetupDialog,
  inviteEmail,
  searchTerm,
  roleFilter,
  statusFilter,
  page,
  rowsPerPage,
  paginatedUsers,
  filteredUsers,
  currentUserRole,
  selectedUser,
  onInviteEmailChange,
  onSearchChange,
  onRoleFilterChange,
  onStatusFilterChange,
  onToggleManagerRole,
  onResendWelcome,
  onOpenInviteDialog,
  onCloseInviteDialog,
  onInviteSubmit,
  onPageChange,
  onOpenSetupDialog,
  onCloseSetupDialog,
  onSaveSetup,
}) => {
  const { t } = useTranslation();

  const getNextRoleAction = (currentRole, currentUserRole) => {
    switch (currentRole) {
      case 'user':
        return 'make_manager';
      case 'manager':
        return currentUserRole === 'superadmin' ? 'make_rh' : 'make_user';
      case 'rh':
        return 'make_user';
      default:
        return 'make_user';
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            {t('admin.users.title')}
          </Typography>
          <Box sx={{ mb: 3, mt: 2 }}>
            <Grid container spacing={3}>
              {/* Active Users KPI */}
              <Grid item xs={12} sm={4}>
                <StatCard
                  icon={<PersonIcon />}
                  title={t('admin.users.kpi.active_users')}
                  value={users.filter(user => user.isActive !== false).length}
                  subtitle={t('admin.users.kpi.active_users_subtitle')}
                />
              </Grid>

              {/* Regular Users KPI */}
              <Grid item xs={12} sm={4}>
                <StatCard
                  icon={<GroupIcon />}
                  title={t('admin.users.kpi.regular_users')}
                  value={
                    users.filter(user => user.isActive !== false && user.role === 'user').length
                  }
                  subtitle={t('admin.users.kpi.regular_users_subtitle')}
                />
              </Grid>

              {/* Total Users KPI */}
              <Grid item xs={12} sm={4}>
                <StatCard
                  icon={<PeopleIcon />}
                  title={t('admin.users.kpi.total_users')}
                  value={users.length}
                  subtitle={t('admin.users.kpi.total_users_subtitle')}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={2} alignItems="center">
              {/* Search field */}
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label={t('admin.users.filters.search')}
                  value={searchTerm}
                  onChange={e => onSearchChange(e.target.value)}
                />
              </Grid>
              {/* Role filter */}
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="role-filter-label">{t('admin.users.filters.role')}</InputLabel>
                  <Select
                    labelId="role-filter-label"
                    value={roleFilter}
                    label={t('admin.users.filters.role')}
                    onChange={e => onRoleFilterChange(e.target.value)}
                  >
                    <MenuItem value="">{t('admin.users.filters.all_roles')}</MenuItem>
                    <MenuItem value="user">{t('admin.users.roles.user')}</MenuItem>
                    <MenuItem value="manager">{t('admin.users.roles.manager')}</MenuItem>
                    {currentUserRole === 'superadmin' && (
                      <MenuItem value="superadmin">{t('admin.users.roles.superadmin')}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* Status filter */}
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="status-filter-label">
                    {t('admin.users.filters.status')}
                  </InputLabel>
                  <Select
                    labelId="status-filter-label"
                    value={statusFilter}
                    label={t('admin.users.filters.status')}
                    onChange={e => onStatusFilterChange(e.target.value)}
                  >
                    <MenuItem value="">{t('admin.users.filters.all_status')}</MenuItem>
                    <MenuItem value="active">{t('admin.users.fields.active')}</MenuItem>
                    <MenuItem value="inactive">{t('admin.users.fields.inactive')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Invite button */}
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  startIcon={<PersonAddIcon />}
                  onClick={onOpenInviteDialog}
                >
                  {t('admin.users.invite.button')}
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Empty state or table */}
          {paginatedUsers.length === 0 ? (
            <Paper
              sx={{
                p: 4,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <PeopleOutlineIcon sx={{ fontSize: 64, color: 'text.secondary' }} />
              <Typography variant="h6" color="text.secondary">
                {t('admin.users.empty.title')}
              </Typography>
              <Typography color="text.secondary">{t('admin.users.empty.description')}</Typography>
              <Button
                variant="contained"
                startIcon={<PersonAddIcon />}
                onClick={onOpenInviteDialog}
              >
                {t('admin.users.invite.button')}
              </Button>
            </Paper>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('admin.users.columns.email')}</TableCell>
                    <TableCell>{t('admin.users.columns.role')}</TableCell>
                    <TableCell>{t('admin.users.columns.status')}</TableCell>
                    <TableCell>{t('admin.users.columns.last_login')}</TableCell>
                    <TableCell>{t('admin.users.columns.days_since_login')}</TableCell>
                    <TableCell align="right">{t('common.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedUsers.map(user => (
                    <TableRow key={user.email.toLowerCase().replace('@', '_at_')}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Typography>
                            {t(`admin.users.roles.${user.role.toLowerCase()}`)}
                          </Typography>
                          {user.role !== 'superadmin' && (
                            <ButtonGroup size="small">
                              <Tooltip
                                title={t(
                                  `admin.users.actions.${getNextRoleAction(user.role, currentUserRole)}`
                                )}
                              >
                                <Button
                                  data-testid={`role-toggle-${user.role}`}
                                  onClick={() =>
                                    onToggleManagerRole(
                                      user.email.toLowerCase().replace('@', '_at_'),
                                      user.role
                                    )
                                  }
                                  startIcon={<ManageAccountsIcon />}
                                  variant="outlined"
                                  size="small"
                                >
                                  {t(
                                    `admin.users.actions.${getNextRoleAction(user.role, currentUserRole)}`
                                  )}
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch checked={user.isActive !== false} disabled size="small" />
                          }
                          label={t(
                            user.isActive !== false
                              ? 'admin.users.fields.active'
                              : 'admin.users.fields.inactive'
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          {user.formattedLastLogin !== 'Never' && (
                            <Typography>{user.formattedLastLogin}</Typography>
                          )}
                          {user.formattedLastLogin === 'Never' && user.role !== 'superadmin' && (
                            <Tooltip title={t('admin.users.actions.resend_welcome_tooltip')}>
                              <Button
                                onClick={() => onResendWelcome(user.email)}
                                size="small"
                                startIcon={<EmailIcon />}
                                variant="outlined"
                              >
                                {t('admin.users.actions.resend_welcome')}
                              </Button>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {user.lastLoginDays === null
                          ? t('common.never')
                          : t('common.days_ago', { days: user.lastLoginDays })}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={t('admin.users.actions.setup_tooltip')}>
                          <Button
                            onClick={() => onOpenSetupDialog(user)}
                            variant="outlined"
                            size="small"
                            startIcon={<SettingsIcon />}
                          >
                            {t('admin.users.actions.setup')}
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination - only show if we have users */}
          {paginatedUsers.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              labelRowsPerPage={t('common.pagination.rows_per_page')}
              labelDisplayedRows={({ from, to, count }) =>
                t('common.pagination.range', { from, to, count })
              }
            />
          )}

          {/* Invite dialog */}
          <Dialog open={openInviteDialog} onClose={onCloseInviteDialog}>
            <DialogTitle>{t('admin.users.invite.title')}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label={t('admin.users.invite.email')}
                type="email"
                fullWidth
                value={inviteEmail}
                onChange={e => onInviteEmailChange(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCloseInviteDialog}>{t('common.cancel')}</Button>
              <Button onClick={onInviteSubmit} variant="contained">
                {t('admin.users.invite.button')}
              </Button>
            </DialogActions>
          </Dialog>

          <EmployeeSetupDialog
            open={openSetupDialog}
            onClose={onCloseSetupDialog}
            onSave={onSaveSetup}
            initialData={selectedUser}
          />
        </Box>
      </Container>
    </LocalizationProvider>
  );
};

// PropTypes validation
UserManagementView.propTypes = {
  users: PropTypes.array.isRequired,
  openInviteDialog: PropTypes.bool.isRequired,
  openSetupDialog: PropTypes.bool.isRequired,
  inviteEmail: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  roleFilter: PropTypes.string.isRequired,
  statusFilter: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  paginatedUsers: PropTypes.array.isRequired,
  filteredUsers: PropTypes.array.isRequired,
  currentUserRole: PropTypes.string,
  selectedUser: PropTypes.object,
  onInviteEmailChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onRoleFilterChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  onToggleManagerRole: PropTypes.func.isRequired,
  onResendWelcome: PropTypes.func.isRequired,
  onOpenInviteDialog: PropTypes.func.isRequired,
  onCloseInviteDialog: PropTypes.func.isRequired,
  onInviteSubmit: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onOpenSetupDialog: PropTypes.func.isRequired,
  onCloseSetupDialog: PropTypes.func.isRequired,
  onSaveSetup: PropTypes.func.isRequired,
};

export default UserManagementView;
