/**
 * @component MissionDialogContainer
 * @description Container component for mission creation/editing dialog.
 * Manages form state and user data loading for mission configuration.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase/config';
import MissionDialogView from './MissionDialogView';

/**
 * @typedef {Object} MissionDialogContainerProps
 * @property {boolean} open - Dialog visibility state
 * @property {Function} onClose - Dialog close handler
 * @property {Function} onSave - Mission save handler
 * @property {Object} mission - Mission data for editing (null for creation)
 */
const MissionDialogContainer = ({
  open = false,
  onClose = () => {},
  onSave = () => {},
  mission = null,
}) => {
  // State management
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    totalDays: 0,
    dailyRate: 0,
    authorizedUsers: [],
    isAvailableToEveryone: true,
  });

  /**
   * Loads user list from Firestore when dialog opens
   */
  useEffect(() => {
    const loadUsers = async () => {
      try {
        const usersQuery = query(collection(db, 'users'));
        const snapshot = await getDocs(usersQuery);
        const userEmails = snapshot.docs.map(doc => doc.data().email);
        setUsers(userEmails);
      } catch (error) {
        console.error('Error loading users:', error);
      }
    };

    if (open) {
      loadUsers();
    }
  }, [open]);

  /**
   * Initializes form data when editing existing mission
   */
  useEffect(() => {
    if (mission) {
      setFormData({
        name: mission.name || '',
        description: mission.description || '',
        totalDays: mission.totalDays || 0,
        dailyRate: mission.dailyRate || 0,
        authorizedUsers: mission.authorizedUsers || [],
        isAvailableToEveryone: mission.isAvailableToEveryone ?? true,
      });
    } else {
      setFormData({
        name: '',
        description: '',
        totalDays: 0,
        dailyRate: 0,
        authorizedUsers: [],
        isAvailableToEveryone: true,
      });
    }
  }, [mission]);

  /**
   * Updates form field value
   * @param {string} field - Field name to update
   * @param {any} value - New field value
   */
  const handleFormChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  /**
   * Handles form submission
   * @param {Event} e - Form submission event
   */
  const handleSubmit = e => {
    e.preventDefault();
    onSave({
      ...formData,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  };

  return (
    <MissionDialogView
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      formData={formData}
      onFormChange={handleFormChange}
      users={users}
      isEdit={!!mission}
    />
  );
};

// PropTypes validation
MissionDialogContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  mission: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    totalDays: PropTypes.number,
    dailyRate: PropTypes.number,
    authorizedUsers: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default MissionDialogContainer;
