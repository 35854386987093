import React, { useState, useEffect, useMemo } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, getDocs, doc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useToast } from '../../contexts/toast';
import BackupView from './BackupView';
import { useTranslation } from 'react-i18next';

/**
 * @component BackupContainer
 * @description Container component for database backup functionality.
 * Handles data fetching and export operations.
 */
const BackupContainer = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();
  const { t } = useTranslation();
  const functions = getFunctions();

  const getCollectionStats = useMemo(
    () => httpsCallable(functions, 'getCollectionStats'),
    [functions]
  );

  // Fetch collection stats on mount
  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const result = await getCollectionStats();
        setCollections(result.data.collections);
      } catch (error) {
        console.error('Error fetching collection stats:', error);
        showToast(t('admin.database.errors.stats_loading'), 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [getCollectionStats, showToast, t]);

  const handleExportDatabase = async () => {
    try {
      setLoading(true);
      const exportData = {};

      for (const col of collections) {
        const snapshot = await getDocs(collection(db, col.name));
        exportData[col.name] = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
      }

      const dataStr = JSON.stringify(exportData, null, 2);
      const dataBlob = new Blob([dataStr], { type: 'application/json' });
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `database-backup-${new Date().toISOString()}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      showToast(t('admin.database.messages.backup_success'), 'success');
    } catch (error) {
      console.error('Error exporting database:', error);
      showToast(t('admin.database.errors.backup_failed'), 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleImportDatabase = async importData => {
    try {
      setLoading(true);

      // Process each collection in the import file
      for (const [collectionName, documents] of Object.entries(importData)) {
        const batch = writeBatch(db);
        const collectionRef = collection(db, collectionName);

        // Then add new documents
        documents.forEach(document => {
          const docRef = doc(collectionRef, document.id);
          const { id, ...docData } = document;
          batch.set(docRef, docData);
        });

        // Commit the batch
        await batch.commit();
      }

      // Refresh collection stats
      const result = await getCollectionStats();
      setCollections(result.data.collections);

      showToast(t('admin.database.messages.import_success'), 'success');
    } catch (error) {
      console.error('Error importing database:', error);
      showToast(t('admin.database.errors.import_failed'), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <BackupView
      collections={collections}
      onExportDatabase={handleExportDatabase}
      onImportDatabase={handleImportDatabase}
      loading={loading}
    />
  );
};

export default BackupContainer;
