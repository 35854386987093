/**
 * @fileoverview Theme context and hook definition
 */
import { createContext, useContext } from 'react';

/**
 * Context for theme state and methods
 * @type {React.Context}
 */
export const ThemeContext = createContext({
  mode: 'light',
  toggleTheme: () => {},
});

/**
 * Custom hook for accessing theme context
 * @returns {Object} Theme context value with mode and toggle function
 * @throws {Error} If used outside of ThemeProvider
 */
export const useThemeMode = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeMode must be used within a ThemeProvider');
  }
  return context;
};
