/**
 * @typedef {Object} AggregationOptions
 * @property {boolean} includeYearlyData - Whether to include yearly aggregates
 * @property {boolean} includeWeeklyData - Whether to include weekly aggregates
 * @property {string} [year] - Year for yearly aggregation
 */

/**
 * Aggregates mission data with configurable options
 * @param {Object} missionDetails - Raw mission data
 * @param {AggregationOptions} options - Aggregation options
 * @returns {Object} Aggregated mission data
 */
const aggregateMissionData = (missionDetails, options) => {
  const aggregated = {};

  Object.entries(missionDetails).forEach(([missionId, mission]) => {
    aggregated[missionId] = {
      name: mission.name,
      totalDays: 0,
      monthlyData: {},
      ...(options.includeYearlyData ? { yearlyData: {} } : {}),
      ...(options.includeWeeklyData ? { weeklyData: {} } : {}),
    };

    // Aggregate monthly data
    if (mission.monthlyData) {
      aggregated[missionId].monthlyData = { ...mission.monthlyData };
      aggregated[missionId].totalDays = Object.values(mission.monthlyData).reduce(
        (sum, value) => sum + value,
        0
      );

      // Add yearly data if requested
      if (options.includeYearlyData && options.year) {
        aggregated[missionId].yearlyData[options.year] = aggregated[missionId].totalDays;
      }
    }
  });

  return aggregated;
};

/**
 * Aggregates project data with configurable options
 * @param {Object} projects - Raw project data
 * @param {AggregationOptions} options - Aggregation options
 * @returns {Object} Aggregated project data
 */
export const aggregateProjectData = (projects, options = {}) => {
  const aggregated = {};

  Object.entries(projects).forEach(([projectId, project]) => {
    aggregated[projectId] = {
      id: projectId,
      name: project.name || 'Unknown Project',
      totalDays: 0,
      monthlyData: {},
      missionDetails: {},
      ...(options.includeYearlyData ? { yearlyData: {} } : {}),
      ...(options.includeWeeklyData ? { weeklyData: {} } : {}),
    };

    // Process mission details if present
    if (project.missionDetails) {
      aggregated[projectId].missionDetails = aggregateMissionData(project.missionDetails, options);
    }

    // Aggregate monthly data
    if (project.monthlyData) {
      aggregated[projectId].monthlyData = { ...project.monthlyData };
      aggregated[projectId].totalDays = Object.values(project.monthlyData).reduce(
        (sum, value) => sum + value,
        0
      );

      // Add yearly data if requested
      if (options.includeYearlyData && options.year) {
        aggregated[projectId].yearlyData[options.year] = aggregated[projectId].totalDays;
      }
    }
  });

  return aggregated;
};

/**
 * Aggregates timesheet entries by project
 * @param {Array} timesheets - Array of timesheet entries
 * @returns {Object} Aggregated timesheet data by project
 */
export const aggregateTimesheetsByProject = timesheets => {
  const grouped = timesheets.reduce((acc, timesheet) => {
    const transformedTimesheet = transformTimesheetData(timesheet);
    const projects = transformedTimesheet.projects || [];

    projects.forEach(project => {
      if (!project?.id) return;

      // Initialize project entry if needed
      if (!acc[project.id]) {
        acc[project.id] = initializeProjectAggregate(project);
      }

      // Process timesheet entries
      const projectData = processTimesheetEntries(
        transformedTimesheet.timeEntries,
        project,
        transformedTimesheet
      );

      // Update project aggregates
      acc[project.id].totalDays += projectData.totalDays;
      acc[project.id].timesheets.push({
        id: transformedTimesheet.id,
        userEmail: transformedTimesheet.userEmail,
        totalDays: projectData.totalDays,
        status: transformedTimesheet.status,
        startDate: transformedTimesheet.createdAt?.toDate(),
        endDate: transformedTimesheet.updatedAt?.toDate(),
      });
    });

    return acc;
  }, {});

  return grouped;
};

// Helper functions
const initializeProjectAggregate = project => ({
  projectName: project.name || 'Unknown Project',
  timesheets: [],
  totalDays: 0,
});

const processTimesheetEntries = (timeEntries, project, timesheet) => {
  const entries = timeEntries || {};
  let totalDays = 0;

  Object.entries(entries).forEach(([entryId, entry]) => {
    if (isProjectMission(entryId, project)) {
      totalDays += calculateEntryDays(entry);
    }
  });

  return { totalDays };
};

const isProjectMission = (entryId, project) => {
  return project.missions?.some(mission => entryId.startsWith(`${project.id}_`));
};

const calculateEntryDays = entry => {
  return Object.values(entry).reduce((sum, value) => {
    const days = parseFloat(value);
    return sum + (isNaN(days) ? 0 : days);
  }, 0);
};

// Add this function to transform timesheet data
export const transformTimesheetData = timesheet => {
  if (!timesheet.timeEntries) return timesheet;

  // Convert timeEntries map to days array
  const days = Object.entries(timesheet.timeEntries).map(([date, value]) => ({
    date,
    value: Number(value), // Convert string "1" to number 1
  }));

  return {
    ...timesheet,
    days,
  };
};
