/**
 * @component ManagementContainer
 * @description Container component for project management functionality.
 * Handles project data fetching, filtering, and state management.
 */
import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useNavigate } from 'react-router-dom';
import ManagementView from './ManagementView';
import { ProjectDialog } from './index';
import PropTypes from 'prop-types';

const ManagementContainer = ({ initialView = 'list', defaultFilters = {} }) => {
  const navigate = useNavigate();

  // State management
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const rowsPerPage = 10;

  /**
   * Fetches projects from Firestore and sets up real-time listener
   */
  useEffect(() => {
    const q = query(collection(db, 'projects'));

    const unsubscribe = onSnapshot(
      q,
      querySnapshot => {
        const projectsData = querySnapshot.docs.map(doc => {
          const data = doc.data();

          let createdAtDate = null;
          if (data.createdAt) {
            if (data.createdAt instanceof Timestamp) {
              createdAtDate = data.createdAt.toDate();
            } else if (data.createdAt._seconds) {
              createdAtDate = new Date(data.createdAt._seconds * 1000);
            } else if (data.createdAt.seconds) {
              createdAtDate = new Date(data.createdAt.seconds * 1000);
            }
          }

          return {
            id: doc.id,
            ...data,
            createdAt: createdAtDate,
          };
        });
        setProjects(projectsData);
        setLoading(false);
      },
      error => {
        setError('Error fetching projects');
        setLoading(false);
      }
    );

    return unsubscribe;
  }, []);

  /**
   * Opens project dialog for creation/editing
   * @param {Object|null} project - Project to edit, null for creation
   */
  const handleOpenDialog = (project = null) => {
    setSelectedProject(project);
    setOpenDialog(true);
  };

  /**
   * Closes project dialog and resets selection
   */
  const handleCloseDialog = () => {
    setSelectedProject(null);
    setOpenDialog(false);
  };

  /**
   * Filters projects based on search term and status
   */
  const filteredProjects = projects.filter(project => {
    const matchesSearch =
      project.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.name?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStatus =
      statusFilter === 'all' || project.status?.toLowerCase() === statusFilter.toLowerCase();

    return matchesSearch && matchesStatus;
  });

  // Get paginated subset of filtered projects
  const paginatedProjects = filteredProjects.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <ManagementView
        loading={loading}
        error={error}
        searchTerm={searchTerm}
        statusFilter={statusFilter}
        page={page}
        rowsPerPage={rowsPerPage}
        paginatedProjects={paginatedProjects}
        filteredProjects={filteredProjects}
        onSearchChange={setSearchTerm}
        onStatusFilterChange={setStatusFilter}
        onPageChange={(event, newPage) => setPage(newPage)}
        onOpenDialog={handleOpenDialog}
        onNavigateToReview={() => navigate('/timesheet/review')}
      />
      <ProjectDialog open={openDialog} onClose={handleCloseDialog} project={selectedProject} />
    </>
  );
};

ManagementContainer.propTypes = {
  initialView: PropTypes.oneOf(['list', 'grid']),
  defaultFilters: PropTypes.object,
};

export default ManagementContainer;
