/**
 * @component UserManagement
 * @description Container component for user management functionality.
 * Handles user data fetching, filtering, and role management.
 */
import React, { useState, useEffect, useMemo } from 'react';
import { collection, query, onSnapshot, doc, runTransaction, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../../firebase/config';
import { useToast } from '../../contexts/toast';
import UserManagementView from './UserManagementView';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase/config';
import { useAuth } from '../../contexts/auth';

const UserManagement = () => {
  // State management for users and UI controls
  const [users, setUsers] = useState([]);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [openSetupDialog, setOpenSetupDialog] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [selectedUser, setSelectedUser] = useState(null);

  const { showToast } = useToast();
  const functions = getFunctions();
  const { user } = useAuth();

  /**
   * Fetches and subscribes to user data from Firestore
   * Updates local state when user data changes
   */
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, 'users')),
      snapshot => {
        const userData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userData);
      },
      error => {
        console.error('Error fetching users:', error);
        showToast('Error loading users', 'error');
      }
    );

    return () => unsubscribe();
  }, [showToast]);

  /**
   * Filters users based on search term and role filter
   * @type {Array} Filtered user list
   */
  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      const matchesSearch = user.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesRole = !roleFilter || user.role === roleFilter;
      const matchesStatus =
        !statusFilter ||
        (statusFilter === 'active' ? user.isActive !== false : user.isActive === false);

      // Add last login information
      const lastLogin = user.lastLoginAt ? new Date(user.lastLoginAt.seconds * 1000) : null;
      const daysSinceLastLogin = lastLogin
        ? Math.floor((new Date() - lastLogin) / (1000 * 60 * 60 * 24))
        : null;

      user.lastLoginDays = daysSinceLastLogin;
      user.formattedLastLogin = lastLogin ? lastLogin.toLocaleDateString() : 'Never';

      return matchesSearch && matchesRole && matchesStatus;
    });
  }, [users, searchTerm, roleFilter, statusFilter]);

  /**
   * Paginates filtered users
   * @type {Array} Users for current page
   */
  const paginatedUsers = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return filteredUsers.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredUsers, page]);

  /**
   * Toggles user's manager role status
   * @param {string} userId - User ID to update
   * @param {string} currentRole - Current user role
   */
  const handleToggleRole = async (userId, currentRole) => {
    try {
      const roleHierarchy = {
        user: 0,
        manager: 1,
        rh: 2,
        superadmin: 3,
      };

      const currentUserRole = user?.role || 'user';
      const currentUserLevel = roleHierarchy[currentUserRole];

      let nextRole;
      switch (currentRole) {
        case 'user':
          nextRole = 'manager';
          break;
        case 'manager':
          // If current user is superadmin, allow setting RH role
          nextRole = currentUserLevel === roleHierarchy.superadmin ? 'rh' : 'user';
          break;
        case 'rh':
          nextRole = 'user';
          break;
        default:
          nextRole = 'user';
      }

      // Check if user has permission to set this role
      if (roleHierarchy[nextRole] >= currentUserLevel) {
        showToast('You do not have permission to set this role', 'error');
        return;
      }

      await runTransaction(db, async transaction => {
        const userRef = doc(db, 'users', userId);
        transaction.update(userRef, {
          role: nextRole,
          updatedAt: new Date(),
          performedBy: user.email,
        });
      });
      showToast(`User role updated to ${nextRole}`, 'success');
    } catch (error) {
      console.error('Error updating role:', error);
      showToast('Error updating user role', 'error');
    }
  };

  /**
   * Sends password reset email to user
   * @param {string} email - User's email address
   */
  const handleResetPassword = async email => {
    try {
      await sendPasswordResetEmail(auth, email);
      showToast('Password reset email sent', 'success');
    } catch (error) {
      console.error('Error resetting password:', error);
      showToast('Error: ' + error.message, 'error');
    }
  };

  // Add email validation function
  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  /**
   * Handles new user invitation submission
   * Calls Firebase function to send invitation email
   */
  const handleInviteSubmit = async () => {
    try {
      const inviteUser = httpsCallable(functions, 'inviteUser');

      if (!isValidEmail(inviteEmail)) {
        showToast('Invalid email', 'error');
        throw new Error('Invalid email');
      }

      await inviteUser({
        email: inviteEmail,
        role: 'user',
        performedBy: user.email,
        isActive: true,
      });

      showToast('Invitation sent successfully', 'success');
      setInviteEmail('');
      setOpenInviteDialog(false);
    } catch (error) {
      console.error('Error sending invitation:', error);
      showToast('Error sending invitation: ' + (error.message || 'Unknown error'), 'error');
    }
  };

  /**
   * Sets up a super admin user
   */
  const handleSetupSuperAdmin = async () => {
    if (!user?.username) return;

    try {
      await runTransaction(db, async transaction => {
        const userId = user.username.toLowerCase().replace('@', '_at_');
        const userRef = doc(db, 'users', userId);
        const userDoc = await transaction.get(userRef);

        if (!userDoc.exists()) {
          transaction.set(userRef, {
            email: user.username,
            name: user.name,
            role: 'superadmin',
            isActive: true,
            createdAt: new Date(),
            performedBy: user.email,
          });
          showToast('Super admin setup completed successfully', 'success');
        } else {
          showToast('User already exists', 'error');
        }
      });
    } catch (err) {
      console.error('Setup error:', err);
      showToast(err.message || 'Failed to setup super admin', 'error');
    }
  };

  /**
   * Handles pagination changes
   * @param {Event} event - Change event
   * @param {number} newPage - New page number
   */
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleResendWelcome = async email => {
    try {
      const inviteUser = httpsCallable(functions, 'inviteUser');
      await inviteUser({
        email,
        role: 'user', // Keep existing role
      });
      showToast('Welcome email resent successfully', 'success');
    } catch (error) {
      console.error('Error resending welcome email:', error);
      showToast('Error resending welcome email: ' + error.message, 'error');
    }
  };

  const handleOpenSetupDialog = user => {
    // Convert Firestore Timestamps to Date objects if they exist
    const dateOfBirth = user.dateOfBirth?.toDate ? user.dateOfBirth.toDate() : null;
    const entryDate = user.entryDate?.toDate ? user.entryDate.toDate() : null;

    setSelectedUser({
      id: user.email.toLowerCase().replace('@', '_at_'),
      email: user.email,
      dateOfBirth: dateOfBirth,
      entryDate: entryDate,
      contractType: user.contractType || 'FULL_TIME',
      isActive: user.isActive !== false,
    });
    setOpenSetupDialog(true);
  };

  const handleCloseSetupDialog = () => {
    setOpenSetupDialog(false);
    setSelectedUser(null);
  };

  const handleSaveSetup = async data => {
    try {
      if (!data.id) {
        throw new Error('Employee ID is missing');
      }

      // Format dates for Firestore
      const formattedData = {
        ...data,
        // Convert to Date objects only if they exist
        dateOfBirth: data.dateOfBirth instanceof Date ? data.dateOfBirth : null,
        entryDate: data.entryDate instanceof Date ? data.entryDate : null,
        updatedAt: new Date(),
        updatedBy: user.email,
      };

      // Remove id and email from the data to be saved
      delete formattedData.id;
      delete formattedData.email;

      const userRef = doc(db, 'users', data.id);
      await updateDoc(userRef, formattedData);

      showToast('Employee setup updated successfully', 'success');
      setOpenSetupDialog(false);
    } catch (err) {
      console.error('Error updating employee setup:', err);
      showToast('Error updating employee setup', 'error');
    }
  };

  return (
    <UserManagementView
      users={users}
      openInviteDialog={openInviteDialog}
      openSetupDialog={openSetupDialog}
      inviteEmail={inviteEmail}
      searchTerm={searchTerm}
      roleFilter={roleFilter}
      statusFilter={statusFilter}
      page={page}
      rowsPerPage={rowsPerPage}
      paginatedUsers={paginatedUsers}
      filteredUsers={filteredUsers}
      currentUserRole={user?.role}
      selectedUser={selectedUser}
      onInviteEmailChange={setInviteEmail}
      onSearchChange={setSearchTerm}
      onRoleFilterChange={setRoleFilter}
      onStatusFilterChange={setStatusFilter}
      onToggleManagerRole={handleToggleRole}
      onResetPassword={handleResetPassword}
      onOpenInviteDialog={() => setOpenInviteDialog(true)}
      onCloseInviteDialog={() => setOpenInviteDialog(false)}
      onInviteSubmit={handleInviteSubmit}
      onPageChange={handlePageChange}
      onSetupSuperAdmin={handleSetupSuperAdmin}
      onResendWelcome={handleResendWelcome}
      onOpenSetupDialog={handleOpenSetupDialog}
      onCloseSetupDialog={handleCloseSetupDialog}
      onSaveSetup={handleSaveSetup}
    />
  );
};

export default UserManagement;
