/**
 * @component TimeSheetReview
 * @description Container component for reviewing and validating timesheets.
 * Manages the approval/rejection workflow and data fetching.
 */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { collection, query, getDocs, orderBy, where, limit, startAfter } from 'firebase/firestore';
import { db, functions } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { useToast } from '../../contexts/toast';
import TimeSheetReviewView from './TimeSheetReviewView';
import { useNavigate } from 'react-router-dom';
import { startOfMonth, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

// Main component for timesheet review functionality
const TimeSheetReview = () => {
  // Store mutable references to avoid dependency cycles
  const loadingRef = useRef(false);
  const pageRef = useRef(0);
  const cursorsRef = useRef({});

  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [statusManagerFilter, setStatusManagerFilter] = useState('ALL');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(new Date()));
  const [hasMore, setHasMore] = useState(true);
  const [showAllTimesheets, setShowAllTimesheets] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [usersWithoutTimesheet, setUsersWithoutTimesheet] = useState([]);
  const [reminderCounts, setReminderCounts] = useState({});

  const { showToast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Memoize query constraints
  const queryConstraints = useMemo(() => {
    const constraints = [];

    if (!showAllTimesheets) {
      const monthString = format(selectedMonth, 'yyyy-MM');
      constraints.push(where('month', '==', monthString));
    }
    console.log('statusFilter', statusFilter?.target?.value);
    if (statusFilter !== 'ALL') {
      constraints.push(where('status', '==', statusFilter));
      console.log('statusFilter', statusFilter);
    }

    if (searchQuery) {
      const searchEnd = searchQuery + '\uf8ff';
      constraints.push(where('userEmail', '>=', searchQuery));
      constraints.push(where('userEmail', '<=', searchEnd));
    }

    constraints.push(orderBy('userEmail'));
    constraints.push(orderBy('month', 'desc'));
    constraints.push(limit(rowsPerPage));

    return constraints;
  }, [showAllTimesheets, selectedMonth, statusFilter, searchQuery, rowsPerPage]);

  const fetchTimesheets = useCallback(
    async (pageNum, shouldReset = false) => {
      if (loadingRef.current) return;

      try {
        loadingRef.current = true;
        setLoading(true);
        const baseQuery = collection(db, 'timesheets');
        const finalConstraints = [...queryConstraints];

        console.log('finalConstraints', finalConstraints);
        // Reset cursors if needed
        if (shouldReset) {
          cursorsRef.current = {};
        }

        // Add cursor if not first page and we have a cursor
        if (pageNum > 0) {
          const prevCursor = cursorsRef.current[pageNum - 1];
          if (!prevCursor) {
            setLoading(false);
            return;
          }
          finalConstraints.push(startAfter(prevCursor));
        }

        const q = query(baseQuery, ...finalConstraints);
        const querySnapshot = await getDocs(q);

        const timesheetData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate(),
            validatedAt: data.validatedAt?.toDate(),
          };
        });

        if (querySnapshot.docs.length > 0) {
          const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
          if (shouldReset) {
            cursorsRef.current = { [pageNum]: lastDoc };
          } else {
            cursorsRef.current[pageNum] = lastDoc;
          }
        }

        setHasMore(querySnapshot.docs.length === rowsPerPage);
        setTimesheets(timesheetData);
      } catch (error) {
        showToast('Error loading timesheets', 'error');
        console.error('Error loading timesheets', error);
      } finally {
        loadingRef.current = false;
        setLoading(false);
      }
    },
    [queryConstraints, rowsPerPage, showToast]
  );

  // Update refs when state changes
  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  // Handle page change
  const handlePageChange = useCallback(
    (event, newPage) => {
      if (loadingRef.current) return;

      setPage(newPage);
      fetchTimesheets(newPage, newPage === 0);
    },
    [fetchTimesheets]
  );

  // Reset everything when filters change
  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(0);
      cursorsRef.current = {};
      fetchTimesheets(0, true);
    }, 300);

    return () => clearTimeout(timer);
  }, [
    statusFilter,
    statusManagerFilter,
    searchQuery,
    selectedMonth,
    showAllTimesheets,
    fetchTimesheets,
  ]);

  // Initial load
  useEffect(() => {
    fetchTimesheets(0, true);
  }, [fetchTimesheets]); // Include fetchTimesheets dependency

  const handleViewTimesheet = useCallback(
    timesheet => {
      navigate(`/timesheet/edit/${timesheet.id}`, {
        state: {
          initialData: timesheet,
          userEmail: timesheet.userEmail,
        },
      });
    },
    [navigate]
  );

  const handleRowsPerPageChange = useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    cursorsRef.current = {};
  }, []);

  const handleStatusFilterChange = useCallback(newStatus => {
    setStatusFilter(newStatus.target.value);
  }, []);

  const handleStatusManagerFilterChange = useCallback(newStatus => {
    setStatusManagerFilter(newStatus);
  }, []);

  const handleSearchQueryChange = useCallback(query => {
    setSearchQuery(query.trim().toLowerCase());
  }, []);

  const handleMonthChange = useCallback(newDate => {
    if (newDate) {
      setSelectedMonth(startOfMonth(newDate));
      // Reset pagination when month changes
      setPage(0);
      cursorsRef.current = {};
    }
  }, []);

  const handleShowAllTimesheetsChange = useCallback(checked => {
    setShowAllTimesheets(checked);
    setPage(0);
    cursorsRef.current = {};
  }, []);

  // Fetch active users
  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('isActive', '==', true));
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setActiveUsers(users);
      } catch (error) {
        console.error('Error fetching active users:', error);
        showToast('Error loading users', 'error');
      }
    };

    fetchActiveUsers();
  }, [showToast]);

  // Calculate users without timesheet for selected month
  useEffect(() => {
    if (!showAllTimesheets && selectedMonth && activeUsers.length > 0) {
      // When there are no timesheets at all, all active users should be listed
      if (timesheets.length === 0) {
        setUsersWithoutTimesheet(activeUsers);
      } else {
        // Otherwise, filter out users who have submitted timesheets
        const usersWithTimesheet = new Set(timesheets.map(t => t.userEmail.toLowerCase()));
        const missingUsers = activeUsers.filter(
          user => !usersWithTimesheet.has(user.email.toLowerCase())
        );
        setUsersWithoutTimesheet(missingUsers);
      }
    } else {
      setUsersWithoutTimesheet([]);
    }
  }, [showAllTimesheets, selectedMonth, timesheets, activeUsers]);

  // Fetch reminder counts for the current month
  const fetchReminderCounts = useCallback(async () => {
    if (!selectedMonth || showAllTimesheets) return;

    try {
      const monthString = format(selectedMonth, 'yyyy-MM');
      const emailLogsRef = collection(db, 'email_logs');
      const q = query(
        emailLogsRef,
        where('type', '==', 'REMINDER'),
        where('month', '==', monthString)
      );

      const snapshot = await getDocs(q);
      const counts = {};

      snapshot.forEach(doc => {
        const data = doc.data();
        counts[data.userEmailId] = (counts[data.userEmailId] || 0) + 1;
      });

      setReminderCounts(counts);
    } catch (error) {
      console.error('Error fetching reminder counts:', error);
    }
  }, [selectedMonth, showAllTimesheets]);

  // Fetch reminder counts when month changes or after sending a reminder
  useEffect(() => {
    fetchReminderCounts();
  }, [fetchReminderCounts]);

  const handleSendReminder = async email => {
    try {
      const monthString = format(selectedMonth, 'yyyy-MM');
      const sendTimesheetReminder = httpsCallable(functions, 'sendTimesheetReminder');

      const timesheetUrl = `${window.location.origin}/timesheet/new?month=${monthString}&userEmail=${email}`;

      await sendTimesheetReminder({
        email,
        month: monthString,
        name: email.split('@')[0],
        timesheetUrl,
      });

      showToast(t('timesheet.messages.reminder_sent'), 'success');
      // Refresh reminder counts after sending
      fetchReminderCounts();
    } catch (error) {
      console.error('Error sending reminder:', error);
      showToast(t('timesheet.messages.reminder_error'), 'error');
    }
  };

  const handleCreateTimesheet = email => {
    const monthString = format(selectedMonth, 'yyyy-MM');
    navigate(`/timesheet/new?month=${monthString}&userEmail=${email}`);
  };

  return (
    <TimeSheetReviewView
      timesheets={timesheets}
      loading={loading}
      page={page}
      rowsPerPage={rowsPerPage}
      statusFilter={statusFilter}
      statusManagerFilter={statusManagerFilter}
      searchQuery={searchQuery}
      selectedMonth={selectedMonth}
      showAllTimesheets={showAllTimesheets}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onStatusFilterChange={handleStatusFilterChange}
      onStatusManagerFilterChange={handleStatusManagerFilterChange}
      onSearchQueryChange={handleSearchQueryChange}
      onMonthChange={handleMonthChange}
      onViewTimesheet={handleViewTimesheet}
      onShowAllTimesheetsChange={handleShowAllTimesheetsChange}
      hasMore={hasMore}
      isEmpty={!loading && timesheets.length === 0}
      usersWithoutTimesheet={usersWithoutTimesheet}
      onSendReminder={handleSendReminder}
      onCreateTimesheet={handleCreateTimesheet}
      reminderCounts={reminderCounts}
    />
  );
};

export default TimeSheetReview;
