/**
 * @component BomzaiLogo
 * @description SVG logo component for the Bomzai application.
 * Renders a stylized text logo with custom coloring.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

/**
 * @param {Object} props - Component props from MUI's SvgIcon
 * @returns {JSX.Element} SVG icon with styled text logo
 */
const BomzaiLogo = props => (
  <SvgIcon {...props} viewBox="0 0 120 40">
    <text
      x="0"
      y="30"
      style={{
        fill: '#fff',
        fontFamily: 'Arial',
        fontSize: '32px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
      }}
    >
      {/* Logo text with alternating colors */}
      <tspan style={{ fill: '#04D361' }}>b</tspan>
      <tspan>om</tspan>
      <tspan style={{ fill: '#04D361' }}>/</tspan>
      <tspan>ai</tspan>
    </text>
  </SvgIcon>
);

// PropTypes validation
BomzaiLogo.propTypes = {
  sx: PropTypes.object,
  onClick: PropTypes.func,
};

export default BomzaiLogo;
