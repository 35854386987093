import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatDateWithCapitalizedMonth } from '../utils/dateUtils';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: '5mm',
    left: '20mm',
    right: '20mm',
    borderTop: '1pt solid #ddd',
    paddingTop: '2mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 9,
  },
  leftText: {
    flex: 1,
    textAlign: 'left',
  },
  centerText: {
    flex: 1,
    textAlign: 'center',
  },
  rightText: {
    flex: 1,
    textAlign: 'right',
  },
});

const Footer = ({ projectName, projectCode, selectedDate, pageNumber }) => {
  const { i18n } = useTranslation();

  return (
    <View style={styles.footer} fixed>
      <Text style={styles.leftText}>
        {projectName} - {projectCode}
      </Text>
      <Text style={styles.centerText}>
        {formatDateWithCapitalizedMonth(selectedDate, 'MMMM yyyy', i18n.language)}
      </Text>
      <Text style={styles.rightText}>{pageNumber}</Text>
    </View>
  );
};

Footer.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectCode: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  pageNumber: PropTypes.string.isRequired,
};

export default Footer;
