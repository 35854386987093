/**
 * @component Layout
 * @description Main layout component that provides the application structure.
 * Combines sidebar navigation with main content area.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SidebarContainer from './SidebarContainer';

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children - Content to be rendered in main area
 */
const Layout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {/* Navigation sidebar */}
      <SidebarContainer />

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          minHeight: '100vh',
          backgroundColor: theme => theme.palette.background.default,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

// PropTypes validation
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
