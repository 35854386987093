import React from 'react';
import PropTypes from 'prop-types';
import { format, eachDayOfInterval } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { locales, formatDateWithCapitalizedMonth } from '../../utils/dateUtils';

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: 20,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: '12pt',
    border: '1pt solid #e0e0e0',
  },
  title: {
    fontSize: 18,
    color: '#1a237e',
    marginBottom: 12,
    borderBottom: '1pt solid #e0e0e0',
    paddingBottom: 8,
  },
  tablesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  tableContainer: {
    flex: 1,
  },
  table: {
    display: 'flex',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 4,
    backgroundColor: '#ffffff',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    borderBottomStyle: 'solid',
    minHeight: 28,
    alignItems: 'center',
  },
  headerRow: {
    backgroundColor: '#f5f5f5',
    minHeight: 32,
  },
  weekendRow: {
    backgroundColor: '#fafafa',
  },
  tableCell: {
    padding: '6pt',
  },
  dateCell: {
    flex: 7,
    borderRightWidth: 1,
    borderRightColor: '#e0e0e0',
    borderRightStyle: 'solid',
  },
  valueCell: {
    flex: 3,
    textAlign: 'center',
  },
  headerText: {
    fontSize: 10,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  dateText: {
    fontSize: 9,
    color: '#424242',
  },
  weekendText: {
    color: '#757575',
  },
  valueText: {
    fontSize: 9,
    color: '#424242',
  },
  totalContainer: {
    marginTop: 10,
    textAlign: 'right',
    paddingRight: 4,
  },
  totalText: {
    fontSize: 11,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  infoGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    marginTop: 10,
  },
  infoItem: {
    flex: 1,
    minWidth: '45%',
  },
  label: {
    fontSize: 9,
    color: '#666666',
    marginBottom: 2,
  },
  value: {
    fontSize: 11,
    color: '#1a237e',
  },
  largeValue: {
    fontSize: 14,
    color: '#1a237e',
    fontWeight: 'bold',
  },
});

const DayTable = ({ days, getDayValue, locale }) => {
  const { t } = useTranslation();

  return (
    <View style={[styles.table, styles.shadow]}>
      {/* Header */}
      <View style={[styles.tableRow, styles.headerRow]}>
        <View style={[styles.tableCell, styles.dateCell]}>
          <Text style={styles.headerText}>{t('timesheet.pdf.timesheet.date')}</Text>
        </View>
        <View style={[styles.tableCell, styles.valueCell]}>
          <Text style={styles.headerText}>{t('timesheet.pdf.timesheet.days')}</Text>
        </View>
      </View>

      {/* Days */}
      {days.map(day => {
        const value = getDayValue(day);
        const isWeekend = [0, 6].includes(day.getDay());

        return (
          <View key={format(day, 'dd')} style={[styles.tableRow, isWeekend && styles.weekendRow]}>
            <View style={[styles.tableCell, styles.dateCell]}>
              <Text style={[styles.dateText, isWeekend && styles.weekendText]}>
                {format(day, 'EEEE dd/MM/yyyy', { locale })}
              </Text>
            </View>
            <View style={[styles.tableCell, styles.valueCell]}>
              <Text style={[styles.valueText, isWeekend && styles.weekendText]}>
                {value.toFixed(1)}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const ConsultantTimesheet = ({ consultant, period, projectData }) => {
  const { t, i18n } = useTranslation();
  const locale = locales[i18n.language] || locales.en;

  const daysInMonth = eachDayOfInterval({ start: period.start, end: period.end });
  const midPoint = Math.ceil(daysInMonth.length / 2);
  const firstHalfDays = daysInMonth.slice(0, midPoint);
  const secondHalfDays = daysInMonth.slice(midPoint);

  const getDayValue = day => {
    if (!consultant.days) return 0;
    const dayStr = format(day, 'yyyy-MM-dd');
    const dayWork = consultant.days.find(d => {
      const dateStr = format(new Date(d.date), 'yyyy-MM-dd');
      return dateStr === dayStr;
    });
    return dayWork ? Number(dayWork.value) : 0;
  };

  return (
    <View style={styles.container}>
      <View style={[styles.header, styles.shadow]}>
        <Text style={styles.title}>{t('timesheet.pdf.timesheet.title')}</Text>
        <View style={styles.infoGrid}>
          <View style={styles.infoItem}>
            <Text style={styles.label}>{t('timesheet.pdf.timesheet.consultant')}</Text>
            <Text style={styles.value}>{consultant.userEmail}</Text>
          </View>
          <View style={styles.infoItem}>
            <Text style={styles.label}>{t('timesheet.pdf.timesheet.project')}</Text>
            <Text style={styles.value}>{projectData.projectName}</Text>
          </View>
          <View style={styles.infoItem}>
            <Text style={styles.label}>{t('timesheet.pdf.timesheet.period')}</Text>
            <Text style={styles.value}>
              {formatDateWithCapitalizedMonth(period.start, 'MMMM yyyy', i18n.language)}
            </Text>
          </View>
          <View style={styles.infoItem}>
            <Text style={styles.label}>{t('timesheet.pdf.timesheet.totalUO')}</Text>
            <Text style={styles.largeValue}>{consultant.totalDays.toFixed(1)}</Text>
          </View>
        </View>
      </View>

      <View style={styles.tablesContainer}>
        <View style={styles.tableContainer}>
          <DayTable days={firstHalfDays} getDayValue={getDayValue} locale={locale} />
        </View>
        <View style={styles.tableContainer}>
          <DayTable days={secondHalfDays} getDayValue={getDayValue} locale={locale} />
        </View>
      </View>

      <View style={styles.totalContainer}>
        <Text style={styles.totalText}>
          {t('timesheet.pdf.timesheet.total')} {consultant.totalDays.toFixed(1)}
        </Text>
      </View>
    </View>
  );
};

ConsultantTimesheet.propTypes = {
  consultant: PropTypes.shape({
    userEmail: PropTypes.string.isRequired,
    totalDays: PropTypes.number.isRequired,
    days: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
  }).isRequired,
  period: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};

DayTable.propTypes = {
  days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  getDayValue: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
};

export default ConsultantTimesheet;
