import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton, Typography, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { format, endOfWeek, eachDayOfInterval, startOfWeek, parseISO, isSameMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getDay } from 'date-fns/fp';

/**
 * @component TimeEntryRow
 * @description Renders a single row in the timesheet representing a mission's time entries.
 * Handles both daily and weekly time entry interactions.
 */

/**
 * @typedef {Object} TimeEntryRowProps
 * @property {Object} mission - Mission details including id, name and project
 * @property {Date[]} workingDays - Array of valid working days
 * @property {Object} timeEntries - Current time entries for all missions
 * @property {boolean} readOnly - Whether the row is editable
 * @property {Function} onTimeChange - Callback for time entry updates
 * @property {Function} onDelete - Callback to remove mission
 * @property {Array} timeOptions - Available time entry options (0, 0.5, 1)
 * @property {string} viewMode - Current view mode ('days' or 'weeks')
 * @property {boolean} includeWeekends - Whether weekends are included
 * @property {string[]} nonWorkingDays - Array of non-working day dates
 * @property {Date} selectedMonth - Currently selected month
 * @property {Date} monthClosure - Date when the month is closed
 * @property {boolean} isUserOnly - Whether the user is only allowed to view the timesheet
 */

const TimeEntryRow = ({
  mission,
  workingDays,
  timeEntries = {},
  readOnly = false,
  onTimeChange,
  onDelete,
  timeOptions = [],
  viewMode = 'days',
  includeWeekends = false,
  nonWorkingDays = [],
  selectedMonth,
  monthClosure,
  isUserOnly,
}) => {
  const { t } = useTranslation();

  // Check if month is closed for the current user
  const isMonthClosed = useMemo(() => {
    return monthClosure && new Date() > monthClosure && isUserOnly;
  }, [monthClosure, isUserOnly]);

  const isWeekend = useCallback(date => {
    const dayOfWeek = getDay(parseISO(date));
    return dayOfWeek === 0 || dayOfWeek === 6;
  }, []);

  const isNonWorkingDay = useCallback(date => nonWorkingDays?.includes(date), [nonWorkingDays]);

  /**
   * @function handleTimeClick
   * @description Handles click events on time entry cells.
   * For week view: updates all working days in the week
   * For day view: cycles through time values (0 -> 0.5 -> 1 -> 0)
   */
  const handleTimeClick = useCallback(
    (dateStr, currentValue) => {
      if (readOnly || isMonthClosed) return;

      if (viewMode === 'weeks') {
        const weekStart = startOfWeek(parseISO(dateStr), { weekStartsOn: 0 });
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });

        const workingDaysInWeek = eachDayOfInterval({ start: weekStart, end: weekEnd }).filter(
          day => {
            const dayStr = format(day, 'yyyy-MM-dd');
            const isWeekendDay = !includeWeekends && isWeekend(dayStr);
            const isOutsideMonth = !isSameMonth(day, selectedMonth);
            const isNonWorking = nonWorkingDays.includes(dayStr);
            return !isWeekendDay && !isOutsideMonth && !isNonWorking;
          }
        );

        const workingDaysCount = workingDaysInWeek.length;
        if (workingDaysCount === 0) return;

        // Calculate current total for the week
        const weekTotal = Number(currentValue);

        // Determine the next value per day based on current total
        let valuePerDay;
        if (weekTotal === 0) valuePerDay = 0.5;
        else if (Math.abs(weekTotal - workingDaysCount * 0.5) < 0.01) valuePerDay = 1;
        else valuePerDay = 0;

        // Apply the new value to all working days in the week
        workingDaysInWeek.forEach(day => {
          const dayStr = format(day, 'yyyy-MM-dd');
          onTimeChange(mission.id, dayStr, String(valuePerDay));
        });
      } else {
        // Day view logic remains the same
        const currentNum = Number(currentValue);
        let newValue;
        if (currentNum === 0) newValue = '0.5';
        else if (currentNum === 0.5) newValue = '1';
        else newValue = '0';

        onTimeChange(mission.id, dateStr, newValue);
      }
    },
    [
      mission.id,
      onTimeChange,
      viewMode,
      includeWeekends,
      nonWorkingDays,
      selectedMonth,
      isMonthClosed,
      isWeekend,
      readOnly,
    ]
  );

  /**
   * @function getWeekTotal
   * @description Calculates the total time value for a week.
   * Used in week view to display aggregated values for each week.
   */
  const getWeekTotal = useCallback(
    weekStart => {
      const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });
      let total = 0;

      const daysInWeek = eachDayOfInterval({ start: weekStart, end: weekEnd }).filter(day => {
        const isWeekendDay = !includeWeekends && isWeekend(day);
        const isOutsideMonth = !isSameMonth(day, selectedMonth);
        const dayStr = format(day, 'yyyy-MM-dd');
        const isNonWorking = nonWorkingDays.includes(dayStr);

        return !isWeekendDay && !isOutsideMonth && !isNonWorking;
      });

      daysInWeek.forEach(day => {
        const dateStr = format(day, 'yyyy-MM-dd');
        const value = timeEntries[mission.id]?.[dateStr];
        if (value) {
          total += Number(value);
        }
      });

      return String(total || '0');
    },
    [mission.id, timeEntries, includeWeekends, nonWorkingDays, selectedMonth, isWeekend]
  );

  /**
   * @function handleFillRow
   * @description Handles filling the entire row with time entries.
   * For week view: fills all working days in the week
   * For day view: fills all available days in the row
   */
  const handleFillRow = useCallback(() => {
    // Prevent changes if month is closed and user is not admin/manager
    if (isMonthClosed) {
      return;
    }

    // First, determine the next value to fill based on current values
    let nextValue = '0.5'; // Default next value

    if (viewMode === 'weeks') {
      // Check if all non-zero weeks have the same value
      const weekValues = workingDays
        .map(weekStart => {
          const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });
          const daysInWeek = eachDayOfInterval({ start: weekStart, end: weekEnd }).filter(day => {
            const isWeekendDay = !includeWeekends && isWeekend(day);
            const isOutsideMonth = !isSameMonth(day, selectedMonth);
            const dayStr = format(day, 'yyyy-MM-dd');
            const isNonWorking = nonWorkingDays.includes(dayStr);
            return !isWeekendDay && !isOutsideMonth && !isNonWorking;
          }).length;

          return {
            value: getWeekTotal(weekStart),
            daysInWeek,
          };
        })
        .filter(week => week.value !== '0' && week.daysInWeek > 0);

      // Determine the target value for each day based on current week values
      let targetDayValue = '0.5'; // Default to 0.5 per day
      if (weekValues.length > 0) {
        const currentValue = weekValues[0].value;
        const daysInWeek = weekValues[0].daysInWeek;
        const valuePerDay = Number(currentValue) / daysInWeek;

        if (valuePerDay === 0.5) targetDayValue = '1';
        else if (valuePerDay === 1) targetDayValue = '0';
        else targetDayValue = '0.5';
      }

      // Fill all weeks
      workingDays.forEach(weekStart => {
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });
        const daysInWeek = eachDayOfInterval({ start: weekStart, end: weekEnd }).filter(day => {
          const isWeekendDay = !includeWeekends && isWeekend(day);
          const isOutsideMonth = !isSameMonth(day, selectedMonth);
          const dayStr = format(day, 'yyyy-MM-dd');
          const isNonWorking = nonWorkingDays.includes(dayStr);
          return !isWeekendDay && !isOutsideMonth && !isNonWorking;
        });

        // Calculate the total value we want for this week
        const totalWeekValue = String(daysInWeek.length * Number(targetDayValue));

        // Only need to set one day per week, as handleTimeClick will handle the rest
        if (daysInWeek.length > 0) {
          const dateStr = format(daysInWeek[0], 'yyyy-MM-dd');
          handleTimeClick(dateStr, totalWeekValue);
        }
      });
    } else {
      // Day view code remains the same
      const dayValues = workingDays
        .map(day => {
          const dateStr = format(day, 'yyyy-MM-dd');
          const isNonWorking = isNonWorkingDay(day);
          return {
            value: timeEntries[mission.id]?.[dateStr] || '0',
            isNonWorking,
          };
        })
        .filter(entry => entry.value !== '0' && !entry.isNonWorking)
        .map(entry => entry.value);

      if (dayValues.length > 0) {
        const currentValue = dayValues[0];
        if (currentValue === '0.5') nextValue = '1';
        else if (currentValue === '1') nextValue = '0';
      }

      workingDays.forEach(day => {
        const dateStr = format(day, 'yyyy-MM-dd');
        const isNonWorking = isNonWorkingDay(day);

        if (!isNonWorking) {
          handleTimeClick(dateStr, nextValue);
        }
      });
    }
  }, [
    workingDays,
    viewMode,
    mission.id,
    timeEntries,
    handleTimeClick,
    getWeekTotal,
    isNonWorkingDay,
    includeWeekends,
    selectedMonth,
    nonWorkingDays,
    isMonthClosed,
    isWeekend,
  ]);

  // Add this utility function near the top of the component
  const getNextValue = useCallback(
    (currentValue, isWeekView = false, weekStartDate = null) => {
      if (isWeekView && weekStartDate) {
        const weekStart = startOfWeek(weekStartDate, { weekStartsOn: 0 });
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });

        // Calculate actual working days for this specific week
        const workingDaysInWeek = eachDayOfInterval({ start: weekStart, end: weekEnd }).filter(
          day => {
            const dayStr = format(day, 'yyyy-MM-dd');
            const isWeekendDay = !includeWeekends && isWeekend(dayStr);
            const isOutsideMonth = !isSameMonth(day, selectedMonth);
            const isNonWorking = nonWorkingDays.includes(dayStr);
            return !isWeekendDay && !isOutsideMonth && !isNonWorking;
          }
        ).length;

        const currentNum = Number(currentValue);

        // Calculate the possible values based on working days
        const halfDaysTotal = workingDaysInWeek * 0.5;
        const fullDaysTotal = workingDaysInWeek * 1.0;

        // Loop through values: 0 -> halfDaysTotal -> fullDaysTotal -> 0
        if (currentNum === 0) return String(halfDaysTotal);
        if (Math.abs(currentNum - halfDaysTotal) < 0.01) return String(fullDaysTotal);
        return '0';
      }

      // Day view logic remains unchanged
      const currentNum = Number(currentValue);
      if (currentNum === 0) return '0.5';
      if (currentNum === 0.5) return '1';
      return '0';
    },
    [includeWeekends, nonWorkingDays, selectedMonth, isWeekend]
  );

  // Add state for transitioning and hover control
  const [transitioningCell, setTransitioningCell] = useState(null);
  const [hoverDisabled, setHoverDisabled] = useState(false);

  // Update click handler with better transition sequence
  const handleCellClick = useCallback(
    (dateStr, value) => {
      if (!readOnly && !isMonthClosed) {
        setHoverDisabled(true);
        setTransitioningCell(dateStr);

        // Update value immediately but keep transition state
        handleTimeClick(dateStr, value);

        // After a delay, complete the transition
        setTimeout(() => {
          setTransitioningCell(null);

          // Re-enable hover after transition
          setTimeout(() => {
            setHoverDisabled(false);
          }, 200);
        }, 300);
      }
    },
    [readOnly, isMonthClosed, handleTimeClick]
  );

  return (
    <TableRow>
      <TableCell data-testid={`mission-cell-${mission.id}`}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {!readOnly && !isMonthClosed && (
            <IconButton
              size="small"
              onClick={() => onDelete(mission)}
              data-testid={`delete-mission-${mission.id}`}
              sx={{ color: 'text.secondary' }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}

          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.primary">
              {mission.name}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {mission.project.name}
            </Typography>
          </Box>

          {!readOnly && !isMonthClosed && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleFillRow}
              data-testid={`fill-row-${mission.id}`}
              startIcon={<PlayArrowIcon fontSize="small" />}
              sx={{
                fontSize: '0.75rem',
                py: 0.5,
                px: 1,
                minWidth: 'auto',
                borderColor: 'divider',
                color: 'text.secondary',
                '&:hover': {
                  bgcolor: 'action.hover',
                  borderColor: 'text.secondary',
                },
              }}
            >
              {t('timesheet.form.fill')}
            </Button>
          )}
        </Box>
      </TableCell>
      {viewMode === 'weeks'
        ? workingDays.map(day => {
            const dateStr = format(day, 'yyyy-MM-dd');
            const value = getWeekTotal(day);
            const isNonWorking = isNonWorkingDay(day);

            return (
              <TableCell
                key={dateStr}
                data-testid={`week-cell-${dateStr}-${mission.id}`}
                align="center"
                onClick={() => handleCellClick(dateStr, value)}
                sx={{
                  padding: '4px',
                  cursor: !readOnly && !isMonthClosed && !isNonWorking ? 'pointer' : 'default',
                  bgcolor:
                    value && value !== '0'
                      ? 'action.selected'
                      : isNonWorking
                        ? 'action.hover'
                        : 'inherit',
                  color: 'text.primary',
                  '&:hover': !readOnly &&
                    !isMonthClosed &&
                    !isNonWorking &&
                    !hoverDisabled && {
                      bgcolor: 'primary.light',
                    },
                  opacity: includeWeekends && isNonWorking ? 0.8 : 1,
                  transition: 'all 0.2s',
                  minHeight: '48px',
                  '&.transitioning': {
                    bgcolor: 'inherit',
                    '& .current-value': {
                      opacity: 1,
                      transform: 'scale(1)',
                      transition: 'all 0.3s',
                      color: 'text.primary',
                    },
                    '& .next-value': {
                      opacity: 0,
                    },
                  },
                }}
                className={transitioningCell === dateStr ? 'transitioning' : ''}
              >
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    className="current-value"
                    data-testid={`week-value-${dateStr}-${mission.id}`}
                    sx={{
                      fontWeight: value !== '0' ? 'medium' : 'normal',
                      fontSize: '0.875rem',
                      transition: 'all 0.2s',
                      position: 'relative',
                      '.MuiTableCell-root:hover &': !hoverDisabled && {
                        opacity: 0.75,
                        transform: 'translateY(-12px) scale(0.55)',
                      },
                    }}
                  >
                    {value !== '0' ? value : '-'}
                  </Typography>

                  {!readOnly && !isMonthClosed && !isNonWorking && (
                    <Typography
                      variant="body2"
                      className="next-value"
                      sx={{
                        position: 'absolute',
                        fontWeight: 'medium',
                        fontSize: '0.875rem',
                        opacity: 0,
                        transform: 'translateY(8px)',
                        transition: 'all 0.2s',
                        '.MuiTableCell-root:hover &': !hoverDisabled && {
                          opacity: 1,
                          transform: 'translateY(0)',
                        },
                      }}
                    >
                      {getNextValue(value, true, day)}
                    </Typography>
                  )}
                </Box>
              </TableCell>
            );
          })
        : workingDays.map(day => {
            const dateStr = format(day, 'yyyy-MM-dd');
            const value = timeEntries[mission.id]?.[dateStr] || '0';
            const isNonWorking = isNonWorkingDay(day);

            return (
              <TableCell
                key={dateStr}
                data-testid={`day-cell-${dateStr}-${mission.id}`}
                align="center"
                onClick={() => handleCellClick(dateStr, value)}
                sx={{
                  padding: '4px',
                  cursor: !readOnly && !isMonthClosed && !isNonWorking ? 'pointer' : 'default',
                  bgcolor:
                    value && value !== '0'
                      ? 'action.selected'
                      : isNonWorking
                        ? 'action.hover'
                        : 'inherit',
                  color: 'text.primary',
                  '&:hover': !readOnly &&
                    !isMonthClosed &&
                    !isNonWorking &&
                    !hoverDisabled && {
                      bgcolor: 'primary.light',
                    },
                  opacity: includeWeekends && isNonWorking ? 0.8 : 1,
                  transition: 'all 0.2s',
                  minHeight: '48px',
                  '&.transitioning': {
                    bgcolor: 'inherit',
                    '& .current-value': {
                      opacity: 1,
                      transform: 'scale(1)',
                      transition: 'all 0.3s',
                      color: 'text.primary',
                    },
                    '& .next-value': {
                      opacity: 0,
                    },
                  },
                }}
                className={transitioningCell === dateStr ? 'transitioning' : ''}
              >
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    className="current-value"
                    data-testid={`day-value-${dateStr}-${mission.id}`}
                    sx={{
                      fontWeight: value !== '0' ? 'medium' : 'normal',
                      fontSize: '0.875rem',
                      transition: 'all 0.2s',
                      position: 'relative',
                      '.MuiTableCell-root:hover &': !hoverDisabled && {
                        opacity: 0.75,
                        transform: 'translateY(-12px) scale(0.55)',
                      },
                    }}
                  >
                    {value !== '0' ? value : '-'}
                  </Typography>

                  {!readOnly && !isMonthClosed && !isNonWorking && (
                    <Typography
                      variant="body2"
                      className="next-value"
                      sx={{
                        position: 'absolute',
                        fontWeight: 'medium',
                        fontSize: '0.875rem',
                        opacity: 0,
                        transform: 'translateY(8px)',
                        transition: 'all 0.2s',
                        '.MuiTableCell-root:hover &': !hoverDisabled && {
                          opacity: 1,
                          transform: 'translateY(0)',
                        },
                      }}
                    >
                      {getNextValue(value)}
                    </Typography>
                  )}
                </Box>
              </TableCell>
            );
          })}
    </TableRow>
  );
};

TimeEntryRow.propTypes = {
  mission: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    project: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  timeEntries: PropTypes.object.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  workingDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  timeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  readOnly: PropTypes.bool,
  viewMode: PropTypes.oneOf(['days', 'weeks']),
  nonWorkingDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  includeWeekends: PropTypes.bool,
  selectedMonth: PropTypes.instanceOf(Date),
  monthClosure: PropTypes.instanceOf(Date),
  isUserOnly: PropTypes.bool,
};

export default TimeEntryRow;
