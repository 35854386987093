/**
 * @component CalendarManagement
 * @description Container component for managing non-working days and monthly closures.
 * Handles data fetching, state management, and Firebase interactions.
 */
import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, doc, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useToast } from '../../contexts/toast';
import { format, parseISO } from 'date-fns';
import CalendarManagementView from './CalendarManagementView';

const CalendarManagement = () => {
  // State management for calendar settings
  const [activeTab, setActiveTab] = useState('nonWorkingDays');
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [nonWorkingDays, setNonWorkingDays] = useState([]);
  const [monthlyClosures, setMonthlyClosures] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [calendarId, setCalendarId] = useState(null);

  const { showToast } = useToast();

  /**
   * Fetches calendar settings for selected year from Firestore
   * Includes non-working days and monthly closures
   */
  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        setLoading(true);
        setError(null);

        const calendarsRef = collection(db, 'calendar_settings');
        const year = format(selectedYear, 'yyyy');
        const yearQuery = query(calendarsRef, where('year', '==', year));
        const yearDocs = await getDocs(yearQuery);

        if (!yearDocs || yearDocs.empty) {
          setCalendarId(null);
          setNonWorkingDays([]);
          setMonthlyClosures({});
          return;
        }

        const calendarDoc = yearDocs.docs[0];
        const data = calendarDoc.data();

        if (!data) {
          throw new Error('Invalid calendar data structure');
        }

        setCalendarId(calendarDoc.id);
        setNonWorkingDays(Array.isArray(data.nonWorkingDays) ? data.nonWorkingDays : []);
        setMonthlyClosures(data.monthlyClosures || {});
      } catch (err) {
        setError(err.message || 'Failed to load calendar data');
        showToast('Error loading calendar data', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchCalendarData();
  }, [selectedYear, showToast]);

  /**
   * Event handlers for calendar management
   */
  const handleTabChange = newValue => {
    setActiveTab(newValue);
  };

  const handleYearChange = newValue => {
    setSelectedYear(newValue);
  };

  /**
   * Manages non-working days list
   * @param {Date} date - Date to add as non-working
   */
  const handleAddNonWorkingDay = (date, error) => {
    if (error) {
      showToast(error, 'error');
      return;
    }

    if (!date) {
      return;
    }

    const formattedDate = format(parseISO(date), 'yyyy-MM-dd');

    if (!nonWorkingDays.includes(formattedDate)) {
      const updatedDays = [...nonWorkingDays, formattedDate].sort();
      setNonWorkingDays(updatedDays);
    }
  };

  /**
   * Removes date from non-working days list
   * @param {string} date - Date to remove in YYYY-MM-DD format
   */
  const handleDeleteNonWorkingDay = date => {
    setNonWorkingDays(nonWorkingDays.filter(d => d !== date));
  };

  /**
   * Updates monthly closure dates
   * @param {number} index - Month index
   * @param {Object} newClosures - Updated closure dates
   */
  const handleUpdateMonthlyClosures = (index, newClosures) => {
    setMonthlyClosures(newClosures);
  };

  /**
   * Persists calendar settings to Firestore
   * Handles both creation and updates
   * @async
   */
  const handleSave = async () => {
    setIsSaving(true);
    try {
      const year = format(selectedYear, 'yyyy');

      // Convert monthlyClosures object to array format
      const formattedClosures = Object.entries(monthlyClosures)
        .filter(([_, date]) => date) // Remove null/undefined dates
        .reduce((acc, [month, date], index) => {
          acc[index] = date;
          return acc;
        }, {});

      const data = {
        year,
        nonWorkingDays,
        monthlyClosures: formattedClosures,
        updatedAt: new Date(),
      };

      if (calendarId) {
        await updateDoc(doc(db, 'calendar_settings', calendarId), data);
      } else {
        const docRef = await addDoc(collection(db, 'calendar_settings'), {
          ...data,
          createdAt: new Date(),
        });
        setCalendarId(docRef.id);
      }

      showToast('Calendar settings saved successfully', 'success');
    } catch (err) {
      setError('Failed to save calendar settings');
      showToast('Error saving calendar settings', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <CalendarManagementView
      activeTab={activeTab}
      selectedYear={selectedYear}
      nonWorkingDays={nonWorkingDays}
      monthlyClosures={monthlyClosures}
      loading={loading}
      error={error}
      isSaving={isSaving}
      onTabChange={handleTabChange}
      onYearChange={handleYearChange}
      onSave={handleSave}
      onAddNonWorkingDay={handleAddNonWorkingDay}
      onDeleteNonWorkingDay={handleDeleteNonWorkingDay}
      onUpdateMonthlyClosures={handleUpdateMonthlyClosures}
    />
  );
};

export default CalendarManagement;
