import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatDateWithCapitalizedMonth } from '../utils/dateUtils';

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: 60,
    marginTop: 40,
    textAlign: 'center',
  },
  title: {
    fontSize: 28,
    marginBottom: 30,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#1a237e', // Dark blue color
  },
  projectName: {
    fontSize: 24,
    marginBottom: 15,
    color: '#424242',
  },
  date: {
    fontSize: 20,
    color: '#616161',
  },
  section: {
    marginBottom: 30,
    padding: 20,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    border: '1pt solid #e0e0e0',
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 15,
    paddingBottom: 8,
    borderBottom: '1pt solid #e0e0e0',
    color: '#1a237e', // Dark blue color
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
  },
  gridItem: {
    flex: 1,
    minWidth: '45%',
  },
  label: {
    fontSize: 10,
    marginBottom: 4,
    color: '#757575',
  },
  value: {
    fontSize: 12,
    color: '#212121',
  },
  largeValue: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1a237e', // Dark blue color
  },
  periodValue: {
    fontSize: 12,
    color: '#424242',
  },
});

const InfoItem = ({ label, value, large = false }) => (
  <View style={styles.gridItem}>
    <Text style={styles.label}>{label}</Text>
    <Text style={large ? styles.largeValue : styles.value}>{value}</Text>
  </View>
);

const CoverPage = ({ projectData, period }) => {
  const { t, i18n } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>{t('timesheet.pdf.title')}</Text>
        <Text style={styles.projectName}>{projectData.projectName}</Text>
        <Text style={styles.date}>
          {formatDateWithCapitalizedMonth(period.start, 'MMMM yyyy', i18n.language)}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('timesheet.pdf.projectInfo.title')}</Text>
        <View style={styles.grid}>
          <InfoItem
            label={t('timesheet.pdf.projectInfo.supplierName')}
            value={projectData.projectName}
          />
          <InfoItem
            label={t('timesheet.pdf.projectInfo.projectCode')}
            value={projectData.projectCode || '-'}
          />
          <InfoItem
            label={t('timesheet.pdf.projectInfo.contractRef')}
            value={projectData.contractRef || '-'}
          />
          <InfoItem
            label={t('timesheet.pdf.projectInfo.orderNumber')}
            value={projectData.orderNumber || '-'}
          />
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('timesheet.pdf.summary.title')}</Text>
        <View style={styles.grid}>
          <InfoItem
            label={t('timesheet.pdf.summary.totalUO')}
            value={projectData.timesheets.reduce((sum, t) => sum + t.totalDays, 0).toFixed(1)}
            large
          />
          <InfoItem
            label={t('timesheet.pdf.summary.emissionDate')}
            value={format(new Date(), 'dd/MM/yyyy')}
          />
          <InfoItem
            label={t('timesheet.pdf.summary.consultantCount')}
            value={projectData.timesheets.length}
          />
          <InfoItem
            label={t('timesheet.pdf.summary.period')}
            value={t('timesheet.pdf.summary.periodFormat', {
              start: format(period.start, 'dd/MM/yy'),
              end: format(period.end, 'dd/MM/yy'),
            })}
          />
        </View>
      </View>
    </View>
  );
};

CoverPage.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
    contractRef: PropTypes.string,
    orderNumber: PropTypes.string,
    timesheets: PropTypes.arrayOf(
      PropTypes.shape({
        totalDays: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  period: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};

InfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  large: PropTypes.bool,
};

export default CoverPage;
