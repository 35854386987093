import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MonthNavigator from '../common/MonthNavigator';
import { useTimesheets } from '../../hooks/useTimesheets';
import { parseISO, format } from 'date-fns';
import {
  Button,
  Card,
  Container,
  Grid,
  Typography,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { pdf } from '@react-pdf/renderer';
import TimeSheetPDFTemplate from './pdf/TimeSheetPDFTemplate';

const TimeSheetPrint = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize selectedDate from URL or current date
  const initializeDate = () => {
    const monthParam = searchParams.get('month');
    if (monthParam) {
      try {
        // Parse YYYY-MM format to Date
        return parseISO(`${monthParam}-01`);
      } catch (error) {
        console.error('Invalid month parameter:', error);
        return new Date();
      }
    }
    return new Date();
  };

  const [selectedDate, setSelectedDate] = useState(initializeDate);
  const { timesheets, loading } = useTimesheets(selectedDate);
  const [projectTimesheets, setProjectTimesheets] = useState({});
  const [expandedProjects, setExpandedProjects] = useState({});
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);

  // Update URL when month changes
  const handleMonthChange = newDate => {
    const monthString = format(newDate, 'yyyy-MM');
    setSearchParams({ month: monthString });
    setSelectedDate(newDate);
  };

  // Toggle expansion state for a project
  const handleExpandClick = projectId => {
    setExpandedProjects(prev => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  useEffect(() => {
    if (timesheets && Array.isArray(timesheets)) {
      const processedData = timesheets.reduce((acc, timesheet) => {
        // Process each project in the timesheet
        (timesheet.projects || []).forEach(project => {
          if (!project?.id) return;

          // Initialize project if not exists
          if (!acc[project.id]) {
            acc[project.id] = {
              projectName: project.name,
              projectCode: project.code,
              timesheets: [],
              totalDays: 0,
            };
          }

          // Get all entries for this project's missions
          const projectTimeEntries = {};
          let projectTotalDays = 0;

          // For each mission in the project
          project.missions.forEach(missionId => {
            // Get the timesheet entries for this mission
            const missionEntries = timesheet.timeEntries[missionId] || {};

            // Add each day's value to the project entries
            Object.entries(missionEntries).forEach(([date, value]) => {
              // If date already exists, add the values
              if (projectTimeEntries[date]) {
                projectTimeEntries[date] = Number(projectTimeEntries[date]) + Number(value);
              } else {
                projectTimeEntries[date] = Number(value);
              }
              projectTotalDays += Number(value);
            });
          });

          // Add timesheet data to project
          acc[project.id].timesheets.push({
            id: timesheet.id,
            userEmail: timesheet.userEmail,
            status: timesheet.status,
            totalDays: projectTotalDays,
            days: Object.entries(projectTimeEntries).map(([date, value]) => ({
              date,
              value,
            })),
          });

          // Update project total
          acc[project.id].totalDays += projectTotalDays;
        });

        return acc;
      }, {});

      console.log('\nFinal processed data:', processedData);
      setProjectTimesheets(processedData);
    }
  }, [timesheets]);

  const handlePrintPDF = async projectId => {
    setIsPdfGenerating(true);
    try {
      console.log('Generating PDF for project:', projectId);
      const PDFDocument = (
        <TimeSheetPDFTemplate
          projectData={projectTimesheets[projectId]}
          selectedDate={selectedDate}
        />
      );

      const blob = await pdf(PDFDocument).toBlob();
      console.log('PDF blob generated:', blob.size, 'bytes');

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = t('timesheet.pdf.filename', {
        month: format(selectedDate, 'yyyy-MM'),
        projectName: projectTimesheets[projectId].projectName,
      });
      console.log('Starting download with filename:', link.download);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        URL.revokeObjectURL(url);
        console.log('URL revoked after download');
      }, 1000);
    } catch (error) {
      console.error('Error generating PDF:', error, error.stack);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const handleViewTimesheet = timesheetId => {
    navigate(`/timesheet/edit/${timesheetId}`);
  };

  const handlePreviewClick = async projectId => {
    try {
      const blob = await pdf(
        <TimeSheetPDFTemplate
          projectData={projectTimesheets[projectId]}
          selectedDate={selectedDate}
        />
      ).toBlob();

      // Create URL and open in new tab
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');

      // Clean up the URL after a delay
      setTimeout(() => URL.revokeObjectURL(url), 1000);
    } catch (error) {
      console.error('Error generating PDF preview:', error);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Typography>{t('common.loading')}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        {t('timesheet.print.title')}
      </Typography>

      <MonthNavigator selectedDate={selectedDate} onChange={handleMonthChange} />

      {Object.entries(projectTimesheets).map(([projectId, projectData]) => {
        if (!projectData.timesheets || projectData.timesheets.length === 0) return null;

        return (
          <Card key={projectId} sx={{ mt: 2, p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={5}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => handleExpandClick(projectId)}
                    aria-expanded={expandedProjects[projectId]}
                    aria-label="show more"
                    sx={{ mr: 1 }}
                  >
                    {expandedProjects[projectId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  <Box>
                    <Typography variant="h6">{projectData.projectName}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t('timesheet.print.timesheetCount', {
                        count: projectData.timesheets.length,
                      })}
                    </Typography>
                    <Typography variant="body2">
                      {t('timesheet.print.totalDays', {
                        days: projectData.totalDays,
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={7} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  startIcon={<PrintIcon />}
                  onClick={() => handlePrintPDF(projectId)}
                  sx={{ mr: 2 }}
                  disabled={isPdfGenerating}
                >
                  {isPdfGenerating ? t('common.loading') : t('timesheet.print.printPDF')}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<VisibilityIcon />}
                  onClick={() => handlePreviewClick(projectId)}
                >
                  {t('timesheet.print.preview')}
                </Button>
              </Grid>
            </Grid>

            <Collapse in={expandedProjects[projectId]} timeout="auto" unmountOnExit>
              <List sx={{ mt: 2 }}>
                {projectData.timesheets.map((timesheet, index) => (
                  <React.Fragment key={timesheet.id}>
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="view"
                          onClick={() => handleViewTimesheet(timesheet.id)}
                          sx={{ mr: 1 }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={timesheet.userEmail}
                        secondary={
                          <>
                            <Typography component="span" variant="body2">
                              {t('timesheet.form.status.' + timesheet.status.toLowerCase())} -
                            </Typography>
                            <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                              {t('timesheet.print.totalDays', { days: timesheet.totalDays })}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    {index < projectData.timesheets.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </Card>
        );
      })}
    </Container>
  );
};

export default TimeSheetPrint;
