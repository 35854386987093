/**
 * @component LoginContainer
 * @description Container component that manages login functionality.
 * Handles authentication state, form submission, and navigation after login.
 */
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import LoginForm from './LoginForm';
import { db, initializeFirebaseAuth } from '../../firebase/config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../contexts/toast';

const LoginContainer = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const { showToast } = useToast();

  useEffect(() => {
    const checkAuth = async () => {
      if (accounts.length > 0) {
        try {
          // Initialize Firebase auth with MSAL account
          await initializeFirebaseAuth(accounts[0]);

          // Generate userId from email
          const userId = accounts[0].username.toLowerCase().replace('@', '_at_');
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);

          if (!userDoc.exists()) {
            await setDoc(userDocRef, {
              email: accounts[0].username,
              name: accounts[0].name,
              createdAt: new Date(),
              lastLoginAt: new Date(),
            });
          } else {
            await setDoc(userDocRef, { lastLoginAt: new Date() }, { merge: true });
          }
        } catch (error) {
          console.error('Error checking auth:', error);
          showToast('Error checking authentication', 'error');
        }
      }
    };

    checkAuth();
  }, [accounts, showToast]);

  const handleLogin = async () => {
    try {
      const loginResponse = await instance
        .loginPopup({
          ...loginRequest,
          prompt: 'select_account',
          redirectUri: window.location.origin,
        })
        .catch(async () => {
          return instance.loginRedirect(loginRequest);
        });

      if (loginResponse) {
        showToast('Login successful', 'success');
        navigate('/', { replace: true });
      }
    } catch (e) {
      showToast('Login failed', 'error');
    }
  };

  return (
    <>
      <LoginForm onLogin={handleLogin} />
    </>
  );
};

export default LoginContainer;
