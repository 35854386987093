import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';

const ToastView = ({ open = false, message = '', severity = 'success', onClose = () => {} }) => (
  <Snackbar
    open={open}
    autoHideDuration={4000}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{ mt: 8 }}
  >
    <Alert
      onClose={onClose}
      severity={severity}
      elevation={6}
      variant="filled"
      sx={{
        width: '100%',
        maxWidth: 400,
      }}
    >
      {message}
    </Alert>
  </Snackbar>
);

ToastView.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  onClose: PropTypes.func,
};

export default ToastView;
