/**
 * @component DashboardView
 * @description Presentation component for the dashboard.
 * Displays user and project analytics in various views.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { getISOWeeksInYear, format } from 'date-fns';
import StatCard from '../common/StatCard';
import {
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';

const DashboardView = ({
  loading,
  kpiData,
  lifetimeKpiData,
  viewMode,
  viewType,
  selectedDate,
  isLifetime,
  onViewModeChange,
  onViewTypeChange,
  onDateChange,
  onLifetimeToggle,
}) => {
  const { t } = useTranslation();

  // Add state for expanded rows
  const [expandedRows, setExpandedRows] = useState(new Set());

  // Add toggle handler
  const handleRowExpand = rowId => {
    setExpandedRows(prev => {
      const next = new Set(prev);
      if (next.has(rowId)) {
        next.delete(rowId);
      } else {
        next.add(rowId);
      }
      return next;
    });
  };

  const formatUserName = email => {
    if (!email) return '';

    // Get the part before @
    const [name] = email.split('@');

    // Split by dot or underscore and capitalize each part
    const parts = name
      .split(/[._]/)
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());

    // Join with space
    return parts.join(' ');
  };

  // Add the missing functions
  const handleDateChange = direction => {
    if (isLifetime) return;

    let newDate = new Date(selectedDate);

    switch (direction) {
      case 'prev':
        if (viewMode === 'weekly') {
          newDate.setMonth(newDate.getMonth() - 1);
        } else if (viewMode === 'monthly') {
          newDate.setMonth(newDate.getMonth() - 1);
        } else {
          newDate.setFullYear(newDate.getFullYear() - 1);
        }
        break;
      case 'next':
        if (viewMode === 'weekly') {
          newDate.setMonth(newDate.getMonth() + 1);
        } else if (viewMode === 'monthly') {
          newDate.setMonth(newDate.getMonth() + 1);
        } else {
          newDate.setFullYear(newDate.getFullYear() + 1);
        }
        break;
      case 'prev-year':
        newDate.setFullYear(newDate.getFullYear() - 1);
        break;
      case 'next-year':
        newDate.setFullYear(newDate.getFullYear() + 1);
        break;
      default:
        break;
    }

    onDateChange(newDate, direction);
  };

  const getDisplayDate = () => {
    if (isLifetime) {
      return t('dashboard.lifetime');
    }

    if (viewMode === 'weekly') {
      return format(selectedDate, 'MMMM yyyy');
    }

    return format(selectedDate, 'MMMM yyyy');
  };

  if (!kpiData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <Typography>{t('dashboard.no_data')}</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  const getDataForView = () => {
    if (!kpiData) return null;

    if (viewType === 'users') {
      return kpiData.users;
    } else {
      // For projects view, use the projects data directly
      return kpiData.projects || {};
    }
  };

  const renderKPICards = () => {
    const data = isLifetime ? lifetimeKpiData : kpiData;

    if (!data) {
      return null;
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StatCard
            icon={<PeopleIcon />}
            title={t('dashboard.stats.total_users')}
            value={data.totalUsers || 0}
            subtitle={t('dashboard.stats.active_this_month')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            icon={<AssignmentIcon />}
            title={t('dashboard.stats.total_timesheets')}
            value={data.totalTimesheets || 0}
            subtitle={t('dashboard.stats.submitted_this_month')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            icon={<TrendingUpIcon />}
            title={t('dashboard.stats.completion_rate')}
            value={`${data.completionRate || 0}%`}
            subtitle={t('dashboard.stats.average_completion')}
          />
        </Grid>
      </Grid>
    );
  };

  const renderDetailTable = () => {
    const data = getDataForView();
    if (!data) return null;

    // Helper function to get the correct value based on view mode
    const getMissionValue = mission => {
      if (!mission || !mission.monthlyData) {
        return 0;
      }

      if (viewMode === 'monthly') {
        const monthKey = format(selectedDate, 'yyyy-MM');
        return mission.monthlyData[monthKey] || 0;
      } else if (viewMode === 'yearly') {
        const yearKey = format(selectedDate, 'yyyy');
        return mission.yearlyData?.[yearKey] || 0;
      }
      return 0;
    };

    return (
      <TableContainer component={Paper}>
        <Table data-testid="dashboard-detail-table">
          <TableHead>
            <TableRow>
              <TableCell data-testid="table-header-name">
                {viewType === 'users' ? t('dashboard.table.user') : t('dashboard.table.project')}
              </TableCell>
              <TableCell align="right" data-testid="table-header-days">
                {t('dashboard.table.total_days')}
              </TableCell>
              <TableCell align="right" data-testid="table-header-count">
                {viewType === 'users'
                  ? t('dashboard.table.projects')
                  : t('dashboard.table.missions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([id, item]) => (
              <React.Fragment key={id}>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton size="small" onClick={() => handleRowExpand(id)} sx={{ mr: 1 }}>
                        {expandedRows.has(id) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      </IconButton>
                      <Box data-testid={`users-cell-${id}`}>
                        {viewType === 'users' ? formatUserName(id) : item.name}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">{getMissionValue(item).toFixed(1)}</TableCell>
                  <TableCell align="right">
                    {viewType === 'users'
                      ? item.missions.length
                      : Object.keys(item.missions).length}
                  </TableCell>
                </TableRow>
                {viewType === 'users' && expandedRows.has(id) && (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ py: 0 }}>
                      <Box sx={{ pl: 4 }}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('dashboard.table.mission')}</TableCell>
                              <TableCell>{t('dashboard.table.project')}</TableCell>
                              <TableCell align="right">{t('dashboard.table.total_days')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(item.missionDetails).map(([missionId, mission]) => (
                              <TableRow key={missionId}>
                                <TableCell data-testid={`mission-name-${missionId}`}>
                                  {mission.name}
                                </TableCell>
                                <TableCell>{mission.projectName}</TableCell>
                                <TableCell align="right" data-testid={`mission-time-${missionId}`}>
                                  {getMissionValue(mission).toFixed(1)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTimelineData = () => {
    const data = getDataForView();
    if (!data) return null;

    const timeData = Object.entries(data).map(([id, item]) => ({
      id,
      name: viewType === 'users' ? id : item.name,
      data: viewMode === 'weekly' ? item.weeklyData : item.monthlyData,
    }));

    return (
      <Box sx={{ mt: 3 }}>
        {timeData.map(({ id, name, data }) => (
          <Accordion key={id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                {viewType === 'users' ? (
                  <>
                    <PersonIcon sx={{ mr: 1 }} />
                    {formatUserName(name)}
                  </>
                ) : (
                  <>
                    <BusinessIcon sx={{ mr: 1 }} />
                    {name}
                  </>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.entries(data || {}).map(([period, value]) => (
                  <Chip
                    key={period}
                    label={`${period}: ${value.toFixed(1)}`}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  // Get all periods based on view mode
  const getAllPeriods = () => {
    if (isLifetime) {
      // Get all years from current year to 5 years back
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 6 }, (_, i) => (currentYear - i).toString()).reverse();
    }

    if (viewMode === 'monthly') {
      // Generate all months for the selected year
      const year = selectedDate.getFullYear();
      return Array.from({ length: 12 }, (_, i) => {
        const month = (i + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
      });
    }

    if (viewMode === 'weekly') {
      // Generate all weeks for the selected year
      const year = selectedDate.getFullYear();
      const lastWeek = getISOWeeksInYear(new Date(year, 0, 1));

      // Generate weeks for current year
      const currentYearWeeks = Array.from({ length: lastWeek }, (_, i) => {
        const weekNum = (i + 1).toString().padStart(2, '0');
        return `${year}-W${weekNum}`;
      });

      // Add Week 1 of next year if we have data for it
      const nextYear = year + 1;
      const nextYearWeek1 = `${nextYear}-W01`;

      return [...currentYearWeeks, nextYearWeek1];
    }

    return [];
  };

  // Helper to format period labels
  const formatPeriodLabel = period => {
    if (isLifetime) return period; // Year is already formatted

    if (viewMode === 'monthly') {
      // Convert YYYY-MM to month name
      const [year, month] = period.split('-');
      return format(new Date(parseInt(year), parseInt(month) - 1), 'MMM');
    }

    if (viewMode === 'weekly') {
      // Just return the week number without "Week" prefix
      const weekNum = period.split('-W')[1];
      return weekNum;
    }

    return period;
  };

  const getTimeData = item => {
    if (isLifetime) {
      return item.yearlyData;
    }
    return viewMode === 'weekly' ? item.weeklyData : item.monthlyData;
  };

  const renderMatrixTable = () => {
    const data = getDataForView();
    if (!data) return null;

    const periods = getAllPeriods();

    return (
      <Box sx={{ mt: 3, mb: 3 }}>
        <Paper>
          <Box sx={{ overflowX: 'auto' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {/* Name Column */}
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      backgroundColor: 'grey.100',
                    }}
                  >
                    {viewType === 'users'
                      ? t('dashboard.table.user')
                      : t('dashboard.table.project')}
                  </TableCell>

                  {/* Period Columns */}
                  {periods.map(period => (
                    <TableCell key={period} align="right">
                      {formatPeriodLabel(period)}
                    </TableCell>
                  ))}

                  {/* Total Column */}
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {t('dashboard.table.total')}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* Main Rows */}
                {Object.entries(data).map(([id, item]) => {
                  const isExpanded = expandedRows.has(id);
                  const timeData = getTimeData(item);

                  return (
                    <React.Fragment key={`fragment-${id}`}>
                      <TableRow hover>
                        <TableCell
                          onClick={() => handleRowExpand(id)}
                          sx={{
                            position: 'sticky',
                            left: 0,
                            cursor: 'pointer',
                            backgroundColor: 'background.paper',
                          }}
                          data-testid={`${viewType}-cell-${id}`}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {viewType === 'users' ? (
                              <PersonIcon fontSize="small" />
                            ) : (
                              <BusinessIcon fontSize="small" />
                            )}
                            <Typography>
                              {viewType === 'users' ? formatUserName(id) : item.name}
                            </Typography>
                            <ExpandMoreIcon
                              sx={{
                                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
                                transition: 'transform 0.2s',
                              }}
                            />
                          </Box>
                        </TableCell>

                        {/* Period Data */}
                        {periods.map(period => (
                          <TableCell key={period} align="right">
                            {timeData?.[period]?.toFixed(1) || '-'}
                          </TableCell>
                        ))}

                        {/* Row Total */}
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                          {(item.totalDays || 0).toFixed(1)}
                        </TableCell>
                      </TableRow>

                      {/* Mission Details Rows */}
                      {isExpanded &&
                        Object.entries(item.missionDetails).map(([missionId, mission]) => {
                          const missionTimeData = getTimeData(mission);
                          return (
                            <TableRow key={missionId} sx={{ backgroundColor: 'grey.50' }}>
                              <TableCell sx={{ pl: 6 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  {viewType === 'users' ? (
                                    <>
                                      <Typography variant="body2">
                                        {mission.name} ({mission.projectName})
                                      </Typography>
                                    </>
                                  ) : (
                                    <Typography variant="body2">{mission.name}</Typography>
                                  )}
                                </Box>
                              </TableCell>

                              {periods.map(period => (
                                <TableCell key={period} align="right">
                                  {missionTimeData?.[period]?.toFixed(1) || '-'}
                                </TableCell>
                              ))}

                              <TableCell align="right">
                                {(mission.totalDays || 0).toFixed(1)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                })}

                {/* Totals Row */}
                <TableRow sx={{ backgroundColor: 'grey.100' }}>
                  <TableCell sx={{ position: 'sticky', left: 0, fontWeight: 'bold' }}>
                    {t('dashboard.table.total')}
                  </TableCell>

                  {periods.map(period => {
                    const columnTotal = Object.values(data).reduce((sum, item) => {
                      const timeData = getTimeData(item);
                      return sum + (Number(timeData?.[period]) || 0);
                    }, 0);

                    return (
                      <TableCell key={period} align="right" sx={{ fontWeight: 'bold' }}>
                        {columnTotal.toFixed(1)}
                      </TableCell>
                    );
                  })}

                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {Object.values(data)
                      .reduce((sum, item) => sum + item.totalDays, 0)
                      .toFixed(1)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderDateNavigation = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {/* Left arrow */}
      <IconButton
        onClick={() => handleDateChange('prev')}
        disabled={isLifetime}
        aria-label="Previous period"
        data-testid="prev-period-button"
        size="small"
      >
        <ChevronLeftIcon />
      </IconButton>

      {/* Current period display */}
      <Typography variant="h6" sx={{ minWidth: '200px', textAlign: 'center' }}>
        {getDisplayDate()}
      </Typography>

      {/* Right arrow */}
      <IconButton
        onClick={() => handleDateChange('next')}
        disabled={isLifetime}
        aria-label="Next period"
        data-testid="next-period-button"
        size="small"
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *:not(:first-of-type)': {
            // Apply maxWidth to all children except the first one (controls)
            maxWidth: '1200px',
            width: '100%',
          },
        }}
      >
        {/* Controls */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            mb: 3,
            flexWrap: 'wrap',
            '& > *': { flexShrink: 0 },
            width: '100%',
            maxWidth: '1200px',
          }}
        >
          {/* View Mode Toggle with Lifetime */}
          <ToggleButtonGroup
            value={isLifetime ? 'yearly' : viewMode}
            exclusive
            onChange={(e, value) => {
              if (value === 'yearly') {
                onLifetimeToggle(true);
              } else if (value) {
                onLifetimeToggle(false);
                onViewModeChange(value);
              }
            }}
            size="small"
          >
            <ToggleButton
              value="weekly"
              sx={{
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                ml: 1,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                },
              }}
            >
              {t('dashboard.weekly')}
            </ToggleButton>

            <ToggleButton
              value="monthly"
              sx={{
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                ml: 1,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                },
              }}
            >
              {t('dashboard.monthly')}
            </ToggleButton>
            <ToggleButton
              value="yearly"
              sx={{
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                ml: 1,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                },
              }}
            >
              {t('dashboard.yearly')}
            </ToggleButton>
          </ToggleButtonGroup>

          {/* Date Navigation */}
          {renderDateNavigation()}
        </Box>

        {/* KPI Cards */}
        <Box sx={{ mb: 6 }}>{renderKPICards()}</Box>

        {/* View Type Toggle */}
        <Box sx={{ mb: 3 }}>
          <ToggleButtonGroup
            value={viewType}
            exclusive
            onChange={(e, value) => value && onViewTypeChange(value)}
            size="small"
          >
            <ToggleButton value="users">{t('dashboard.view_type.users')}</ToggleButton>
            <ToggleButton value="projects">{t('dashboard.view_type.projects')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Matrix Table */}
        {renderMatrixTable()}

        {/* Other components */}
        {viewType === 'users' && renderDetailTable()}
        {viewType === 'users' && renderTimelineData()}
      </Box>
    </Box>
  );
};

DashboardView.propTypes = {
  loading: PropTypes.bool.isRequired,
  kpiData: PropTypes.object,
  lifetimeKpiData: PropTypes.object,
  viewMode: PropTypes.oneOf(['monthly', 'weekly']).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  viewType: PropTypes.oneOf(['users', 'projects']).isRequired,
  isLifetime: PropTypes.bool.isRequired,
  onViewModeChange: PropTypes.func.isRequired,
  onViewTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onLifetimeToggle: PropTypes.func.isRequired,
};

export default DashboardView;
