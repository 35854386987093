/**
 * @component DashboardContainer
 * @description Container component for the dashboard page.
 * Manages data fetching and state for user and project analytics.
 */
import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useToast } from '../../contexts/toast';
import { useTranslation } from 'react-i18next';
import DashboardView from './DashboardView';
import { aggregateProjectData } from '../../utils/dataProcessing';

const DashboardContainer = () => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('monthly');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewType, setViewType] = useState('users');
  const [kpiData, setKpiData] = useState(null);
  const [lifetimeKpiData, setLifetimeKpiData] = useState(null);
  const [isLifetime, setIsLifetime] = useState(false);

  const processKPIData = (data, year) => {
    if (data.projects) {
      const aggregatedProjects = aggregateProjectData(data.projects, {
        includeYearlyData: true,
        year,
      });
      data.projects = aggregatedProjects;
    }
    return data;
  };

  const fetchKPIData = useCallback(async () => {
    try {
      setLoading(true);
      const year = selectedDate.getFullYear().toString();
      const kpiRef = doc(db, 'kpis', year);
      const lifetimeRef = doc(db, 'kpis_lifetime', 'global');

      const [kpiSnap, lifetimeKpiSnap] = await Promise.all([getDoc(kpiRef), getDoc(lifetimeRef)]);

      if (kpiSnap.exists()) {
        const data = kpiSnap.data();
        processKPIData(data, year);
        setKpiData(data);
      } else {
        setKpiData(null);
        showToast(t('dashboard.no_data_for_year', { year }), 'info');
      }

      if (lifetimeKpiSnap.exists()) {
        const lifetimeData = lifetimeKpiSnap.data();
        setLifetimeKpiData(lifetimeData);
      }
    } catch (error) {
      showToast(t('dashboard.error.loading'), 'error');
    } finally {
      setLoading(false);
    }
  }, [selectedDate, t, showToast]);

  useEffect(() => {
    fetchKPIData();
  }, [fetchKPIData]);

  return (
    <DashboardView
      loading={loading}
      kpiData={kpiData}
      lifetimeKpiData={lifetimeKpiData}
      viewMode={viewMode}
      viewType={viewType}
      selectedDate={selectedDate}
      isLifetime={isLifetime}
      onViewModeChange={setViewMode}
      onViewTypeChange={setViewType}
      onDateChange={setSelectedDate}
      onLifetimeToggle={setIsLifetime}
    />
  );
};

export default DashboardContainer;
