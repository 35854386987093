/**
 * @component ProtectedRoute
 * @description Route wrapper component that handles authentication and role-based access control.
 * Redirects unauthorized users to login or home page based on their permissions.
 */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import PropTypes from 'prop-types';

const roleHierarchy = {
  user: 0,
  manager: 1,
  rh: 2,
  superadmin: 3,
};

/**
 * @typedef {Object} ProtectedRouteProps
 * @property {React.ReactNode} children - Child components to render if authorized
 * @property {string} [requiredRole] - Optional role requirement for access
 */
const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, loading } = useAuth();

  // Show nothing while checking authentication
  if (loading) return null;

  // Redirect to login if not authenticated
  if (!user) return <Navigate to="/login" />;

  // Check role-based access if required
  if (requiredRole) {
    const userRoleLevel = roleHierarchy[user.role] || 0;
    const requiredRoleLevel = roleHierarchy[requiredRole] || 0;

    // User has access if their role level is >= required level
    const hasRequiredRole = userRoleLevel >= requiredRoleLevel;

    return hasRequiredRole ? children : <Navigate to="/" />;
  }

  return children;
};

// PropTypes validation
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  requiredRole: PropTypes.oneOf(['user', 'manager', 'rh', 'superadmin']),
};

export default ProtectedRoute;
