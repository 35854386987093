/**
 * @fileoverview Theme color constants
 * @description Defines the application's color palette for both light and dark modes
 */
export const bomzaiColors = {
  purple: '#6B4EE6',
  green: '#2E7D32',
  white: '#FFFFFF',
  dark: {
    primary: '#8F7BEB',
    background: '#121212',
    paper: '#1E1E1E',
  },
};
