import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @component TransitionPage
 * @description A transition page that shows a loading animation and redirects to the specified URL
 * Usage: /transition?redirect=/desired/path
 */
const TransitionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirectUrl = params.get('redirect');

    if (!redirectUrl) {
      // If no redirect URL is provided, go to home
      navigate('/', { replace: true });
      return;
    }

    const timer = setTimeout(() => {
      navigate(redirectUrl, { replace: true });
    }, 300);

    return () => clearTimeout(timer);
  }, [navigate, location]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        gap: 2,
      }}
    >
      <CircularProgress size={60} />
      <Typography variant="h6" color="text.secondary">
        {t('common.loading')}
      </Typography>
    </Box>
  );
};

export default TransitionPage;
