import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: 30,
    marginTop: 10,
  },
  title: {
    fontSize: 20,
    color: '#1a237e',
    marginBottom: 20,
    textAlign: 'center',
  },
  table: {
    display: 'flex',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 4,
    backgroundColor: '#ffffff',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    borderBottomStyle: 'solid',
    minHeight: 35,
    alignItems: 'center',
  },
  headerRow: {
    backgroundColor: '#f5f5f5',
    minHeight: 40,
  },
  totalRow: {
    backgroundColor: '#f5f5f5',
    minHeight: 40,
  },
  tableCell: {
    padding: '8pt',
  },
  consultantCell: {
    flex: 3,
    borderRightWidth: 1,
    borderRightColor: '#e0e0e0',
    borderRightStyle: 'solid',
  },
  uoCell: {
    flex: 1,
    textAlign: 'right',
  },
  headerText: {
    fontSize: 11,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  cellText: {
    fontSize: 10,
    color: '#424242',
  },
  totalText: {
    fontSize: 11,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 12,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  // Add shadow effect
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
});

const ConsultantSummaryTable = ({ projectData }) => {
  const { t } = useTranslation();

  const consultantSummary = projectData.timesheets.map(timesheet => ({
    name: timesheet.userEmail,
    totalUO: timesheet.totalDays,
  }));

  const totalUO = consultantSummary.reduce((sum, consultant) => sum + consultant.totalUO, 0);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>{t('timesheet.pdf.summary.title')}</Text>
      </View>

      <View style={[styles.table, styles.shadow]}>
        {/* Header */}
        <View style={[styles.tableRow, styles.headerRow]}>
          <View style={[styles.tableCell, styles.consultantCell]}>
            <Text style={styles.headerText}>{t('timesheet.pdf.consultantDetails.consultant')}</Text>
          </View>
          <View style={[styles.tableCell, styles.uoCell]}>
            <Text style={styles.headerText}>{t('timesheet.pdf.consultantDetails.workUnits')}</Text>
          </View>
        </View>

        {/* Consultant Rows */}
        {consultantSummary.map((consultant, index) => (
          <View key={`${consultant.name}-${index}`} style={styles.tableRow}>
            <View style={[styles.tableCell, styles.consultantCell]}>
              <Text style={styles.cellText}>{consultant.name}</Text>
            </View>
            <View style={[styles.tableCell, styles.uoCell]}>
              <Text style={styles.cellText}>{consultant.totalUO.toFixed(1)}</Text>
            </View>
          </View>
        ))}

        {/* Total Row */}
        <View style={[styles.tableRow, styles.totalRow]}>
          <View style={[styles.tableCell, styles.consultantCell]}>
            <Text style={styles.totalText}>{t('timesheet.pdf.consultantDetails.total')}</Text>
          </View>
          <View style={[styles.tableCell, styles.uoCell]}>
            <Text style={styles.totalValue}>{totalUO.toFixed(1)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

ConsultantSummaryTable.propTypes = {
  projectData: PropTypes.shape({
    timesheets: PropTypes.arrayOf(
      PropTypes.shape({
        userEmail: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ConsultantSummaryTable;
