import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

// Initialize MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

// Initialize MSAL immediately
msalInstance.initialize().catch(error => {
  console.error('MSAL initialization error:', error);
});

// Default to using the first account if available
export const getActiveAccount = () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) return null;
  return accounts[0];
};

// Initialize active account
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

export default msalInstance;
