/**
 * @component TimeSheetListView
 * @description Presentation component for timesheet list.
 * Displays a table of user's timesheets with actions for creating and editing.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { formatMonthYear } from '../../utils/dateFormatters';
import { formatDistanceToNow } from 'date-fns';

/**
 * @typedef {Object} TimeSheetListViewProps
 * @property {Array} timesheets - List of user's timesheets
 * @property {boolean} loading - Loading state indicator
 * @property {Function} onNewTimesheet - Handler for creating new timesheet
 * @property {Function} onEdit - Handler for editing existing timesheet
 */

// Define status constants
const TIMESHEET_STATUSES = {
  PENDING: 'PENDING',
  VALIDATED: 'VALIDATED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

// Add this helper function at the top
const formatTimestamp = timestamp => {
  if (!timestamp) return null;
  try {
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  } catch (error) {
    console.warn('Invalid timestamp:', timestamp);
    return 'common.never';
  }
};

const TimeSheetListView = ({ timesheets, loading = false, onNewTimesheet, onEdit }) => {
  const { t, i18n } = useTranslation();

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>{t('common.loading')}</Typography>
      </Box>
    );
  }

  return (
    <Container>
      {/* Header with title and add button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('timesheet.list.title')}
        </Typography>
        <Button variant="contained" startIcon={<AddIcon />} onClick={onNewTimesheet}>
          {t('timesheet.list.new_timesheet')}
        </Button>
      </Box>

      {/* Timesheets table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('timesheet.list.columns.month')}</TableCell>
              <TableCell>{t('timesheet.list.columns.status')}</TableCell>
              <TableCell>{t('timesheet.list.columns.total_days')}</TableCell>
              <TableCell>{t('timesheet.list.columns.last_updated')}</TableCell>
              <TableCell align="right">{t('common.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : timesheets.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t('timesheet.list.no_timesheets')}
                </TableCell>
              </TableRow>
            ) : (
              timesheets.map(timesheet => {
                return (
                  <TableRow key={timesheet.id}>
                    <TableCell>
                      {formatMonthYear(new Date(timesheet.month + '-01'), i18n.language)}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={t(`timesheet.status.${timesheet.status.toLowerCase()}`)}
                        color={timesheet.status === 'validated' ? 'success' : 'default'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {timesheet.totalDays ? timesheet.totalDays.toFixed(1) : '0'}{' '}
                      {t('common.days')}
                    </TableCell>
                    <TableCell>
                      {formatTimestamp(timesheet.updatedAt) || t('common.never')}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={
                          timesheet.status === 'validated' ? t('common.view') : t('common.edit')
                        }
                      >
                        <IconButton onClick={() => onEdit(timesheet)} color="primary" size="small">
                          {timesheet.status === 'validated' ? (
                            <VisibilityIcon data-testid="VisibilityIcon" />
                          ) : (
                            <EditIcon data-testid="EditIcon" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

// PropTypes validation
TimeSheetListView.propTypes = {
  timesheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      createdAt: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.shape({
          toDate: PropTypes.func.isRequired,
        }),
      ]),
      validatedAt: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.shape({
          toDate: PropTypes.func.isRequired,
        }),
      ]),
      totalDays: PropTypes.number,
      completionPercentage: PropTypes.number,
      status: PropTypes.oneOf(Object.values(TIMESHEET_STATUSES)).isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool,
  onNewTimesheet: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default TimeSheetListView;
