import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import AuditTrailViewer from '../../audit/AuditTrailViewer';

const AuditTrailTab = ({ timesheetId }) => {
  const location = useLocation();
  const { id } = useParams();
  const isNewTimesheet = location.pathname.includes('/timesheet/new');

  return (
    <Box sx={{ p: 2 }}>
      <AuditTrailViewer
        entityType="TIMESHEET"
        entityId={id || timesheetId}
        hideFilters={true}
        hideAffectedColumn={true}
        hideEntityColumn={true}
        isEmpty={isNewTimesheet}
      />
    </Box>
  );
};

AuditTrailTab.propTypes = {
  timesheetId: PropTypes.string,
};

export default AuditTrailTab;
