/**
 * @component CalendarManagementView
 * @description Presentation component for calendar settings interface.
 * Displays non-working days and monthly closures management UI.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Alert,
  CircularProgress,
  Button,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { DatePicker } from '@mui/x-date-pickers';
import NonWorkingDaysTab from './NonWorkingDaysTab';
import MonthlyClosuresTab from './MonthlyClosuresTab';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {Object} CalendarManagementViewProps
 * @property {string} activeTab - Currently selected tab index
 * @property {Date} selectedYear - Selected year for calendar settings
 * @property {string[]} nonWorkingDays - Array of non-working dates
 * @property {Object} monthlyClosures - Monthly closure dates
 * @property {boolean} loading - Loading state indicator
 * @property {string} error - Error message if any
 * @property {boolean} isSaving - Save operation state
 * @property {Function} onTabChange - Tab selection handler
 * @property {Function} onYearChange - Year selection handler
 * @property {Function} onSave - Save settings handler
 * @property {Function} onAddNonWorkingDay - Add non-working day handler
 * @property {Function} onDeleteNonWorkingDay - Remove non-working day handler
 * @property {Function} onUpdateMonthlyClosures - Update monthly closures handler
 */

// Component implementation with props destructuring
const CalendarManagementView = ({
  activeTab = 'nonWorkingDays',
  selectedYear,
  nonWorkingDays,
  monthlyClosures,
  loading,
  error,
  isSaving,
  onTabChange,
  onYearChange,
  onSave,
  onAddNonWorkingDay,
  onDeleteNonWorkingDay,
  onUpdateMonthlyClosures,
}) => {
  const { t } = useTranslation();

  // Add this handler to properly handle tab changes
  const handleTabChange = (event, newValue) => {
    onTabChange(newValue);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('admin.calendar.title')}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <DatePicker
          views={['year']}
          value={selectedYear}
          onChange={onYearChange}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
              inputProps: { 'data-testid': 'year-picker' },
            },
          }}
        />
        <Button
          variant="contained"
          onClick={onSave}
          disabled={isSaving}
          startIcon={<SaveIcon />}
          data-testid="save-button"
        >
          {isSaving ? t('common.loading') : t('common.save')}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange} // Use the new handler
            aria-label="calendar management tabs"
          >
            <Tab
              label={t('admin.calendar.tabs.non_working_days')}
              value="nonWorkingDays"
              id="calendar-tab-0"
              data-testid="non-working-days-tab"
            />
            <Tab
              label={t('admin.calendar.tabs.monthly_closures')}
              value="monthlyClosures"
              id="calendar-tab-1"
              data-testid="monthly-closures-tab"
            />
          </Tabs>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography sx={{ ml: 2 }}>{t('common.loading')}</Typography>
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            {activeTab === 'nonWorkingDays' && (
              <NonWorkingDaysTab
                days={nonWorkingDays}
                onAdd={onAddNonWorkingDay}
                onDelete={onDeleteNonWorkingDay}
                selectedYear={selectedYear}
              />
            )}
            {activeTab === 'monthlyClosures' && (
              <MonthlyClosuresTab closures={monthlyClosures} onUpdate={onUpdateMonthlyClosures} />
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

CalendarManagementView.propTypes = {
  activeTab: PropTypes.oneOf(['nonWorkingDays', 'monthlyClosures']),
  selectedYear: PropTypes.instanceOf(Date).isRequired,
  nonWorkingDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  monthlyClosures: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isSaving: PropTypes.bool.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onAddNonWorkingDay: PropTypes.func.isRequired,
  onDeleteNonWorkingDay: PropTypes.func.isRequired,
  onUpdateMonthlyClosures: PropTypes.func.isRequired,
};

CalendarManagementView.defaultProps = {
  activeTab: 'nonWorkingDays',
};

export default CalendarManagementView;
