/**
 * @component ThemeProvider
 * @description Theme context provider that manages application-wide theme settings.
 * Implements light/dark mode switching and custom color palette.
 */
import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { ThemeContext } from './themeContext';
import { bomzaiColors } from './themeConstants';

/**
 * Memoized theme configuration
 * Includes custom color palette for both light and dark modes
 */
const createBomzaiTheme = mode =>
  createTheme({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // Light mode palette
            primary: {
              main: bomzaiColors.purple,
              dark: '#6557A0',
              light: '#9E8FDB',
              contrastText: bomzaiColors.white,
            },
            secondary: {
              main: bomzaiColors.green,
              light: '#81C784',
              dark: '#388E3C',
              contrastText: bomzaiColors.white,
            },
            background: {
              default: '#F8F9FA',
              paper: bomzaiColors.white,
            },
            text: {
              primary: '#2C3E50',
              secondary: '#6B7280',
            },
            action: {
              hover: 'rgba(0, 0, 0, 0.03)',
              selected: 'rgba(0, 0, 0, 0.05)',
            },
          }
        : {
            // Dark mode palette
            primary: {
              main: bomzaiColors.dark.primary,
              dark: '#1D1B45',
              light: '#3D3B65',
              contrastText: bomzaiColors.white,
            },
            secondary: {
              main: bomzaiColors.green,
            },
            background: {
              default: bomzaiColors.dark.background,
              paper: bomzaiColors.dark.paper,
            },
          }),
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? bomzaiColors.white : bomzaiColors.dark.paper,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 8,
          },
        },
      },
    },
  });

export const ThemeProvider = ({ children }) => {
  // Theme mode state
  const [mode, setMode] = useState('light');

  /**
   * Toggles between light and dark theme modes
   */
  const toggleTheme = () => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(() => createBomzaiTheme(mode), [mode]);

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTheme = () => useContext(ThemeContext);
