/**
 * @component LoginForm
 * @description Presentation component for the login interface.
 * Includes email/password inputs, demo credentials, and password reset.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Paper, Button, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {Object} LoginFormProps
 * @property {Function} onLogin - Login handler
 */
const LoginForm = ({ onLogin }) => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 6,
            width: '100%',
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
            {t('auth.login')}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={onLogin}
            sx={{
              mt: 2,
              mb: 3,
              py: 1.5,
            }}
          >
            {t('auth.sign_in_microsoft')}
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginForm;
