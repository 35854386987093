/**
 * @component SidebarView
 * @description Presentation component for the sidebar navigation.
 * Renders navigation menu, user controls, and theme toggle.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  useTheme,
  Typography,
} from '@mui/material';
import {
  AccessTime as TimeSheetIcon,
  Assessment as ReviewIcon,
  Business as ManagementIcon,
  CalendarMonth as CalendarIcon,
  Group as UserManagementIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
  Work as WorkIcon,
  History as HistoryIcon,
  Storage as StorageIcon,
  Dashboard as DashboardIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import BomzaiLogo from './BomzaiLogo';
import ThemeSwitcher from '../common/ThemeSwitcher';
import { useTranslation } from 'react-i18next';
import LanguageDisplay from '../common/LanguageIcon';
import packageJson from '../../../package.json';

/**
 * Width of the expanded sidebar
 * @constant {number}
 */
const DRAWER_WIDTH = 240;

/**
 * Navigation menu configuration
 * Defines sections and items with role-based access
 * @constant {Object}
 */
const menuSections = {
  user: {
    title: 'navigation.sections.user',
    items: [
      {
        path: '/',
        icon: <TimeSheetIcon />,
        label: 'navigation.items.timesheet',
        roles: ['user', 'manager', 'rh', 'superadmin'],
      },
    ],
  },
  manager: {
    title: 'navigation.sections.manager',
    items: [
      {
        path: '/timesheet/review',
        icon: <ReviewIcon />,
        label: 'navigation.items.review_timesheets',
        roles: ['manager', 'rh', 'superadmin'],
      },
      {
        path: '/timesheet/print',
        icon: <PrintIcon />,
        label: 'navigation.items.print_timesheets',
        roles: ['manager', 'rh', 'superadmin'],
      },
    ],
  },
  rh: {
    title: 'navigation.sections.rh',
    items: [
      {
        path: '/management',
        icon: <ManagementIcon />,
        label: 'navigation.items.management',
        roles: ['rh', 'superadmin'],
      },
      {
        path: '/admin/calendar',
        icon: <CalendarIcon />,
        label: 'navigation.items.calendar',
        roles: ['rh', 'superadmin'],
      },
      {
        path: '/admin/users',
        icon: <UserManagementIcon />,
        label: 'navigation.items.user_management',
        roles: ['rh', 'superadmin'],
      },
      {
        path: '/dashboard',
        icon: <DashboardIcon />,
        label: 'navigation.items.dashboard',
        roles: ['rh', 'superadmin'],
      },
    ],
  },
  superadmin: {
    title: 'navigation.sections.superadmin',
    items: [
      {
        path: '/audit',
        icon: <HistoryIcon />,
        label: 'navigation.items.audit_trails',
        roles: ['superadmin'],
      },
      {
        path: '/backup',
        icon: <StorageIcon />,
        label: 'navigation.items.database_tools',
        roles: ['superadmin'],
      },
    ],
  },
};

/**
 * @typedef {Object} SidebarViewProps
 * @property {Object} user - Current user object
 * @property {string} mode - Current theme mode
 * @property {string} currentPath - Current route path
 * @property {Object} anchorEl - Menu anchor element
 * @property {boolean} isMenuOpen - User menu open state
 * @property {boolean} open - Sidebar expanded state
 * @property {Function} onToggle - Sidebar toggle handler
 * @property {Function} onMenuOpen - User menu open handler
 * @property {Function} onMenuClose - User menu close handler
 * @property {Function} onLogout - Logout handler
 * @property {Function} onNavigate - Navigation handler
 * @property {Function} onThemeToggle - Theme toggle handler
 * @property {Function} onLanguageClick - Language click handler
 * @property {Object} languageAnchorEl - Language menu anchor element
 * @property {Function} onLanguageClose - Language menu close handler
 * @property {Function} onLanguageSelect - Language menu select handler
 */
const SidebarView = ({
  user,
  mode,
  currentPath,
  anchorEl,
  isMenuOpen,
  open,
  onToggle,
  onMenuOpen,
  onMenuClose,
  onLogout,
  onNavigate,
  onThemeToggle,
  onLanguageClick,
  languageAnchorEl,
  onLanguageClose,
  onLanguageSelect,
}) => {
  const theme = useTheme();
  const userRole = user?.role || 'user';
  const { t, i18n } = useTranslation();

  const LANGUAGES = {
    fr: { name: 'Français', icon: '🇫🇷' },
    en: { name: 'English', icon: '🇬🇧' },
    pt: { name: 'Português', icon: '🇵🇹' },
    es: { name: 'Español', icon: '🇪🇸' },
    ko: { name: '한국어', icon: '🇰🇷' },
  };

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: open ? DRAWER_WIDTH : 65,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? DRAWER_WIDTH : 65,
            boxSizing: 'border-box',
            bgcolor: 'background.paper',
            borderRight: `1px solid ${theme.palette.divider}`,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
        data-testid="sidebar"
        className={open ? 'expanded' : 'collapsed'}
      >
        {/* Logo and toggle section */}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: open ? 'flex-start' : 'center',
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <IconButton
            onClick={onToggle}
            sx={{
              color: 'primary.contrastText',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
            data-testid="sidebar-toggle"
          >
            <MenuIcon />
          </IconButton>
          {open && (
            <BomzaiLogo
              data-testid="bomzai-logo"
              sx={{
                height: 45,
                cursor: 'pointer',
                color: 'primary.contrastText',
                flexGrow: 1,
                width: 'auto',
              }}
              onClick={() => onNavigate('/')}
            />
          )}
        </Box>

        <Divider />

        {/* Navigation menu sections */}
        {user && (
          <List sx={{ flexGrow: 1 }}>
            {Object.entries(menuSections).map(([sectionKey, section]) => {
              const sectionItems = section.items.filter(item => item.roles.includes(userRole));
              if (sectionItems.length === 0) return null;

              return (
                <React.Fragment key={sectionKey}>
                  {/* Only show section title when expanded */}
                  {open && (
                    <ListItem sx={{ pt: 1 }}>
                      <ListItemText
                        primary={t(section.title)}
                        sx={{
                          '& .MuiTypography-root': {
                            color: 'text.secondary',
                            fontSize: '0.75rem',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          },
                        }}
                      />
                    </ListItem>
                  )}
                  {/* Navigation items */}
                  {sectionItems.map(item => (
                    <ListItem key={item.path} disablePadding>
                      <ListItemButton
                        selected={currentPath === item.path}
                        onClick={() => onNavigate(item.path)}
                        data-testid={`nav-${item.path}`}
                        sx={{
                          minHeight: 40,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 40,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        {open && <ListItemText primary={t(item.label)} />}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </React.Fragment>
              );
            })}
          </List>
        )}

        <Divider />

        {/* Settings section at bottom */}
        <Box
          sx={{
            p: 1,
            borderTop: 1,
            borderColor: theme.palette.divider,
          }}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
            }}
          >
            {/* Theme toggle */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={onThemeToggle}
                sx={{
                  minHeight: 40,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  '&:hover': { bgcolor: 'action.hover' },
                }}
                data-testid="theme-toggle"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 40,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ThemeSwitcher />
                </ListItemIcon>
                {open && <ListItemText primary={t('common.settings.theme')} />}
              </ListItemButton>
            </ListItem>

            {/* Language selector */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={onLanguageClick}
                sx={{
                  minHeight: 40,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  '&:hover': { bgcolor: 'action.hover' },
                }}
                data-testid="language-button"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 40,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LanguageDisplay />
                </ListItemIcon>
                {open && <ListItemText primary={t('common.settings.language')} />}
              </ListItemButton>
            </ListItem>

            {/* Profile */}
            {user && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={onMenuOpen}
                  sx={{
                    minHeight: 40,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&:hover': { bgcolor: 'action.hover' },
                  }}
                  data-testid="profile-menu-button"
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 40,
                      mr: open ? 2 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <AccountCircleIcon />
                  </ListItemIcon>
                  {open && <ListItemText primary={t('common.settings.profile')} />}
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>

        {/* User menu */}
        {user && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onMenuClose}
            data-testid="profile-menu"
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                mt: 1.5,
                bgcolor: 'background.paper',
                color: 'text.primary',
                '& .MuiMenuItem-root': {
                  color: 'text.primary',
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem>
              <ListItemIcon>
                <PersonIcon fontSize="small" sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              {user?.email}
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <WorkIcon fontSize="small" sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              {user?.role}
            </MenuItem>
            <Divider />
            <MenuItem onClick={onLogout} data-testid="logout-button">
              <ListItemIcon>
                <LogoutIcon fontSize="small" sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              {t('auth.logout')}
            </MenuItem>
          </Menu>
        )}

        {/* Version display */}
        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 48,
            width: '100%',
          }}
          data-testid="version-container"
        >
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '0.7rem',
              transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
              transformOrigin: 'center',
            }}
          >
            {open ? `${t('common.version')} ${packageJson.version}` : packageJson.version}
          </Typography>
        </Box>
      </Drawer>

      {/* Language selection menu */}
      <Menu
        anchorEl={languageAnchorEl}
        open={Boolean(languageAnchorEl)}
        onClose={onLanguageClose}
        PaperProps={{
          elevation: 4,
          sx: {
            mt: 1.5,
            minWidth: 180,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1.5,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {Object.entries(LANGUAGES).map(([code, { name, icon }]) => (
          <MenuItem
            key={code}
            onClick={() => onLanguageSelect(code)}
            selected={i18n.language === code}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'action.selected',
              },
            }}
            data-testid={`language-${code}`}
          >
            <ListItemIcon sx={{ minWidth: 36, fontSize: '1.2rem' }}>{icon}</ListItemIcon>
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

// PropTypes validation
SidebarView.propTypes = {
  user: PropTypes.object,
  mode: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  isMenuOpen: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onThemeToggle: PropTypes.func.isRequired,
  onLanguageClick: PropTypes.func.isRequired,
  languageAnchorEl: PropTypes.object,
  onLanguageClose: PropTypes.func.isRequired,
  onLanguageSelect: PropTypes.func.isRequired,
};

export default SidebarView;
