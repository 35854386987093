/**
 * @component TimeSheetList
 * @description Container component for displaying user's timesheet history.
 * Manages timesheet data fetching and navigation to edit/create forms.
 */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/auth';
import TimeSheetListView from './TimeSheetListView';
import { format } from 'date-fns';
import { useToast } from '../../contexts/toast';

const TimeSheetList = () => {
  // State and hooks
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { showToast } = useToast();

  // Add checkExistingTimesheet function
  const checkExistingTimesheet = useCallback(
    async selectedDate => {
      if (!user?.email) return null;

      try {
        const userId = user.email.toLowerCase().replace('@', '_at_');
        const selectedMonthStr = format(selectedDate, 'yyyy-MM');

        const timesheetsRef = collection(db, 'timesheets');
        const q = query(
          timesheetsRef,
          where('userId', '==', userId),
          where('month', '==', selectedMonthStr)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const existingTimesheet = {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
          };

          return existingTimesheet;
        }
      } catch (error) {
        setError(error);
        showToast('Error checking existing timesheet', 'error');
        return null;
      }

      return null;
    },
    [user?.email, showToast]
  );

  useEffect(() => {
    if (!user?.email) {
      setLoading(false);
      return;
    }

    const userId = user.email.toLowerCase().replace('@', '_at_');
    const timesheetsRef = collection(db, 'timesheets');
    const q = query(timesheetsRef, where('userId', '==', userId));

    try {
      const unsubscribe = onSnapshot(
        q,
        snapshot => {
          const timesheetData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTimesheets(timesheetData);
          setLoading(false);
          setError(null);
        },
        error => {
          console.error('Error fetching timesheets:', error);
          setError(error);
          setLoading(false);
          showToast('Error fetching timesheets', 'error');
        }
      );

      return () => unsubscribe();
    } catch (error) {
      console.error('Error setting up timesheet listener:', error);
      setError(error);
      setLoading(false);
      showToast('Error setting up timesheet listener', 'error');
    }
  }, [user, showToast]);

  /**
   * Handles creation of new timesheet
   */
  const handleNewTimesheet = async () => {
    try {
      const existingTimesheet = await checkExistingTimesheet(new Date());

      if (existingTimesheet) {
        navigate(`/timesheet/edit/${existingTimesheet.id}`);
        return;
      }

      navigate('/timesheet/new');
    } catch (error) {
      setError(error);
      showToast('Error creating new timesheet', 'error');
    }
  };

  /**
   * Navigates to edit form with timesheet data
   * @param {Object} timesheet - Timesheet to edit
   */
  const handleEdit = timesheet => {
    try {
      navigate(`/timesheet/edit/${timesheet.id}`, {
        state: {
          initialData: {
            id: timesheet.id,
            month: timesheet.month,
            timeEntries: timesheet.timeEntries,
            projects: timesheet.projects,
            status: timesheet.status,
            totalDays: timesheet.totalDays,
            userId: timesheet.userId,
            userEmail: timesheet.userEmail,
            includeWeekends: timesheet.includeWeekends,
          },
        },
      });
    } catch (error) {
      setError(error);
      showToast('Error navigating to edit timesheet', 'error');
    }
  };

  if (error) {
    return (
      <div>
        <p>Error loading timesheets: {error.message}</p>
        <button onClick={() => setError(null)}>Retry</button>
      </div>
    );
  }

  return (
    <TimeSheetListView
      timesheets={timesheets}
      loading={loading}
      onNewTimesheet={handleNewTimesheet}
      onEdit={handleEdit}
    />
  );
};

export default TimeSheetList;
