/**
 * @component MissionItemContainer
 * @description Container component for mission items in the project table.
 * Handles mission editing and deletion logic.
 */
import React from 'react';
import PropTypes from 'prop-types';
import MissionItemView from './MissionItemView';

/**
 * @typedef {Object} MissionItemContainerProps
 * @property {Object} mission - Mission data to manage
 * @property {Function} onDelete - Parent deletion handler
 * @property {Function} onEdit - Parent edit handler
 */
const MissionItemContainer = ({ mission, onDelete, onEdit }) => {
  /**
   * Handles mission deletion
   * Can be extended to add confirmation or additional logic
   */
  const handleDelete = () => {
    // Add any delete confirmation or logic here
    onDelete();
  };

  /**
   * Handles mission editing
   * Can be extended to add preparation logic before editing
   */
  const handleEdit = () => {
    // Add any edit preparation logic here
    onEdit(mission);
  };

  return <MissionItemView mission={mission} onDelete={handleDelete} onEdit={handleEdit} />;
};

// PropTypes validation
MissionItemContainer.propTypes = {
  mission: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    totalDays: PropTypes.number.isRequired,
    dailyRate: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default MissionItemContainer;
