/**
 * @component SidebarContainer
 * @description Container component for the sidebar navigation.
 * Manages sidebar state, user authentication, theme, and navigation.
 */
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useToast } from '../../contexts/toast';
import { useThemeMode } from '../../contexts/theme';
import SidebarView from './SidebarView';
import { useTranslation } from 'react-i18next';

const SidebarContainer = () => {
  // Context hooks
  const { user, logout } = useAuth();
  const { showToast } = useToast();
  const { mode, toggleTheme } = useThemeMode();
  const { i18n } = useTranslation();

  // Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Local state
  const [anchorEl, setAnchorEl] = useState(null); // User menu anchor
  const [open, setOpen] = useState(true); // Sidebar expanded state
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);

  /**
   * Handles opening the user menu
   * @param {Event} event - Click event
   */
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the user menu
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles user logout
   * Navigates to login page on success
   * @async
   */
  const handleLogout = async () => {
    try {
      await logout();
      showToast('Successfully logged out', 'success');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      showToast('Error during logout', 'error');
    }
    handleMenuClose();
  };

  /**
   * Handles navigation to different routes
   * @param {string} path - Target route path
   */
  const handleNavigate = path => {
    navigate(path);
  };

  /**
   * Toggles sidebar expanded/collapsed state
   */
  const handleToggle = () => {
    setOpen(!open);
  };

  /**
   * Handles theme toggle
   * Delegates to theme context
   */
  const handleThemeToggle = () => {
    toggleTheme();
  };

  const handleLanguageClick = event => {
    event.stopPropagation();
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageSelect = language => {
    try {
      i18n.changeLanguage(language);
      localStorage.setItem('preferredLanguage', language);
      showToast(`Language changed to ${language.toUpperCase()}`, 'success');
    } catch (error) {
      console.error('Error changing language:', error);
      showToast('Failed to change language', 'error');
    }
    handleLanguageClose();
  };

  return (
    <>
      <SidebarView
        user={user}
        mode={mode}
        currentPath={location.pathname}
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        open={open}
        onToggle={handleToggle}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onLogout={handleLogout}
        onNavigate={handleNavigate}
        onThemeToggle={handleThemeToggle}
        onLanguageClick={handleLanguageClick}
        languageAnchorEl={languageAnchorEl}
        onLanguageClose={handleLanguageClose}
        onLanguageSelect={handleLanguageSelect}
      />
    </>
  );
};

export default SidebarContainer;
