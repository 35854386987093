import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    color: '#1a237e',
    marginBottom: 40,
    textAlign: 'center',
  },
  signatureContainer: {
    width: '100%',
    maxWidth: '400pt',
    display: 'flex',
    flexDirection: 'column',
    gap: 60,
  },
  signatureBox: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    border: '1pt solid #e0e0e0',
    padding: '15pt',
    display: 'flex',
    flexDirection: 'column',
  },
  boxTitle: {
    fontSize: 14,
    color: '#1a237e',
    marginBottom: 20,
    borderBottom: '1pt solid #e0e0e0',
    paddingBottom: 8,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  label: {
    fontSize: 9,
    color: '#666666',
    marginBottom: 4,
  },
  inputField: {
    height: '20pt',
    borderBottom: '1pt solid #e0e0e0',
  },
  signatureArea: {
    marginTop: 20,
    height: '80pt',
    border: '1pt dashed #1a237e',
    borderRadius: 4,
    backgroundColor: '#f8f9ff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signatureText: {
    fontSize: 10,
    color: '#666666',
    fontStyle: 'italic',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
});

const SignatureBox = ({ type }) => {
  const { t } = useTranslation();
  const prefix = `timesheet.pdf.signatures.${type}`;

  return (
    <View style={[styles.signatureBox, styles.shadow]}>
      <Text style={styles.boxTitle}>{t(`${prefix}.title`)}</Text>

      <View style={styles.fieldContainer}>
        <Text style={styles.label}>{t(`${prefix}.signerName`)}</Text>
        <View style={styles.inputField} />
      </View>

      <View style={styles.fieldContainer}>
        <Text style={styles.label}>{t(`${prefix}.signatureDate`)}</Text>
        <View style={styles.inputField} />
      </View>

      <View style={styles.signatureArea}>
        <Text style={styles.signatureText}>{t(`${prefix}.signatureZone`)}</Text>
      </View>
    </View>
  );
};

SignatureBox.propTypes = {
  type: PropTypes.oneOf(['client', 'supplier']).isRequired,
};

const SignaturePage = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('timesheet.pdf.signatures.title')}</Text>
      <View style={styles.signatureContainer}>
        <SignatureBox type="client" />
        <SignatureBox type="supplier" />
      </View>
    </View>
  );
};

export default SignaturePage;
