import { format } from 'date-fns';
import { enUS, fr, ptBR } from 'date-fns/locale';

const locales = {
  en: enUS,
  fr: fr,
  pt: ptBR,
};

/**
 * Formats a date according to the specified format and language
 * @param {Date} date - The date to format
 * @param {string} formatStr - The format string (date-fns format)
 * @param {string} language - The language code (en, fr, pt)
 * @returns {string} The formatted date string
 */
export const formatDate = (date, formatStr, language = 'en') => {
  if (!date) return '';

  return format(date, formatStr, {
    locale: locales[language] || locales.en,
  });
};

/**
 * Formats a date in short format according to the language
 * @param {Date} date - The date to format
 * @param {string} language - The language code (en, fr, pt)
 * @returns {string} The formatted date string
 */
export const formatShortDate = (date, language = 'en') => {
  return formatDate(date, 'P', language);
};

/**
 * Formats a date with time according to the language
 * @param {Date} date - The date to format
 * @param {string} language - The language code (en, fr, pt)
 * @returns {string} The formatted date string
 */
export const formatDateTime = (date, language = 'en') => {
  return formatDate(date, 'Pp', language);
};

/**
 * Formats a month and year according to the language
 * @param {Date} date - The date to format
 * @param {string} language - The language code (en, fr, pt)
 * @returns {string} The formatted date string
 */
export const formatMonthYear = (date, language = 'en') => {
  return formatDate(date, 'MMMM yyyy', language);
};

/**
 * Formats a weekday name according to the language
 * @param {Date} date - The date to format
 * @param {string} language - The language code (en, fr, pt)
 * @returns {string} The formatted weekday name
 */
export const formatWeekday = (date, language = 'en') => {
  return formatDate(date, 'EEE', language);
};

/**
 * Formats a week number according to the language
 * @param {Date} date - The date to format
 * @param {string} language - The language code (en, fr, pt)
 * @returns {string} The formatted week number
 */
export const formatWeekNumber = (date, language = 'en') => {
  return formatDate(date, 'w', language);
};
