import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';

export const useTimesheets = selectedDate => {
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        setLoading(true);
        // Format the selected date to YYYY-MM format for querying
        const monthString = selectedDate.toISOString().slice(0, 7);
        console.log('Fetching timesheets for month:', monthString);

        const timesheetsRef = collection(db, 'timesheets');
        const q = query(timesheetsRef, where('month', '==', monthString));
        const querySnapshot = await getDocs(q);
        const fetchedTimesheets = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log('Fetched timesheets:', fetchedTimesheets);
        setTimesheets(fetchedTimesheets);
      } catch (err) {
        console.error('Error fetching timesheets:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTimesheets();
  }, [selectedDate]);

  return { timesheets, loading, error };
};
