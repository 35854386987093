import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import PropTypes from 'prop-types';

const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();

  // Show nothing while checking authentication
  if (loading) return null;

  // Redirect to timesheet if authenticated
  if (user) {
    return <Navigate to="/timesheet" replace />;
  }

  return children;
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
