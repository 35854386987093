/**
 * @component MonthlyClosuresTab
 * @description Tab component for managing monthly closure dates.
 * Allows setting specific closure dates for each month of the year.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

/**
 * Array of month names for display
 * @constant {string[]}
 */
const MONTH_KEYS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

/**
 * @typedef {Object} MonthlyClosuresTabProps
 * @property {Object} closures - Object containing closure dates by month index
 * @property {Function} onUpdate - Handler for closure date updates
 */
const MonthlyClosuresTab = ({ closures, onUpdate }) => {
  const { t } = useTranslation();

  /**
   * Local state to manage closure dates
   * Array of 12 elements (one per month), containing date strings or null
   */
  const [localClosures, setLocalClosures] = useState(Array(12).fill(null));

  // Update local state when props change
  useEffect(() => {
    // Convert object to array if needed
    const closuresArray = Array(12).fill(null);
    Object.entries(closures).forEach(([index, date]) => {
      closuresArray[index] = date;
    });
    setLocalClosures(closuresArray);
  }, [closures]);

  const handleDateChange = (index, date) => {
    const newClosures = [...localClosures];
    newClosures[index] = date ? format(date, 'yyyy-MM-dd') : null;
    setLocalClosures(newClosures);

    // Convert array back to object for parent component
    const closuresObject = newClosures.reduce((acc, date, idx) => {
      if (date) acc[idx] = date;
      return acc;
    }, {});

    onUpdate(index, closuresObject);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {t('admin.calendar.monthly_closures.title')}
      </Typography>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('admin.calendar.monthly_closures.month')}</TableCell>
              <TableCell>{t('admin.calendar.monthly_closures.closure_date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {MONTH_KEYS.map((monthKey, index) => (
              <TableRow key={monthKey}>
                <TableCell>{t(`admin.calendar.monthly_closures.months.${monthKey}`)}</TableCell>
                <TableCell>
                  <DatePicker
                    label={t('admin.calendar.monthly_closures.closure_date')}
                    value={localClosures[index] ? parseISO(localClosures[index]) : null}
                    onChange={date => handleDateChange(index, date)}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {MONTH_KEYS.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  {t('admin.calendar.monthly_closures.no_closures')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

MonthlyClosuresTab.propTypes = {
  closures: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default MonthlyClosuresTab;
