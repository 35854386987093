import React from 'react';
import { Language as LanguageIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

/**
 * @component LanguageDisplay
 * @description Displays the current language icon in the sidebar
 */
const LanguageDisplay = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LanguageIcon />
    </Box>
  );
};

export default LanguageDisplay;
