import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { format, addMonths, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { fr, enUS, pt } from 'date-fns/locale';

const locales = {
  fr,
  en: enUS,
  pt,
};

const MonthNavigator = ({ selectedDate, onChange }) => {
  const { i18n } = useTranslation();

  const currentLocale = locales[i18n.language] || enUS;

  const handlePreviousMonth = () => {
    onChange(subMonths(selectedDate, 1));
  };

  const handleNextMonth = () => {
    onChange(addMonths(selectedDate, 1));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        my: 2,
      }}
    >
      <IconButton onClick={handlePreviousMonth} size="small">
        <ChevronLeft />
      </IconButton>
      <Typography variant="h6" component="span">
        {format(selectedDate, 'MMMM yyyy', { locale: currentLocale })}
      </Typography>
      <IconButton onClick={handleNextMonth} size="small">
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

MonthNavigator.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MonthNavigator;
