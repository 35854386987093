/**
 * @fileoverview Authentication context and hook definition
 */
import { createContext, useContext } from 'react';

/**
 * Context for authentication state and methods
 * @type {React.Context}
 */
export const AuthContext = createContext();

/**
 * Custom hook for accessing authentication context
 * @returns {Object} Authentication context value
 * @throws {Error} If used outside of AuthProvider
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
