import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isWeekend,
  format,
  startOfWeek,
  eachWeekOfInterval,
  endOfWeek,
  isSameMonth,
  isAfter,
  parseISO,
} from 'date-fns';
import {
  collection,
  addDoc,
  updateDoc,
  query,
  where,
  getDocs,
  doc,
  serverTimestamp,
  getDoc,
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/auth';
import { useToast } from '../../contexts/toast';
import TimeSheetFormView from './TimeSheetFormView';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TIME_OPTIONS = [
  { value: 0, label: '0' },
  { value: 0.5, label: '0.5' },
  { value: 1, label: '1' },
];

/**
 * @component TimeSheetForm
 * @description Main container component for timesheet management.
 * Handles state management, data persistence, and business logic for time entries.
 *
 * @param {Object} props
 * @param {Object} props.initialData - Initial timesheet data for editing
 */

const TimeSheetForm = ({ initialData = null }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [includeWeekends, setIncludeWeekends] = useState(initialData?.includeWeekends || false);
  const [selectedMonth, setSelectedMonth] = useState(
    initialData?.month ? new Date(initialData.month + '-01') : new Date()
  );
  const [data, setData] = useState(initialData);
  const [monthClosure, setMonthClosure] = useState(null);
  const [availableMissions, setAvailableMissions] = useState([]);
  const [selectedMissions, setSelectedMissions] = useState([]);
  const [timeEntries, setTimeEntries] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [nonWorkingDays, setNonWorkingDays] = useState([]);
  const [viewMode, setViewMode] = useState('days');
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaved, setIsSaved] = useState(!!initialData?.id);
  const [isValidated, setIsValidated] = useState(initialData?.status === 'VALIDATED');
  const [availableUsers, setAvailableUsers] = useState([]);
  const [currentUserEmail, setCurrentUserEmail] = useState(
    location.state?.userEmail || initialData?.userEmail || user?.email
  );
  const [comments, setComments] = useState(initialData?.comments || '');
  const [currentTimesheetId, setCurrentTimesheetId] = useState(initialData?.id || null);

  const isUserOnly = user?.role === 'user';

  const effectiveReadOnly = useMemo(() => {
    return isValidated && isUserOnly;
  }, [isValidated, isUserOnly]);

  const initialDataRef = useRef(initialData);

  // Effect to load calendar settings
  useEffect(() => {
    if (!user?.email) return;

    const loadCalendarSettings = async () => {
      const selectedYear = format(selectedMonth, 'yyyy');

      try {
        const calendarRef = collection(db, 'calendar_settings');
        const yearQuery = query(calendarRef, where('year', '==', selectedYear));
        const snapshot = await getDocs(yearQuery);

        const settings = snapshot.docs[0]?.data();

        setNonWorkingDays(settings?.nonWorkingDays || []);
        if (settings?.monthlyClosures) {
          const monthKey = parseInt(format(selectedMonth, 'M')) - 1; // 0-based month index
          const closureDate = settings.monthlyClosures[monthKey];
          if (closureDate) {
            setMonthClosure(new Date(closureDate));
          } else {
            setMonthClosure(null);
          }
        }
      } catch (error) {
        console.error('❌ Error loading calendar settings:', error);
        showToast('Error loading calendar settings', 'error');
      }
    };

    loadCalendarSettings();
  }, [showToast, selectedMonth, user?.email]);

  // Effect to load available users for admin/manager selection
  useEffect(() => {
    const loadUsers = async () => {
      if (!user?.email) return;
      // check if the user is not 'user'
      if (user?.role !== 'user') {
        try {
          const usersRef = collection(db, 'users');
          const snapshot = await getDocs(usersRef);
          const users = snapshot.docs.map(doc => ({
            email: doc.data().email,
          }));
          setAvailableUsers(users);
        } catch (error) {
          console.error('Error loading users:', error);
          showToast('Error loading users', 'error');
        }
      }
    };

    loadUsers();
  }, [user?.email, user?.role, showToast]);

  const workingDays = useMemo(() => {
    const start = startOfMonth(selectedMonth);
    const end = endOfMonth(selectedMonth);

    const isNonWorkingDay = date => {
      const dateStr = format(date, 'yyyy-MM-dd');
      return isWeekend(date) || nonWorkingDays.includes(dateStr);
    };

    if (viewMode === 'weeks') {
      // Get all weeks that contain days from the selected month
      const allWeeks = eachWeekOfInterval({ start, end }, { weekStartsOn: 0 });

      // Filter weeks to only include those that have days in the selected month
      return allWeeks.filter(weekStart => {
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });
        return isSameMonth(weekStart, selectedMonth) || isSameMonth(weekEnd, selectedMonth);
      });
    }

    // Get all days of the month
    const allDays = eachDayOfInterval({ start, end });

    // Filter out non-working days if includeWeekends is false
    return includeWeekends ? allDays : allDays.filter(day => !isNonWorkingDay(day));
  }, [selectedMonth, viewMode, includeWeekends, nonWorkingDays]);

  const remainingMissions = useMemo(() => {
    const selectedIds = new Set(selectedMissions.map(m => m.id));
    return availableMissions.filter(mission => !selectedIds.has(mission.id));
  }, [availableMissions, selectedMissions]);

  const kpis = useMemo(() => {
    const isNonWorkingDay = date => {
      const dateStr = format(date, 'yyyy-MM-dd');
      return isWeekend(date) || nonWorkingDays.includes(dateStr);
    };

    const start = startOfMonth(selectedMonth);
    const end = endOfMonth(selectedMonth);
    const allDays = eachDayOfInterval({ start, end });

    // Total working days (excluding weekends and holidays)
    const totalWorkingDays = allDays.filter(day => !isNonWorkingDay(day)).length;

    // Optional days are weekends + holidays
    const optionalDays = allDays.filter(day => isNonWorkingDay(day)).length;

    let totalDaysInputted = 0;
    Object.values(timeEntries).forEach(entries => {
      Object.values(entries).forEach(value => {
        totalDaysInputted += parseFloat(value) || 0;
      });
    });

    return {
      totalWorkingDays,
      optionalDays,
      totalDaysInputted,
      completionPercentage: (totalDaysInputted / totalWorkingDays) * 100,
    };
  }, [selectedMonth, timeEntries, nonWorkingDays]);

  useEffect(() => {
    if (!user?.email) return;

    const loadTimesheet = async () => {
      setIsLoading(true);

      try {
        // Always load projects and missions
        const projectsSnapshot = await getDocs(collection(db, 'projects'));

        // If we have an ID but no initialData, load the timesheet
        let timesheetData = initialDataRef.current;
        if (id && !initialData) {
          const timesheetDoc = await getDoc(doc(db, 'timesheets', id));
          if (timesheetDoc.exists()) {
            timesheetData = {
              id: timesheetDoc.id,
              ...timesheetDoc.data(),
            };
          } else {
            console.error('❌ Timesheet not found:', id);
            showToast('Timesheet not found', 'error');
            navigate('/timesheets');
            return;
          }
        }

        const allMissions = [];
        projectsSnapshot.forEach(projectDoc => {
          const projectData = projectDoc.data();

          if (projectData.missions && Array.isArray(projectData.missions)) {
            const projectMissions = projectData.missions
              .filter(
                mission =>
                  mission.isAvailableToEveryone ||
                  (mission.authorizedUsers && mission.authorizedUsers.includes(currentUserEmail))
              )
              .map(mission => ({
                ...mission,
                id: `${projectDoc.id}_${mission.name}`,
                project: {
                  id: projectDoc.id,
                  name: projectData.name,
                  code: projectData.code,
                  color: projectData.color,
                  invoicable: projectData.invoicable ?? true,
                },
              }));
            allMissions.push(...projectMissions);
          }
        });

        setAvailableMissions(allMissions);

        if (timesheetData) {
          // Set initial month
          const month = new Date(timesheetData.month + '-01');
          setSelectedMonth(month);

          // Set selected missions
          const selectedMissions = allMissions.filter(mission =>
            timesheetData.projects?.some(p => p.missions.includes(mission.id))
          );
          setSelectedMissions(selectedMissions);

          // Set time entries
          setTimeEntries(timesheetData.timeEntries || {});
          setIsValidated(timesheetData.status === 'VALIDATED');
          // Store the loaded data as initialData for validation
          if (!initialData) {
            initialDataRef.current = timesheetData;
          }
        }
      } catch (error) {
        console.error('❌ Error loading timesheet:', error);
        showToast('Error loading timesheet data', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    loadTimesheet();
  }, [user?.email, id, showToast, navigate, initialData, currentUserEmail]);

  useEffect(() => {
    if (id && !initialData && !location.state?.initialData) {
      const fetchTimesheet = async () => {
        try {
          const timesheetDoc = await getDoc(doc(db, 'timesheets', id));
          if (timesheetDoc.exists()) {
            const data = timesheetDoc.data();
            setData(data);
            setIsValidated(data.status === 'VALIDATED');
            setTimeEntries({
              id: timesheetDoc.id,
              ...data,
            });
          }
        } catch (error) {
          console.error('Error fetching timesheet:', error);
          showToast('Error loading timesheet', 'error');
        }
      };
      fetchTimesheet();
    }
  }, [id, initialData, showToast, location.state]);

  // Add effect to track changes
  useEffect(() => {
    if (!initialData) return;

    const hasTimeEntryChanges =
      JSON.stringify(initialData.timeEntries) !== JSON.stringify(timeEntries);
    const hasMissionChanges =
      JSON.stringify(initialData.projects?.map(p => p.missions).flat()) !==
      JSON.stringify(selectedMissions.map(m => m.id));

    setHasChanges(hasTimeEntryChanges || hasMissionChanges);
  }, [timeEntries, selectedMissions, initialData]);

  /**
   * Handles time entry updates for both day and week views
   * Validates entries against weekends and non-working days
   * Updates state while maintaining data consistency
   */

  const handleTimeChange = useCallback(
    (missionId, dateStr, value) => {
      if (isUserOnly && monthClosure && isAfter(new Date(), monthClosure)) {
        showToast('This month is closed', 'error');
        return;
      }

      if (viewMode === 'weeks') {
        const weekStart = startOfWeek(parseISO(dateStr), { weekStartsOn: 0 });
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });

        const daysInWeek = eachDayOfInterval({ start: weekStart, end: weekEnd }).filter(day => {
          const isWeekendDay = !includeWeekends && isWeekend(day);
          const isOutsideMonth = !isSameMonth(day, selectedMonth);
          const dayStr = format(day, 'yyyy-MM-dd');
          const isNonWorking = nonWorkingDays.includes(dayStr);

          return !isWeekendDay && !isOutsideMonth && !isNonWorking;
        });

        setTimeEntries(prev => {
          const updatedEntries = {};
          daysInWeek.forEach(day => {
            const dayStr = format(day, 'yyyy-MM-dd');
            updatedEntries[dayStr] = value;
          });

          return {
            ...prev,
            [missionId]: {
              ...(prev[missionId] || {}),
              ...updatedEntries,
            },
          };
        });
      } else {
        setTimeEntries(prev => ({
          ...prev,
          [missionId]: {
            ...(prev[missionId] || {}),
            [dateStr]: value,
          },
        }));
      }
    },
    [viewMode, includeWeekends, nonWorkingDays, selectedMonth, isUserOnly, monthClosure, showToast]
  );

  /**
   * Adds a new mission to the timesheet
   * Updates selectedMissions state and closes the selection dialog
   */

  const handleAddMission = useCallback(
    mission => {
      // Check if mission already exists in selectedMissions
      const isDuplicate = selectedMissions.some(m => m.id === mission.id);

      if (!isDuplicate) {
        setSelectedMissions(prev => [...prev, mission]);
        setOpenDialog(false);
      }
    },
    [selectedMissions]
  );

  /**
   * Removes a mission from the timesheet
   * Cleans up both mission selection and associated time entries
   */

  const handleDeleteMission = useCallback(mission => {
    setSelectedMissions(prev => prev.filter(m => m.id !== mission.id));
    setTimeEntries(prev => {
      const { [mission.id]: removed, ...rest } = prev;
      return rest;
    });
  }, []);

  /**
   * Navigates back to the previous screen
   */

  const handleBack = useCallback(() => {
    // Use browser history to go back
    navigate(-1);
  }, [navigate]);

  /**
   * Toggles the inclusion of weekends in the timesheet
   */

  const handleToggleWeekends = useCallback(() => {
    setIncludeWeekends(prev => !prev);
  }, []);

  /**
   * Persists timesheet data to Firestore
   * Handles both creation of new timesheets and updates to existing ones
   * Includes error handling and success notifications
   * @async
   */

  const handleSave = useCallback(
    async data => {
      try {
        const selectedUserEmail = currentUserEmail;
        const userId = selectedUserEmail.toLowerCase().replace('@', '_at_');
        const monthStr = format(selectedMonth, 'yyyy-MM');

        const timesheetData = {
          userId,
          userEmail: selectedUserEmail,
          month: monthStr,
          timeEntries,
          projects: selectedMissions.reduce((acc, mission) => {
            const projectId = mission.project.id;
            const existingProject = acc.find(p => p.id === projectId);
            if (existingProject) {
              existingProject.missions.push(mission.id);
            } else {
              acc.push({
                id: projectId,
                name: mission.project.name,
                missions: [mission.id],
              });
            }
            return acc;
          }, []),
          totalDays: kpis.totalDaysInputted,
          completionPercentage: kpis.completionPercentage,
          updatedAt: serverTimestamp(),
          performedBy: user.email,
          status: 'PENDING',
          includeWeekends,
          comments,
        };

        let savedTimesheetId;

        if (id || initialData?.id) {
          await updateDoc(doc(db, 'timesheets', id || initialData.id), {
            ...timesheetData,
            performedBy: user.email,
            updatedAt: serverTimestamp(),
          });
          savedTimesheetId = id || initialData.id;
          showToast('Timesheet updated successfully', 'success');
        } else {
          const docRef = await addDoc(collection(db, 'timesheets'), {
            ...timesheetData,
            createdAt: serverTimestamp(),
          });
          savedTimesheetId = docRef.id;
          showToast('Timesheet created successfully', 'success');
          // redirect user to the new timesheet page
          navigate(`/timesheet/edit/${savedTimesheetId}`);
        }

        setIsSaved(true);
        setHasChanges(false);
        return savedTimesheetId;
      } catch (error) {
        console.error('❌ Error saving timesheet:', error);
        showToast(`Error saving timesheet: ${error.message}`, 'error');
        throw error;
      }
    },
    [
      currentUserEmail,
      selectedMonth,
      timeEntries,
      selectedMissions,
      kpis.totalDaysInputted,
      kpis.completionPercentage,
      includeWeekends,
      initialData?.id,
      showToast,
      navigate,
      user.email,
      id,
      comments,
    ]
  );

  const handleViewModeChange = useCallback((event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  }, []);

  // Add the checkExistingTimesheet function
  const checkExistingTimesheet = useCallback(
    async (selectedDate, userEmail = user?.email) => {
      if (!userEmail) return null;

      const userId = userEmail.toLowerCase().replace('@', '_at_');
      const selectedMonthStr = format(selectedDate, 'yyyy-MM');

      try {
        const timesheetsRef = collection(db, 'timesheets');
        const q = query(
          timesheetsRef,
          where('userId', '==', userId),
          where('month', '==', selectedMonthStr)
        );

        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          const existingTimesheet = {
            id: snapshot.docs[0].id,
            ...snapshot.docs[0].data(),
          };
          return existingTimesheet;
        }
        return null;
      } catch (error) {
        console.error('❌ Error checking existing timesheet:', error);
        return null;
      }
    },
    [user?.email]
  );

  // Add the handleMonthChange function
  const handleMonthChange = useCallback(
    async (newDate, currentUser) => {
      const userToUse = currentUser || currentUserEmail || user?.email;

      // Navigate through transition with both month and user parameters
      navigate(
        `/transition?redirect=${encodeURIComponent(
          '/timesheet/new?userEmail=' + userToUse + '&month=' + format(newDate, 'yyyy-MM')
        )}`
      );
    },
    [navigate, currentUserEmail, user?.email]
  );

  const handleUserChange = useCallback(
    async event => {
      const newUserEmail = event.target.value;

      // Always use transition route when changing user
      navigate(
        `/transition?redirect=${encodeURIComponent(
          '/timesheet/new?userEmail=' + newUserEmail + '&month=' + format(selectedMonth, 'yyyy-MM')
        )}`
      );
    },
    [navigate, selectedMonth]
  );

  // Add effect to initialize data
  useEffect(() => {
    if (initialData) {
      setTimeEntries(initialData.timeEntries || {});
      setComments(initialData.comments ?? '');
      setSelectedMissions(
        initialData.projects
          ?.map(p => p.missions?.map(missionId => availableMissions.find(m => m.id === missionId)))
          .flat()
          .filter(Boolean) || []
      );
    }
  }, [id, initialData, availableMissions, showToast, location.state]);

  useEffect(() => {
    if (initialData?.userEmail) {
      setCurrentUserEmail(initialData.userEmail);
    }
  }, [initialData]);

  // Add this effect near the other useEffect declarations
  useEffect(() => {
    const handleInitialLoad = async () => {
      if (location.pathname === '/timesheet/new') {
        const params = new URLSearchParams(location.search);
        const userEmail = params.get('userEmail');
        const month = params.get('month');

        if (userEmail && month) {
          // Check for existing timesheet with these parameters
          try {
            const date = new Date(month + '-01'); // Convert YYYY-MM to Date
            const existingTimesheet = await checkExistingTimesheet(date, userEmail);

            if (existingTimesheet) {
              // Redirect to edit page if timesheet exists
              navigate(`/timesheet/edit/${existingTimesheet.id}`, {
                state: {
                  initialData: existingTimesheet,
                  userEmail: userEmail,
                },
                replace: true, // Use replace to avoid adding to history stack
              });
              return;
            }
          } catch (error) {
            console.error('Error checking existing timesheet:', error);
            showToast(t('timesheet.messages.load_error'), 'error');
          }
        }

        // Reset form only if no query params
        if (!userEmail && !month) {
          setTimeEntries({});
          setSelectedMissions([]);
          setHasChanges(false);
          setIsSaved(false);
          setIsValidated(false);
        }
      }
    };

    handleInitialLoad();
  }, [location.pathname, location.search, checkExistingTimesheet, navigate, showToast, t]);

  // Effect to initialize data with correct user context
  useEffect(() => {
    if (id && !initialData) {
      const fetchTimesheet = async () => {
        try {
          const timesheetDoc = await getDoc(doc(db, 'timesheets', id));
          if (timesheetDoc.exists()) {
            const data = timesheetDoc.data();
            setData(data);

            setCurrentUserEmail(data.userEmail); // Set the correct user email
            setIsValidated(data.status === 'VALIDATED');
            setComments(data.comments || ''); // Set comments from loaded timesheet
            setTimeEntries({
              id: timesheetDoc.id,
              ...data,
            });
          }
        } catch (error) {
          console.error('Error fetching timesheet:', error);
          showToast('Error loading timesheet', 'error');
        }
      };
      fetchTimesheet();
    }
  }, [id, initialData, showToast, location.state?.userEmail]);

  // Add comments handler
  const handleCommentsChange = useCallback(newComments => {
    setComments(newComments);
  }, []);

  // Add this effect near the top of your component, after state declarations
  useEffect(() => {
    // Only handle query params on /timesheet/new
    if (location.pathname === '/timesheet/new') {
      const params = new URLSearchParams(location.search);
      const userEmail = params.get('userEmail');
      const month = params.get('month');

      // Handle user email from query param
      if (userEmail && userEmail !== currentUserEmail) {
        setCurrentUserEmail(userEmail);
      }

      // Handle month from query param
      if (month) {
        const newDate = new Date(month + '-01');
        const currentMonth = format(selectedMonth, 'yyyy-MM');
        if (currentMonth !== month) {
          setSelectedMonth(newDate);
        }
      }
    }
  }, [
    location.pathname,
    location.search,
    currentUserEmail,
    selectedMonth,
    setCurrentUserEmail,
    setSelectedMonth,
  ]);

  // Add new status handlers
  const handleValidate = useCallback(async () => {
    console.log('🔄 Starting timesheet validation...', { id });

    try {
      console.log('📝 Setting current timesheet ID:', id);
      setCurrentTimesheetId(id);

      if (id) {
        console.log('✍️ Updating timesheet document with status VALIDATED');
        console.log('📊 Update payload:', {
          status: 'VALIDATED',
          updatedAt: 'serverTimestamp',
          timesheetId: id,
        });

        await updateDoc(doc(db, 'timesheets', id), {
          status: 'VALIDATED',
          updatedAt: serverTimestamp(),
        });

        console.log('✅ Timesheet document updated successfully');

        console.log('🔄 Setting validated state to true');
        setIsValidated(true);

        console.log('📢 Showing success toast notification');
        showToast(t('timesheet.messages.validate_success'), 'success');
      } else {
        console.warn('⚠️ No timesheet ID provided for validation');
      }
    } catch (error) {
      console.error('❌ Error validating timesheet:', error);
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        timesheetId: id,
      });
      showToast(t('timesheet.messages.validate_error'), 'error');
    }
  }, [id, showToast, t]);

  const handleApprove = useCallback(async () => {
    try {
      if (id) {
        await updateDoc(doc(db, 'timesheets', id), {
          status: 'APPROVED',
          updatedAt: serverTimestamp(),
        });
        setIsValidated(true);
        showToast(t('timesheet.messages.approve_success'), 'success');
      }
    } catch (error) {
      console.error('Error approving timesheet:', error);
      showToast(t('timesheet.messages.approve_error'), 'error');
    }
  }, [id, showToast, t]);

  const handleDisapprove = useCallback(async () => {
    try {
      if (id) {
        await updateDoc(doc(db, 'timesheets', id), {
          status: 'VALIDATED',
          updatedAt: serverTimestamp(),
        });
        setIsValidated(true);
        showToast(t('timesheet.messages.disapprove_success'), 'success');
      }
    } catch (error) {
      console.error('Error disapproving timesheet:', error);
      showToast(t('timesheet.messages.disapprove_error'), 'error');
    }
  }, [id, showToast, t]);

  const handleConvertToDraft = useCallback(async () => {
    try {
      if (id) {
        await updateDoc(doc(db, 'timesheets', id), {
          status: 'PENDING',
          updatedAt: serverTimestamp(),
        });
        setIsValidated(false);
        showToast(t('timesheet.messages.convert_to_draft_success'), 'success');
      }
    } catch (error) {
      console.error('Error converting timesheet to draft:', error);
      showToast(t('timesheet.messages.convert_to_draft_error'), 'error');
    }
  }, [id, showToast, t]);

  return (
    <TimeSheetFormView
      isLoading={isLoading}
      readOnly={effectiveReadOnly}
      includeWeekends={includeWeekends}
      onToggleWeekends={handleToggleWeekends}
      onBack={handleBack}
      workingDays={workingDays}
      selectedMissions={selectedMissions}
      timeEntries={timeEntries}
      onTimeChange={handleTimeChange}
      onDeleteMission={handleDeleteMission}
      remainingMissions={remainingMissions}
      openDialog={openDialog}
      onOpenDialog={() => setOpenDialog(true)}
      onCloseDialog={() => setOpenDialog(false)}
      onAddMission={handleAddMission}
      kpis={kpis}
      onSave={handleSave}
      selectedMonth={selectedMonth}
      onMonthChange={handleMonthChange}
      timeOptions={TIME_OPTIONS}
      userEmail={currentUserEmail}
      viewMode={viewMode}
      onViewModeChange={handleViewModeChange}
      nonWorkingDays={nonWorkingDays}
      hasChanges={hasChanges}
      isSaved={isSaved}
      initialData={initialData || data}
      isValidated={isValidated}
      isUserOnly={isUserOnly}
      availableUsers={availableUsers}
      onUserChange={handleUserChange}
      monthClosure={monthClosure}
      comments={comments}
      onCommentsChange={handleCommentsChange}
      timesheetId={currentTimesheetId}
      onValidate={handleValidate}
      onApprove={handleApprove}
      onDisapprove={handleDisapprove}
      status={initialData?.status || data?.status || 'PENDING'}
      isManagerOrAbove={
        user?.role === 'manager' || user?.role === 'rh' || user?.role === 'superadmin'
      }
      onConvertToDraft={handleConvertToDraft}
    />
  );
};

TimeSheetForm.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.string,
    month: PropTypes.string,
    timeEntries: PropTypes.object,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        missions: PropTypes.arrayOf(PropTypes.string).isRequired,
      })
    ),
  }),
};

export default TimeSheetForm;
