import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { ProtectedRoute, PublicRoute } from '../components/routes';
import TimeSheetList from '../components/timesheet/TimeSheetList';
import TimeSheetForm from '../components/timesheet/TimeSheetForm';
import { Management } from '../components/management';
import UserManagement from '../components/admin/UserManagement';
import TimeSheetReview from '../components/timesheet/TimeSheetReview';
import CalendarManagement from '../components/admin/CalendarManagement';
import AuditTrailViewer from '../components/audit/AuditTrailViewer';
import { Login } from '../components/auth';
import BackupContainer from '../components/admin/BackupContainer';
import DashboardContainer from '../components/dashboard/DashboardContainer';
import TransitionPage from '../components/common/TransitionPage';
import TimeSheetPrint from '../components/timesheet/TimeSheetPrint';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public route - redirect to timesheet if authenticated */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      {/* Default redirect to timesheet */}
      <Route path="/" element={<Navigate to="/timesheet" />} />
      <Route path="*" element={<Navigate to="/timesheet" />} />

      {/* Timesheet Routes */}
      <Route
        path="/timesheet"
        element={
          <ProtectedRoute roles={['user', 'manager', 'rh', 'superadmin']}>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<TimeSheetList />} />
        <Route path="new" element={<TimeSheetForm />} />
        <Route path="edit/:id" element={<TimeSheetForm />} />
        <Route
          path="review"
          element={
            <ProtectedRoute requiredRole="manager">
              <TimeSheetReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="print"
          element={
            <ProtectedRoute roles={['manager', 'rh', 'superadmin']}>
              <TimeSheetPrint />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* Management Routes */}
      <Route
        path="/management"
        element={
          <ProtectedRoute requiredRole="manager">
            <Management />
          </ProtectedRoute>
        }
      />

      {/* Admin Routes */}
      <Route
        path="/admin"
        element={
          <ProtectedRoute requiredRole="rh">
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route path="users" element={<UserManagement />} />
        <Route path="calendar" element={<CalendarManagement />} />
      </Route>

      {/* Audit Routes - SuperAdmin only */}
      <Route
        path="/audit"
        element={
          <ProtectedRoute requiredRole="superadmin">
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<AuditTrailViewer />} />
        <Route path=":entityType/:entityId" element={<AuditTrailViewer />} />
      </Route>

      <Route
        path="/backup"
        element={
          <ProtectedRoute requiredRole="superadmin">
            <BackupContainer />
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute roles={['rh', 'superadmin']}>
            <DashboardContainer />
          </ProtectedRoute>
        }
      />

      <Route path="/transition" element={<TransitionPage />} />
    </Routes>
  );
};

export default AppRoutes;
